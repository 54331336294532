import ReactDOM from 'react-dom/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from './context/theme/Theme';
import { GlobalProvider } from './context/global/Global';
import { SnackbarProvider } from './context/snackbar/Snackbar';
import { ModulesProvider } from "./context/modules/Modules";
import {DeepLinkProvider} from "./context/deepLink/DeepLink";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <GlobalProvider>
        <ModulesProvider>
            <ThemeProvider>
                <SnackbarProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DeepLinkProvider>
                            <App />
                        </DeepLinkProvider>
                    </LocalizationProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </ModulesProvider>
    </GlobalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();