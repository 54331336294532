import { FormEvent, useContext, useEffect, useState } from "react";

import { IProps } from "./WorkExperienceModal.interface";
import WorkExperienceModalView from "./WorkExperienceModal.view";
import { useDoRequest, useSnackbar } from "../../../../../hooks";
import { useGlobalState } from "../../../../../context/global/Global";
import { ModulesContext } from "../../../../../context/modules/Modules";
import { IModulesContext } from "../../../../../context/modules/Modules.interface";
import { IProfessionalHistoryAlterDTO, IProfessionalHistoryCreateDTO } from "../../../../../modules/api/endpoints/professionalHistoryBrother/ProfessionalHistory.interface";

function WorkExperienceModal(props: IProps) {
    const { selectedExperience, allExperiences, modalVisible, type, setAllExperiences, onClose } = props;
    const { Analytics } = useContext<IModulesContext>(ModulesContext);

    const snackbar = useSnackbar();
    const { user } = useGlobalState();

    const [companyName, setCompanyName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [endDate, setEndDate] = useState<string | Date | null>(null);
    const [companyPosition, setCompanyPosition] = useState<string>("");
    const [initialDate, setInitialDate] = useState<string | Date | null>(null);

    const ProfessionalHistoryUpdate = useDoRequest((api) => api.ProfessionalHistory.Update);
    const ProfessionalHistoryCreate = useDoRequest((api) => api.ProfessionalHistory.Create);

    useEffect(() => {
        setCompanyName(selectedExperience?.nomeEmpresa || "");
        setCompanyPosition(selectedExperience?.nomeCargo || "");
        setDescription(selectedExperience?.descricaoAtividade || "");
        setInitialDate(selectedExperience?.dataInicio || null);
        setEndDate(selectedExperience?.dataFim || null);
    }, [selectedExperience])

    async function handleChange(event: any) {
        event.preventDefault();
        let input = event.target.value;
        switch (event.target.name) {
            case 'companyName':
                setCompanyName(input);
                break;
            case 'companyPosition':
                setCompanyPosition(input);
                break;
            case 'description':
                setDescription(input);
                break;
            default:
                break;
        }
    }

    function handleSubmit(e: FormEvent) {
        e.preventDefault();
        if(!user) return;

        if (endDate == "Invalid Date" || initialDate == "Invalid Date" || !endDate || !initialDate) {
            snackbar("Insira datas válidas.").error();
            return;
        }

        if (type == "edit") {
            if (!selectedExperience) return;

            const newProfessionalHistory: IProfessionalHistoryAlterDTO = {
                idHistoricoProfissional: selectedExperience.idHistoricoProfissional,
                idObreiro: user.idObreiro,
                nomeEmpresa: companyName,
                nomeCargo: companyPosition,
                descricaoAtividade: description,
                dataInicio: initialDate,
                dataFim: endDate
            }

            editExperience(newProfessionalHistory);
        } else if (type == "create") {
            const newProfessionalHistory: IProfessionalHistoryCreateDTO = {
                idObreiro: user.idObreiro,
                nomeEmpresa: companyName,
                nomeCargo: companyPosition,
                descricaoAtividade: description,
                dataInicio: initialDate,
                dataFim: endDate
            }

            newExperience(newProfessionalHistory);
        }
    }

    function editExperience(newProfessionalHistory: IProfessionalHistoryAlterDTO) {
        Analytics.logScreenAction('Detalhes de um irmão', 'Editar histórico profissional');
        ProfessionalHistoryUpdate.doRequest(newProfessionalHistory).then((res) => {
            if (res.statusExecucao && res.dadosRetorno) {
                const alteredExperience = res.dadosRetorno;
                let allExperiencesInstance = allExperiences;

                allExperiencesInstance?.forEach((experience) => {
                    if (experience.idHistoricoProfissional == alteredExperience.idHistoricoProfissional) {
                        experience.nomeCargo = alteredExperience.nomeCargo;
                        experience.nomeEmpresa = alteredExperience.nomeEmpresa;
                        experience.descricaoAtividade = alteredExperience.descricaoAtividade;
                        experience.dataInicio = alteredExperience.dataInicio;
                        experience.dataFim = alteredExperience.dataFim;
                    }
                })

                snackbar("Experiência profissional atualizada com sucesso.").success();
                setAllExperiences(allExperiencesInstance);
                onCloseAction();
            } else {
                snackbar(res.mensagem || "Não foi possível buscar os dados da experiência.").error();
            }
        }).catch((error) => {
            snackbar("Não foi possível os dados da experiência.").error();
        })
    }

    function newExperience(newProfessionalHistory: IProfessionalHistoryCreateDTO) {
        Analytics.logScreenAction('Detalhes de um irmão', 'Adicionar histórico profissional');
        ProfessionalHistoryCreate.doRequest(newProfessionalHistory).then((res) => {
            if (res.statusExecucao && res.dadosRetorno) {
                const newExperience = res.dadosRetorno;
                let allExperiencesInstance = allExperiences;

                allExperiencesInstance?.push(newExperience);

                snackbar("Experiência profissional criada com sucesso.").success();
                setAllExperiences(allExperiencesInstance);
                onCloseAction();
            } else {
                snackbar(res.mensagem || "Não foi possível buscar os dados da experiência.").error();
            }
        }).catch((error) => {
            snackbar("Não foi possível adicionar experiência profissional.").error();
        })
    }

    function clearFields() {
        setCompanyName("");
        setCompanyPosition("");
        setDescription("");
        setInitialDate(null);
        setEndDate(null);
    }

    function onCloseAction() {
        clearFields();
        onClose();
    }

    return (
        <WorkExperienceModalView
            modalVisible={modalVisible}
            companyName={companyName}
            initialDate={initialDate}
            endDate={endDate}
            companyPosition={companyPosition}
            description={description}
            type={type}
            loading={ProfessionalHistoryUpdate.loading}
            onClose={onCloseAction}
            handleSubmit={handleSubmit}
            handleInputChange={handleChange}
            setInitialDate={setInitialDate}
            setEndDate={setEndDate}
        />
    )
}

export default WorkExperienceModal;