import { useHistory } from '../../../../hooks';
import { ICompanyListCard } from './CompanyListCard.interface';
import CompanyListCardView from './CompanyListCard.view';

function CompanyListCard(props: ICompanyListCard) {
    const { company } = props;

    const history = useHistory();

    function goToCompanyPage() {
        history.redirectTo(`/detailCompany/${company.idEstabelecimento}`);
    }

    function addressTranslation() {
        if (!company.enderecoCidade && !company.enderecoUf) {
            return '';
        } else if (company.enderecoCidade && company.enderecoUf) {
            return `${company?.enderecoCidade} - ${company.enderecoUf}`;
        } else if (company.enderecoCidade) {
            return company?.enderecoCidade;
        } else {
            return company.enderecoUf;
        }
    }

    return (
        <CompanyListCardView
            title={company.nomeEstabelecimento || ""}
            nameCatagory={company?.idCategoriaNavigation?.nomeCategoria || ""}
            site={company?.site || "--"}
            address={addressTranslation() || "--"}
            goToCompanyPage={goToCompanyPage}
        />
    );
}

export default CompanyListCard;