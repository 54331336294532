import RestAPIModule from './../../RestApi';
import { IResponse } from '../../../../model';
import { IBookGetById, IBookSearchBook, IBookSearchBookOutput } from './Book.interface'
import { IBookDTO } from '../../../../model/BookDTO.interface';

export default class BookRequest {
    public static URL_BOOK = `${RestAPIModule.URL}/Livros`;
    public static URL_BOOK_GET_BY_ID = `${BookRequest.URL_BOOK}/ListarID`;
    public static URL_BOOK_SEARCH = `${BookRequest.URL_BOOK}/SearchBooks`;

    public static GetById(params: IBookGetById): Promise<IResponse<IBookDTO>> {
        return RestAPIModule.httpGet(`${BookRequest.URL_BOOK_GET_BY_ID}?idLivro=${params.idBook}`).then((response) => response);
    }

    public static SearchBooks(params: IBookSearchBook): Promise<IResponse<IBookSearchBookOutput>> {
        return RestAPIModule.httpGet(`${BookRequest.URL_BOOK_SEARCH}?searchTerm=${params.searchTerm}&page=${params.page}&amount=${params.amount}`).then((response) => response);
    }
}