import { Button, Card, CardContent, Grid, styled, Typography } from '@mui/material';

import { FratBackground } from '../../../../assets';

export const CardStyled = styled(Card)(() => ({
    marginTop: 32,
    marginBottom: 24,
    borderRadius: 16,
    width: '100%',
    backgroundImage: `url(${FratBackground})`,
    backgroundPosition: "bottom center",
    backgroundSize: "cover"
}));

export const CardContentStyled = styled(CardContent)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
}));

export const SocialButtons = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    color: "white",
    borderTopLeftRadius: 52,
    borderTopRightRadius: 52,
    borderBottomRightRadius: 52,
    boxShadow: "4px 4px 4px 2px rgba(0, 0, 0, 0.2)",
    height: "100%",
    ':hover': {
        backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down("md")]: {
        width: "100%"
    }
}));

export const Title = styled(Typography)(({ theme }) => ({
    textAlign: "center",
    fontSize: "48px",
    color: "white",
    marginBottom: "24px"
}));

export const Text = styled(Typography)(({ theme }) => ({
    marginBottom: "10px"
}));

export const GridContainer = styled(Grid)(({ theme }) => ({
    width: "100%",
    [theme.breakpoints.down("sm")]: {
        width: "60%"
    },
    [theme.breakpoints.down(420)]: {
        width: "90%"
    }
}));