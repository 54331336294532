import { colors, PaletteOptions } from '@mui/material';

export const light: PaletteOptions = {
    primary: {
        main: '#2B374F',
        light: '#87A8C3',
        dark: '#607D8B'
    },
    info: {
        main: colors.blue[500]
    },
    secondary: {
        main: colors.blueGrey[500],
        A100: colors.blue[500]
    },
    text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
    },
};

export const dark: PaletteOptions = {
    mode: "dark",
    primary: {
        main: colors.blue[500],
    },
    info: {
        main: colors.blue[500]
    },
    secondary: {
        main: colors.blueGrey[500]
    },
    text: {
        primary: colors.blueGrey[100],
        secondary: colors.blueGrey[50]
    },
};