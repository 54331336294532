import { Fade, useMediaQuery, useScrollTrigger, useTheme } from "@mui/material";
import { MouseEvent, useEffect, useState } from "react";

import { ScreenImage1, ScreenImage2, ScreenImage3, ScreenImage4, ScreenImage5, ScreenImage6, ScreenImage7 } from "../../assets";
import { useDoRequest, useHistory, useSnackbar } from "../../hooks";
import { IContactUsInput } from "../../modules/api/endpoints/brother/Brother.interface";
import { ScreenCarouselItens } from "./components/screenCarousel/ScreenCarousel.interface";
import { IBackToTop, ISection } from "./LandingPage.interface";
import { useGlobalState } from "../../context/global/Global";
import LandingPageView from "./LandingPage.view";

import { ScrollToTop } from "./LandingPage.style";
import { useThemeState } from "../../context/theme/Theme";

function LandingPage() {
    const snackbar = useSnackbar();
    const { isAuthenticated } = useGlobalState();
    const history = useHistory();
    const theme = useTheme();
    const { darkMode, toggleDarkMode } = useThemeState();
    const matches = useMediaQuery(theme.breakpoints.down("md"));

    const SendContactEmail = useDoRequest((api) => api.Brother.ContactUs);

    const [email, setEmail] = useState<string>("");

    const [sendEmail, setSendEmail] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [message, setMessage] = useState<string>("");

    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

    const [screens] = useState<ScreenCarouselItens[]>([
        { id: 1, src: ScreenImage1 },
        { id: 2, src: ScreenImage2 },
        { id: 3, src: ScreenImage3 },
        { id: 4, src: ScreenImage4 },
        { id: 5, src: ScreenImage5 },
        { id: 6, src: ScreenImage6 },
        { id: 7, src: ScreenImage7 }
    ]);

    const [sections] = useState<Array<ISection>>([
        { name: "Sobre", id: "about" },
        { name: "Funcionalidades", id: "functionalities" },
        { name: "Telas", id: "screens" },
    ]);

    const [headerBackground, setHeaderBackground] = useState<boolean>(false);

    useEffect(() => {
        if (darkMode) {
            toggleDarkMode();
        }

        window.removeEventListener('scroll', handleScroll);
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    function handleScroll() {
        (window.pageYOffset > 750) ? setHeaderBackground(true) : setHeaderBackground(false);
    }

    function ScrollTop(props: IBackToTop) {
        const { children } = props;
        const trigger = useScrollTrigger({
            disableHysteresis: true,
            threshold: 100,
        });

        return (
            <Fade in={trigger}>
                <ScrollToTop
                    onClick={backToTop}
                    role="presentation"
                >
                    {children}
                </ScrollToTop>
            </Fade>
        );
    }

    function backToTop(event: React.MouseEvent<HTMLDivElement>) {
        event.preventDefault();
        const anchor = document.getElementById("top");

        if (anchor) anchor.scrollIntoView({ behavior: matches ? "auto" : "smooth" });
    }

    function onHeaderButtonClick(id: string) {
        handleCloseNavMenu();
        let element = document.getElementById(id);
        let headerOffset = 120;
        let elementPosition = element?.getBoundingClientRect().top;
        let offsetPosition = (elementPosition || 0) + window.pageYOffset - headerOffset;

        if (element) window.scrollTo({
            top: offsetPosition,
            behavior: matches ? "auto" : "smooth"
        });
    }

    function handleOpenNavMenu(event: MouseEvent<HTMLElement>) {
        setAnchorElNav(event.currentTarget);
    }

    function handleCloseNavMenu() {
        setAnchorElNav(null);
    }

    function loginRedirect() {
        history.redirectTo("/login");
    }

    function homeRedirect() {
        history.redirectTo("/home");
    }

    function handleEmailFormChange(event: any) {
        event.preventDefault();

        const inputName = event.target.name;
        const inputValue = event.target.value;

        switch (inputName) {
            case 'email':
                setSendEmail(inputValue);
                break;
            case 'name':
                setName(inputValue);
                break;
            case 'message':
                setMessage(inputValue);
                break;
            default:
                break;
        }
    }

    function onSubmit(event: any) {
        event.preventDefault();

        const dto: IContactUsInput = {
            email: sendEmail,
            message: message,
            name: name,
        }

        SendContactEmail.doRequest(dto).then((response) => {
            if (response.dadosRetorno && response.statusExecucao) {
                snackbar(response.dadosRetorno).success();
            } else {
                snackbar("Erro ao enviar email.").error();
            }
        }).catch((error) => {
            snackbar("Falha ao enviar email. Tente novamente mais tarde.").error();
        })
    }

    return (
        <LandingPageView
            name={name}
            email={email}
            message={message}
            sendEmail={sendEmail}
            screens={screens}
            sections={sections}
            anchorElNav={anchorElNav}
            isAuthenticated={isAuthenticated}
            headerBackground={headerBackground}
            redirect={isAuthenticated ? homeRedirect : loginRedirect}
            handleEmailFormChange={handleEmailFormChange}
            handleOpenNavMenu={handleOpenNavMenu}
            handleCloseNavMenu={handleCloseNavMenu}
            onHeaderButtonClick={onHeaderButtonClick}
            setEmail={setEmail}
            onSubmit={onSubmit}
            ScrollTop={ScrollTop}
        />
    )
}

export default LandingPage;
