import RestAPIModule from '../../RestApi';
import { IResponse } from '../../../../model';
import { IProfessionalHistoryAlterDTO, IProfessionalHistoryCreateDTO } from './ProfessionalHistory.interface';
import { IProfessionalHistoryDTO } from '../../../../model/ProfessionalHistoryDTO.interface';

export default class ProfessionalHistory {
    public static URL_PROFESSIONAL_HISTORY = `${RestAPIModule.URL}/HistoricoProfissionalObreiro`;
    public static URL_PROFESSIONAL_HISTORY_UPDATE = `${ProfessionalHistory.URL_PROFESSIONAL_HISTORY}/Alterar`;
    public static URL_PROFESSIONAL_HISTORY_CREATE = `${ProfessionalHistory.URL_PROFESSIONAL_HISTORY}/Incluir`;
    public static URL_PROFESSIONAL_HISTORY_DELETE = `${ProfessionalHistory.URL_PROFESSIONAL_HISTORY}/Excluir`;

    public static Update(params: IProfessionalHistoryAlterDTO): Promise<IResponse<IProfessionalHistoryDTO>> {
        return RestAPIModule.httpPut(`${ProfessionalHistory.URL_PROFESSIONAL_HISTORY_UPDATE}`, params).then((response) => response);
    }

    public static Create(params: IProfessionalHistoryCreateDTO): Promise<IResponse<IProfessionalHistoryDTO>> {
        return RestAPIModule.httpPost(`${ProfessionalHistory.URL_PROFESSIONAL_HISTORY_CREATE}`, params).then((response) => response);
    }

    public static Delete(idProfessionalHistory: number): Promise<IResponse<IProfessionalHistoryDTO>> {
        return RestAPIModule.httpDelete(`${ProfessionalHistory.URL_PROFESSIONAL_HISTORY_DELETE}?IdHistoricoProfissional=${idProfessionalHistory}`).then((response) => response);
    }
}