import DeleteIcon from '@mui/icons-material/Delete';
import { Box, styled, Typography } from "@mui/material";

export const DefaultBox = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    marginTop: 12,
    alignItems: 'center',
}));

export const DeleteIconRed = styled(DeleteIcon)(({theme}) => ({
    color: theme.palette.error.main
}));

export const InfosText = styled(Typography)(() => ({
    marginTop: 2
}));