import { Typography, Divider, styled } from "@mui/material";

export const Header = styled('div')(() => ({
    width: '100%'
}));

export const Title = styled(Typography)(() => ({
    marginTop: 10,
    marginBottom: 6,
}));

export const SubTitle = styled('span')(() => ({
    fontWeight: 'lighter', 
    marginBottom: 16,
    width: '100%'
}));

export const Ball = styled('div')(({theme}) => ({
    width: '2px',
    height: '2px',
    borderRadius: 4,
    marginTop: 3,
    marginLeft: 8,
    marginRight: 8,
    display: "block",
    backgroundColor: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
    [theme.breakpoints.down("sm")]: {
        display: "none"
    },
}));

export const SectionDivider = styled(Divider)(() => ({
    marginTop: 12,
    marginBottom: 12
}));