import { Box, Divider, Link, styled, Typography } from '@mui/material';
import { MapContainer } from 'react-leaflet';

export const SectionHeader = styled('h2')(({theme}) => ({
    fontSize: 32,
    [theme.breakpoints.down("sm")]: {
        fontSize: 24
    }
}));

export const SectionDivider = styled(Divider)(() => ({
    marginTop: 12,
    marginBottom: 12
}));

export const ProfileBox = styled(Box)(() => ({
    display: "flex", 
    flexDirection: "row",
}));

export const Labels = styled(Typography)(({theme}) => ({
    fontWeight: "bold",
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
        fontSize: 14
    }
}));

export const ContactTexts = styled(Typography)(({theme}) => ({
    fontSize: 16,
    marginLeft: 4,
    wordBreak: "break-word",
    [theme.breakpoints.down("sm")]: {
        fontSize: 14
    }
}));

export const Site = styled(Link)(({theme}) => ({
    textDecoration: 'none',
    color: theme.palette.info.main,
    wordBreak: "break-word",
    cursor: 'pointer',
    marginLeft: 4
}));

export const MapContainerCompany = styled(MapContainer)(({theme}) => ({
    width: "100%", 
    height: "400px",
    borderRadius: 4,
    boxShadow: `0px 0px 5px -1px ${theme.palette.mode == "dark" ? '#FFFFFF' : '#000000'}`
}));