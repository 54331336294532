import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import { BrotherRedirectCard, CardHeader, CardStyled, Loading } from "../../components";

import {
    Banner,
    GoBackButton,
    LightText,
    NoContentContainer,
    PageNotFoundText,
    SectionDivider,
    Title
} from "./DetailPost.style";

import { IProps } from "./DetailPost.interface";

import { ModulesContext } from "../../context/modules/Modules";
import { IModulesContext } from "../../context/modules/Modules.interface";

function DetailPostView(props: IProps) {
    const { post, postFormatedDate, loading, goBackHome, getPostText } = props;

    const modules = useContext<IModulesContext>(ModulesContext);

    function renderBody() {
        return (
            <React.Fragment>
                {post ? (
                    <React.Fragment>
                        <CardHeader title={post?.titulo || ""} subTitle="Publicação" />

                        <LightText variant="h6">{postFormatedDate}</LightText>

                        {post?.banner && (
                            <Box>
                                <Banner src={modules.Mask.formatBase64(post?.banner)} alt="Banner da publicação" />
                            </Box>
                        )}

                        <SectionDivider />

                        <Box>
                            <LightText variant="h6"> <strong>Descrição:</strong> </LightText>

                            <Typography variant="subtitle1" padding={2}>
                                <div dangerouslySetInnerHTML={getPostText()}/>
                            </Typography>

                        </Box>

                        {post?.autor?.bitAtivo && (
                            <React.Fragment>
                                <SectionDivider />

                                <Box>
                                    <Title variant="h3">Irmão autor</Title>

                                    <BrotherRedirectCard
                                        type="linear"
                                        hasContactInformation={true}
                                        brother={post?.autor}
                                        avatarSize="large"
                                        screenName={'Detalhes de uma postagem'}
                                    />
                                </Box>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                ) : (
                    <NoContentContainer>
                        <PageNotFoundText variant="h2">Essa publicação está inativa</PageNotFoundText>

                        <GoBackButton variant="contained" onClick={goBackHome}>
                            Voltar para a home
                        </GoBackButton>
                    </NoContentContainer>
                )}
            </React.Fragment>
        )
    }

    return (
        <CardStyled>
            <Loading open={loading} />
            {!loading && (
                renderBody()
            )}
        </CardStyled>
    )
}

export default DetailPostView;