import { Button, Stack, Typography } from '@mui/material';
import { IProps } from './DeepLinkModal.interface';
import { CardContentBox, ModalBox, ModalCard, ModalContainer } from './DeepLinkModal.style';

function DeepLinkModalView(props: IProps) {
    const { visible, linkOpenApp, linkDownloadAppleStore, showAppleStoreButton, onClick, onClickClose } = props;

    return (
        <ModalContainer open={visible} onClose={onClick}>
            <ModalBox>
                <ModalCard>
                    <CardContentBox>
                        <Typography variant="h4">Deseja visualizar essa página em nosso app?</Typography>

                        <Stack spacing={1} direction="column" mt={3}>
                            <Stack spacing={1} direction="row">
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    onClick={onClickClose} >
                                    Não
                                </Button>

                                <Button
                                    variant="contained"
                                    fullWidth
                                    href={linkOpenApp}
                                    onClick={onClick} >
                                    Sim
                                </Button>
                            </Stack>

                            {showAppleStoreButton &&
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    href={linkDownloadAppleStore}
                                    onClick={onClick}
                                    style={{textTransform: "initial"}}
                                >
                                    Ainda não tenho o app
                                </Button>
                            }
                        </Stack>
                    </CardContentBox>
                </ModalCard>
            </ModalBox>
        </ModalContainer>
    );
}

export default DeepLinkModalView;