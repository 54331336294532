import { styled, Typography } from '@mui/material';

export const PageContainer = styled('div')(() => ({
    width: '100%',
}))

export const DefaultBox = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    marginTop: 12
}))

export const IconTextBox = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: 4
}))

export const InfosText = styled(Typography)(() => ({
    width: '100%',
    marginTop: 2,
    wordBreak: 'break-word'
}))
















