import {useContext, useEffect, useState} from 'react';

import HomeView from './Home.view';
import { IPostDTO } from '../../model';
import { ModulesContext } from '../../context/modules/Modules';
import { IModulesContext } from '../../context/modules/Modules.interface';
import { useArray, useDoRequest, useSnackbar, useHistory } from '../../hooks';
import { IGetVotesPagedInput } from '../../modules/api/endpoints/post/Post.interface';

function Home() {
    const history = useHistory();
    const postList = useArray<IPostDTO>([]);
    const { Analytics } = useContext<IModulesContext>(ModulesContext);
    const snackbar = useSnackbar();

    const [initialLoading, setInitialLoading] = useState<boolean>(false);

    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);
    const [amountItemsPerPage, setAmountItemsPerPage] = useState<number>(8);

    const GetPostsPaged = useDoRequest((api) => api.Post.GetPostsPaged);

    function componentDidMount() {
        Analytics.logScreen('Página principal');

        getPostsPagedRequest(1);
    }

    useEffect(componentDidMount, []);

    function getPostsPagedRequest(page: number) {
        const dto: IGetVotesPagedInput = {
            searchFeed: "",
            page: page,
            amount: amountItemsPerPage
        }

        setInitialLoading(true);

        GetPostsPaged.doRequest(dto).then((res) => {
            if (res.statusExecucao && res.dadosRetorno) {
                const data = res.dadosRetorno;
                setPage(data.page);
                setPageCount(data.pageCount);
                postList.setValue(data.listFeed);
            } else {
                snackbar(res.mensagem || "Não foi possível buscar as informações do feed.").error();
            }
        }).catch((error) => {
            snackbar("Não foi possível buscar as informações do feed.").error();
        }).finally(() => {
            setInitialLoading(false);
        });
    }

    function getNextPagedRequest() {
        const dto: IGetVotesPagedInput = {
            searchFeed: "",
            page: page + 1,
            amount: amountItemsPerPage
        }

        GetPostsPaged.doRequest(dto).then((res) => {
            if (res.statusExecucao && res.dadosRetorno) {
                const data = res.dadosRetorno;
                setPageCount(data.pageCount);
                setPage(data.page);
                postList.setValue([...postList.value, ...data.listFeed]);
            } else {
                snackbar(res.mensagem || "Não foi possível buscar as informações do feed.").error();
            }
        }).catch((error) => {
            snackbar("Não foi possível buscar as informações do feed.").error();
        })
    }

    function redirectToDetailBrother(idBrother: string | number) {
        history.redirectTo(`/detailBrother/${idBrother}`);
    }

    function redirectToDetailPost(idPost: string | number) {
        history.redirectTo(`/detailPost/${idPost}`);
    }

    return (
        <HomeView
            feed={postList.value}
            loading={initialLoading}
            infinityLoading={GetPostsPaged.loading}
            page={page}
            pageCount={pageCount}
            redirectToDetailBrother={redirectToDetailBrother}
            redirectToDetailPost={redirectToDetailPost}
            getNextPagedRequest={getNextPagedRequest}
        />
    );
}

export default Home;