import { Container, styled } from "@mui/material";

export const BoxStyled = styled(Container)(() => ({
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 32,
    marginLeft: "0px"
}));

export const smUp = {
    display: {
        xs: "block",
        sm: "none",
        md: "none",
        lg: "none",
        xl: "none"
    },
    height: "100%",
    width: "100%"
};

export const xsDown = {
    display: {
        xs: "none",
        sm: "block",
        md: "block",
        lg: "block",
        xl: "block"
    },
    height: "100%",
    width: "100%"
};