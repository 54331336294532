import React, { useContext } from 'react';
import { TabPanel } from '@mui/lab';
import { Box, Divider, Grid, Tabs, Typography } from '@mui/material';

import { CardStyled, Loading, DeepLinkModal } from '../../components';
import {
    ProfessionalHistory, AcademicHistory, BrotherPost,
    Information, Languages, ProfileModal
} from './components';

import { IProps } from './DetailBrother.interface';

import { ModulesContext } from "../../context/modules/Modules";
import { IModulesContext } from "../../context/modules/Modules.interface";

import {
    NoDataContainer, TabContextStyled,
    SecondayText, TabItem, ProfileInfo,
    ProfileCard, ProfileImg, NameBrother
} from "./DetailBrother.style";

function DetailBrotherView(props: IProps) {
    const {
        tabSelect, academyRecordData,
        languageData, infoBrother,
        professionalHistory, isMyProfile, loading,
        brotherPosts, modalEditState,
        postPageCount, postPage, infinityLoading,
        setLanguageData, setProfessionalHistory,
        setInfoBrother, handleTabChange,
        setModalEditState, getNextBrotherPosts
    } = props;

    const { Mask } = useContext<IModulesContext>(ModulesContext);

    function renderData() {
        if (loading) {
            return (
                <Loading open={loading} />
            );
        } else {
            return (
                <React.Fragment>
                    {renderCard()}
                </React.Fragment>
            );
        }
    }

    function renderCard() {
        if (infoBrother) {
            return (
                <CardStyled>
                    <ProfileCard gap={2}>
                        <ProfileImg src={Mask.formatBase64(infoBrother.imageBrother)} />
                        <ProfileInfo>
                            <NameBrother>{infoBrother.nameBrother}</NameBrother>
                            <SecondayText>{infoBrother.occupationBrother}</SecondayText>

                            <Typography variant="body1" >
                                {infoBrother.ageBrother + " anos de idade"}
                            </Typography>
                        </ProfileInfo>
                    </ProfileCard>

                    <Divider style={{ marginTop: 16 }} />

                    <TabContextStyled value={tabSelect}>
                        <Tabs
                            aria-label="profile tabs"
                            textColor="primary"
                            indicatorColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            allowScrollButtonsMobile
                            onChange={(e, value) => handleTabChange(value)}
                            style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
                            value={tabSelect}
                        >
                            <TabItem label="Publicações" value="1" />
                            <TabItem label="Histórico Acadêmico" value="2" />
                            <TabItem label="Histórico Profissional" value="3" />
                            <TabItem label="Idiomas" value="4" />
                            <TabItem label="Informações e contato" value="5" />
                        </Tabs>

                        <TabPanel value="1">
                            <BrotherPost
                                brotherName={infoBrother.nameBrother}
                                brotherIcon={infoBrother.imageBrother}
                                postPage={postPage}
                                postPageCount={postPageCount}
                                brotherPosts={brotherPosts}
                                getNextBrotherPosts={getNextBrotherPosts}
                                infinityLoading={infinityLoading}
                            />
                        </TabPanel>
                        <TabPanel value="2">
                            <AcademicHistory
                                academicHistoryData={academyRecordData}
                                isMyProfile={isMyProfile}
                            />
                        </TabPanel>
                        <TabPanel value="3">
                            <ProfessionalHistory
                                professionalHistory={professionalHistory}
                                isMyProfile={isMyProfile}
                                setProfessionalHistory={setProfessionalHistory}
                            />
                        </TabPanel>
                        <TabPanel value="4">
                            <Languages
                                languageData={languageData}
                                isMyProfile={isMyProfile}
                                setLanguageData={setLanguageData}
                            />
                        </TabPanel>
                        <TabPanel value="5">
                            <Information
                                infoBrother={infoBrother}
                                isMyProfile={isMyProfile}
                                setModalEditState={setModalEditState}
                            />
                        </TabPanel>
                    </TabContextStyled>

                    <ProfileModal infoBrother={infoBrother} openState={modalEditState} setOpenState={setModalEditState} setInfoBrother={setInfoBrother} />
                </CardStyled >
            );
        } else {
            return (
                <NoDataContainer>
                    Nenhum dado encontrado.
                </NoDataContainer>
            );
        }
    }

    return (
        <Box>
            {renderData()}
        </Box>
    );
}

export default DetailBrotherView;