import { Grid, TextField } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers';

import { FormCardHeader } from "../../../../../components";
import { IPropsView } from "./WorkExperienceModal.interface";
import { ModalContainer, ModalBox, ModalCard, CardContentBox } from "./WorkExperienceModal.style";

function WorkExperienceModalView(props: IPropsView) {
    const {
        modalVisible,
        companyName,
        initialDate,
        endDate,
        companyPosition,
        description,
        loading,
        type,
        onClose,
        setInitialDate,
        setEndDate,
        handleSubmit,
        handleInputChange
    } = props;

    return (
        <ModalContainer open={modalVisible} onClose={onClose}>
            <ModalBox>
                <ModalCard>
                    <CardContentBox>
                        <form onSubmit={(e) => handleSubmit(e)} onChange={(e) => handleInputChange(e)}>
                            <FormCardHeader
                                onCloseClick={onClose}
                                title={`${type == "create" ? "Nova Experiência Profissional" : "Editar Experiência Profissional"}`}
                                subtitle={"Informações da experiência"}
                                loading={loading}
                            />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        variant="outlined"
                                        label="Cargo"
                                        name="companyPosition"
                                        required
                                        value={companyPosition}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        variant="outlined"
                                        label="Nome da empresa"
                                        name="companyName"
                                        required
                                        value={companyName}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        variant="outlined"
                                        label="Descrição da atividade"
                                        name="description"
                                        required
                                        value={description}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <DatePicker
                                        label="Data de início"
                                        inputFormat="dd/MM/yyyy"
                                        value={initialDate}
                                        maxDate={endDate == null ? undefined : endDate}
                                        onChange={(newValue: Date | string | null) => {
                                            setInitialDate(newValue || "");
                                        }}
                                        renderInput={(params: any) => <TextField required fullWidth {...params} />}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <DatePicker
                                        label="Data de término"
                                        inputFormat="dd/MM/yyyy"
                                        value={endDate}
                                        minDate={initialDate == null ? undefined : initialDate}
                                        onChange={(newValue: Date | string | null) => {
                                            setEndDate(newValue || "");
                                        }}
                                        renderInput={(params: any) => <TextField required fullWidth {...params} />}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </CardContentBox>
                </ModalCard>
            </ModalBox>
        </ModalContainer>
    );
}

export default WorkExperienceModalView;