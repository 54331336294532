import { useContext } from 'react';
import { Typography } from '@mui/material';

import { GenericCardList } from '../../../../components';
import { ICardListJobViewProps } from './CardListJob.interface';
import { ModulesContext } from '../../../../context/modules/Modules';
import { IModulesContext } from '../../../../context/modules/Modules.interface';

function CardListJobView(props: ICardListJobViewProps) {
    const { title, companyName, description, redirect } = props;
    const { Mask } = useContext<IModulesContext>(ModulesContext);

    return (
        <GenericCardList title={title} goToPage={redirect}>
            <Typography variant="body2"> Companhia: {companyName} </Typography>
            <Typography variant="body2"> Descrição: {Mask.redutorString(description, 150)} </Typography>
        </GenericCardList>
    );
}

export default CardListJobView;