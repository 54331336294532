import { Container, Paper, styled, Typography } from '@mui/material';

import { IPaperInterface } from './InfoCard.interface';

export const ContainerStyled = styled(Container)(() => ({
    margin: 'auto',
    display: 'flex',
    paddingLeft: 0,
    paddingRight: 0,
    flexDirection: 'column',
}));

export const DefaultText = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.dark,
    fontWeight: 700,
    fontSize: 36,
    [theme.breakpoints.down("md")]: {
        fontSize: 30,
    },
}));

export const Title = styled(Typography)(({ theme }) => ({
    fontSize: "22px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    color: theme.palette.mode === "dark" ? theme.palette.primary.dark : "#5b32b4",
    marginBottom: "15px"
}));

export const Text = styled(Typography)(({ theme }) => ({
    fontSize: "16px",
    textAlign: "center",
    fontWeight: 300
}));

export const PaperElevate = styled(Paper, { shouldForwardProp: (prop) => prop !== 'bordered' && prop !== 'height' })<IPaperInterface>(({ bordered, height, theme }) => ({
    padding: 16,
    borderTopLeftRadius: bordered ? 52 : 0,
    borderTopRightRadius: bordered ? 52 : 0,
    borderBottomRightRadius: bordered ? 52 : 0,
    minHeight: height ? height : "auto",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: bordered ? "center" : "normal",
    alignItems: "center",
    transition: "all 0.5s ease-in-out",
    [theme.breakpoints.down('sm')]: {
        justifyContent: "center",
        minHeight: "auto"
    }
}));