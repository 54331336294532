import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Typography, Box, IconButton,  } from '@mui/material';

import { LanguageModal } from './../../';
import { ILanguagesView } from './Languages.interface';
import { DefaultBox, DeleteIconRed, InfosText } from './Languages.styles';
import { ITblIdiomaObreiro } from '../../../../../model/BrotherDTO.interface';

function LanguagesView(props: ILanguagesView) {
    const { 
        addLanguageModalVisible, 
        isMyProfile,
        languageData,
        modalType,
        editItem,
        openLanguageModal,
        closeLanguageModal, 
        setLanguageData,
        deleteLanguage,
    } = props;

    function languageFluency(item: ITblIdiomaObreiro) {
        if (item.bitAvancado) {
            return "Avançado";
        }
        else if (item.bitBasico) {
            return "Básico";
        }
        else if (item.bitFluente) {
            return "Fluente";
        }
        else {
            return "Intermediário"
        }
    }

    return (
        <React.Fragment>
            <DefaultBox justifyContent={"space-between"} style={{marginTop: 0}}>
                <Typography variant="subtitle1" color="secondary">IDIOMAS</Typography>
                {isMyProfile && (
                    <Box>
                        <IconButton onClick={() => openLanguageModal('create')}>
                            <AddIcon />
                        </IconButton>
                    </Box>
                )}
            </DefaultBox>

            {languageData.length >= 1 ? (
                <React.Fragment>
                    {languageData.map((item, idx) => (
                        <DefaultBox key={idx} justifyContent="space-between">
                            <Typography variant="overline">{item.idIdiomaNavigation.idioma} - {languageFluency(item)}</Typography>
                            
                            {isMyProfile && (
                                <Box>
                                    <IconButton onClick={() => openLanguageModal('edit', item)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => deleteLanguage(item)}>
                                        <DeleteIconRed />
                                    </IconButton>
                                </Box>
                                
                            )}
                        </DefaultBox>
                    ))}
                </React.Fragment>
            ) : (
                <DefaultBox>
                    <InfosText variant="overline">Este obreiro não possui idiomas cadastrados.</InfosText>
                </DefaultBox>
            )}

            <LanguageModal
                modalVisible={addLanguageModalVisible}
                userLanguageList={languageData}
                modalType={modalType}
                editItem={editItem}
                onClose={closeLanguageModal}
                setLanguagesList={setLanguageData}
            />
        </React.Fragment >
    );
}

export default LanguagesView;