import { createContext } from 'react';

import {Mask, RestApi, Encryption, Firebase, AnalyticsModule, UserSystem} from '../../modules';
import {IModulesContext, IProps} from "./Modules.interface";

const modules = {
    Mask: new Mask(),
    RestApi: new RestApi(),
    Encryption: new Encryption(),
    Firebase: new Firebase(),
    Analytics: new AnalyticsModule(),
    UserSystem: new UserSystem()
}

export const ModulesContext = createContext<IModulesContext>(modules);

export function ModulesProvider(props: IProps) {
    return (
        <ModulesContext.Provider value={modules}>
            {props.children}
        </ModulesContext.Provider>
    );
}