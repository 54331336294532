import { DatePicker } from '@mui/x-date-pickers';
import { Grid, InputLabel, MenuItem, TextField, Select } from "@mui/material";

import { IPropsView } from "./AcademicModal.interface";
import { FormCardHeader } from '../../../../../components';
import { IInstructionLevelDTO } from '../../../../../model';
import { CardContentBox, ModalBox, ModalCard, ModalContainer, FormControlStyled } from "./AcademicModal.style";

function AcademicModalView(props: IPropsView) {
    const {
        openState,
        educationLevel,
        initialDate,
        endDate,
        nameSchool,
        classBrother,
        concluded,
        type,
        loading,
        instructionLevelList,
        handleInputChange,
        handleSelectChange,
        handleSubmit,
        setInitialDate,
        setEndDate,
        onClose
    } = props;

    function renderInstructionLevel() {
        if(instructionLevelList.length == 0) return;
        return (
            instructionLevelList.map((instructionLevel: IInstructionLevelDTO, idx: number) => {
                return (
                    <MenuItem value = {instructionLevel.idNivelInstrucao} key = {idx}>{instructionLevel.descricaoInstrucao}</MenuItem>
                )
            })
        );
    }
    return (
        <ModalContainer open={openState} onClose={onClose}>
            <ModalBox>
                <ModalCard>
                    <CardContentBox>
                        <form onSubmit={(e) => handleSubmit(e)} onChange={(e) => handleInputChange(e)}>
                            <FormCardHeader
                                onCloseClick={onClose}
                                title={`${type === "create" ? "Nova Formação Acadêmica" : "Editar Formação Acadêmica"}`}
                                subtitle={"Informações da formação"}
                                loading={loading}
                            />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        variant="outlined"
                                        label="Nome instituição"
                                        name="nameSchool"
                                        value={nameSchool}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        variant="outlined"
                                        label="Curso"
                                        name="classBrother"
                                        value={classBrother}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <FormControlStyled>
                                        <InputLabel id="selectEducationLevel">Nível instrução*</InputLabel>
                                        <Select
                                            labelId="selectEducationLevel"
                                            id="selectEducationLevel"
                                            name="selectEducationLevel"
                                            value={educationLevel}
                                            label="Nível instrução*"
                                            onChange={(event: any) => handleSelectChange(event)}
                                            required
                                            fullWidth
                                        >
                                            {renderInstructionLevel()}
                                        </Select>
                                    </FormControlStyled>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <FormControlStyled>
                                        <InputLabel id="selectConcluded">Status conclusão</InputLabel>
                                        <Select
                                            labelId="selectConcluded"
                                            id="selectConcluded"
                                            name="selectConcluded"
                                            value={concluded}
                                            label="Status conclusão"
                                            onChange={(event: any) => handleSelectChange(event)}
                                            required
                                            fullWidth
                                        >
                                            <MenuItem value={1}> Concluído </MenuItem>
                                            <MenuItem value={0}> Não Concluído </MenuItem>
                                        </Select>
                                    </FormControlStyled>
                                </Grid>
                                <Grid item xs={12} sm={12} md={concluded ? 6 : 12} lg={concluded ? 6 : 12}>
                                    <DatePicker
                                        label="Data de início"
                                        inputFormat="dd/MM/yyyy"
                                        value={initialDate}
                                        maxDate={endDate ?? ''}
                                        onChange={(newValue: Date | string | null) => {
                                            setInitialDate(newValue || "");
                                        }}
                                        renderInput={(params: any) => <TextField required fullWidth {...params} />}
                                    />
                                </Grid>

                                {!!concluded &&
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <DatePicker
                                            label="Data de término"
                                            inputFormat="dd/MM/yyyy"
                                            value={endDate}
                                            minDate={initialDate ?? ''}
                                            onChange={(newValue: Date | string | null) => {
                                                setEndDate(newValue || "");
                                            }}
                                            renderInput={(params: any) => <TextField required={!!concluded} fullWidth {...params} />}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </form>
                    </CardContentBox>
                </ModalCard>
            </ModalBox>
        </ModalContainer >
    );
}

export default AcademicModalView;