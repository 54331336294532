import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import CallIcon from '@mui/icons-material/Call';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import IosShareIcon from '@mui/icons-material/IosShare';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BlockIcon from '@mui/icons-material/Block';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { IProps } from "./DetailJob.interface";

import { BrotherRedirectCard, CardHeader, CardStyled, Loading } from "../../components";

import {
    ApplyButton, Ball, CloseText, Company, IconLabelBox,
    InformationBox, OpenText, Oriente, SituationBox,
} from "./DetailJob.style";

function DetailJobView(props: IProps) {
    const { job, loading, jobCreationDate, alreadyApplied,
        applyLoading, brotherTelephone, closed, remuneration,
        jobFinishedDate, applyToJob
    } = props;

    return (
        <Box>
            <Loading open={loading} />

            {!loading && (
                <React.Fragment>
                    <CardStyled>
                        <CardHeader
                            title={job?.nomeVaga || ""}
                            subTitle={"Informações da Vaga"}
                            options={[
                                <>
                                    <Company variant="subtitle1" underline="hover">{job?.nomeEmpresa}</Company>
                                    <Ball />
                                    <Oriente>{job?.orienteTrabalho}</Oriente>
                                    <Ball />
                                    <Typography variant="subtitle1">{job?.bitAtivo && !closed ? "Aberta" : "Fechada"} a {job?.bitAtivo && !closed ? jobCreationDate : jobFinishedDate}.</Typography>
                                </>
                            ]}
                        />

                        <IconLabelBox>
                            <CallIcon />
                            <Typography variant="subtitle1" paddingLeft={1}>{brotherTelephone}</Typography>
                        </IconLabelBox>

                        <IconLabelBox>
                            <MonetizationOnIcon />
                            <Typography variant="subtitle1" paddingLeft={1}>R$ {remuneration}</Typography>
                        </IconLabelBox>

                        <IconLabelBox>
                            <AccessTimeIcon />
                            <Typography variant="subtitle1" paddingLeft={1}>{job?.bitTemporario ? "Temporário" : "Permanente"}</Typography>
                        </IconLabelBox>

                        <IconLabelBox>
                            <Typography variant="subtitle1">Possui comissão: </Typography>
                            <Typography variant="subtitle1" paddingLeft={1}>{job?.bitComissao ? "Sim" : "Não"}</Typography>
                        </IconLabelBox>

                        <IconLabelBox>
                            <Typography variant="subtitle1">Situação: </Typography>
                            {job?.bitAtivo && !closed ? (
                                <SituationBox>
                                    <OpenText variant="subtitle1">Aberta </OpenText>
                                    <CheckCircleOutlineIcon color={"success"} />
                                </SituationBox>
                            ) : (
                                <SituationBox>
                                    <CloseText variant="subtitle1">Fechada </CloseText>
                                    <BlockIcon color={"error"} />
                                </SituationBox>
                            )}
                        </IconLabelBox>

                        <ApplyButton
                            variant="contained"
                            disabled={(!job?.bitAtivo || closed || alreadyApplied)}
                            endIcon={<IosShareIcon />}
                            onClick={applyToJob}
                        >
                            {applyLoading ? (
                                <CircularProgress size={24} />
                            ) : (
                                alreadyApplied ? "Já se candidatou" : "Candidatar-se"
                            )}
                        </ApplyButton>
                    </CardStyled>

                    <CardStyled>
                        <CardHeader
                            title={"Sobre a vaga"}
                        />

                        <InformationBox>
                            <Typography variant="h5" >Descrição: </Typography>
                            <Typography variant="subtitle1" lineHeight={1.5}>{job?.descricaoVaga}</Typography>
                        </InformationBox>

                        <InformationBox>
                            <Typography variant="h5" >Habilidades esperadas: </Typography>
                            <Typography variant="subtitle1" lineHeight={1.5}>{job?.habilidadesRequeridas}</Typography>
                        </InformationBox>

                        <InformationBox>
                            <Typography variant="h5" >Benefícios: </Typography>
                            <Typography variant="subtitle1" lineHeight={1.5}>{job?.descricaoBeneficios}</Typography>
                        </InformationBox>
                    </CardStyled>

                    {job?.idObreiroNavigation && job?.idObreiroNavigation?.bitAtivo && (
                        <CardStyled>
                            <CardHeader title={"Irmão Ofertador"} />

                            <BrotherRedirectCard
                                type="linear"
                                hasContactInformation={true}
                                brother={job?.idObreiroNavigation}
                                avatarSize="large"
                                screenName="Detalhes de uma vaga"
                            />
                        </CardStyled>
                    )}
                </React.Fragment>
            )}
        </Box>
    );
}

export default DetailJobView;