import { LoadingButton } from "@mui/lab";
import SearchIcon from '@mui/icons-material/Search';
import { Box, Grid, TextField } from "@mui/material";

import { Title, TextNoData } from "./ListJob.style";
import { CardListJob } from "./components";
import Loading from "../../components/loading/Loading";
import { IListJobViewProps } from "./ListJob.interface";
import { CardStyled, InfinityScroll, } from "../../components";

function ListJobView(props: IListJobViewProps) {
    const {
        jobList, loading,
        searchTerm, page,
        pageCount,
        setSearchTerm, submitFilter,
        nextPage
    } = props;

    function renderCards() {
        if (jobList.length >= 1) {
            return jobList.map((job, idx) => (
                <Grid item xs={12} sm={12} md={6} lg={6} key={idx}>
                    <CardListJob item={job} />
                </Grid>
            ));
        } else {
            return (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextNoData>
                        Nenhuma vaga encontrada.
                    </TextNoData>
                </Grid>
            );
        }
    }

    return (
        <CardStyled>
            <Loading open={loading} />
            <Title variant="h3"> Vagas </Title>

            <Grid container spacing={2} mt={1} mb={2} display="flex" alignItems="center">
                <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                    <TextField
                        value={searchTerm}
                        fullWidth
                        size="small"
                        label="Buscar vagas"
                        id="search-jobs"
                        type="search"
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                    <LoadingButton
                        loading={loading}
                        disabled={loading}
                        variant="contained"
                        fullWidth
                        onClick={submitFilter}

                    >
                        <SearchIcon />
                    </LoadingButton>
                </Grid>
            </Grid>

            {!loading &&
                <Grid container spacing={1}>
                    {renderCards()}
                </Grid>
            }

            {jobList && (page < pageCount) && !loading && (
                <InfinityScroll callBack={nextPage} />
            )}
        </CardStyled>
    );
}

export default ListJobView;