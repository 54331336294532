import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import DetailEventView from './DetailEvent.view';
import { IEventDTO } from '../../model';
import { useDoRequest, useHistory, useSnackbar } from '../../hooks';
import { IGetEventByIdInput } from '../../modules/api/endpoints/event/Event.interface';
import { IModulesContext } from '../../context/modules/Modules.interface';
import { ModulesContext } from '../../context/modules/Modules';
import {IDeepLinkContext} from "../../context/deepLink/DeepLink.interface";
import {DeepLinkContext} from "../../context/deepLink/DeepLink";

function DetailEvent() {
    const { idEvent } = useParams<string>();
    const history = useHistory();
    const snackbar = useSnackbar();

    const { Analytics } = useContext<IModulesContext>(ModulesContext);
    const { setRedirectScreen } = useContext<IDeepLinkContext>(DeepLinkContext);

    const [eventDTO, setEventDTO] = useState<IEventDTO | null>(null);
    const GetEventById = useDoRequest((api) => api.Event.GetEventById);

    useEffect(componentDidMount, [idEvent]);

    function componentDidMount() {
        Analytics.logScreen('Detalhes de um evento');
        setRedirectScreen(`Login/DetailEvent/${idEvent}`);

        getEventById();

        return () => {
            setRedirectScreen(null);
        }
    }

    function getEventById() {
        if (!idEvent) return;

        const dto: IGetEventByIdInput = {
            idCalendario: Number(idEvent)
        }

        GetEventById.doRequest(dto).then((res) => {
            if (res.statusExecucao && res.dadosRetorno) {
                setEventDTO(res.dadosRetorno);
            } else {
                snackbar(res.mensagem || "Não foi possível buscar os dados do evento.").error();
                history.redirectTo("/home");
            }
        }).catch((error) => {
            snackbar("Não foi possível buscar os dados do evento.").error();
            history.redirectTo("/404");
        });
    }

    function openLink(link: string) {
        if (!link) return;
        window.open(link, "_blank");
    }

    function goToBrotherPage(idBrother: number | undefined) {
        if(!idBrother) return;
        history.redirectTo(`/detailBrother/${idBrother}`);
    }

    return (
        <DetailEventView
            event={eventDTO}
            loading={GetEventById.loading}
            openLink={openLink}
            goToBrotherPage={goToBrotherPage}
        />
    );
}

export default DetailEvent;