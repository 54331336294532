import { IProps } from "./Information.interface";
import InformationView from "./Information.view";

function Information(props: IProps) {

    const { infoBrother, isMyProfile, setModalEditState } = props;

    return (
        <InformationView
            infoBrother={infoBrother}
            isMyProfile={isMyProfile}
            setModalEditState={setModalEditState}
        />
    );
}

export default Information;