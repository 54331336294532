import { Button, Container, styled, Typography } from '@mui/material';

export const Title404 = styled(Typography)(({theme}) => ({
    textAlign: 'center',
    letterSpacing: '-0.24px',
    fontWeight: 500,
    fontSize: 192,
}))

export const PageContainer = styled(Container)(({theme}) => ({
    alignItems: 'center', 
    height: '80vh',
    display: "flex", 
    flexDirection: "column", 
    justifyContent: "center",
    color: theme.palette.primary.main
}))

export const GoBackButton = styled(Button)(({theme}) => ({
    textTransform: "uppercase",
    backgroundColor: theme.palette.primary.main
}))

export const PageNotFoundText = styled(Typography)(({theme}) => ({
    color: theme.palette.text.primary,
}))

export const NoBrothersText = styled(Typography)(({theme}) => ({
    color: theme.palette.text.primary,
    paddingTop: 20,
    paddingBottom: 30
}))