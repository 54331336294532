import { Box } from "@mui/material";

import { InfoCardViewProps } from "./InfoCard.interface";

import { PaperElevate, Text, Title } from "./InfoCard.style";

function InfoCardView(props: InfoCardViewProps) {
    const { icon, text, title, cardShadow, inLine, bordered, height, setCardShadow } = props;

    return (
        <PaperElevate 
            height={height}
            elevation={cardShadow} 
            bordered={bordered}
            onMouseOver={() => setCardShadow(24)} 
            onMouseOut={() => setCardShadow(3)}
        >
            <Box display={inLine ? "flex" : "inherit"} flexDirection={inLine ? "row" : "column"}>
                <Box mr={inLine ? 1 : 0} mb={2} display="flex" justifyContent="center">
                    {icon}
                </Box>

                <Title variant="h4">{title}</Title>
            </Box>

            <Text variant="body1">{text}</Text>
        </PaperElevate>
    )
}

export default InfoCardView;