import { Box, styled, Typography } from "@mui/material";

export const Text = styled(Typography)(({ theme }) => ({
    cursor: 'pointer',
    fontWeight: "bolder",
    fontSize: "20px",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
        display: "none",
    },
}));

export const LinkText = styled("div")(({theme}) => ({
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: "10px",
    marginBottom: "10px",
    borderRadius: "20px",
    width: "100%",
    ':hover': {
        backgroundColor: theme.palette.mode == "dark" ? '#e8f5fe' : '#d7e4ed',

        color: theme.palette.primary.main,
        transition: "200ms ease-out",
    },
    [theme.breakpoints.down("md")]: {
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "0px",
        paddingRight: "0px",
    },
    [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
    }
}));