import React, { useEffect } from 'react';
import { useRoutes, Navigate, useLocation, useMatch } from 'react-router-dom';

import { MainLayout } from '../layouts';
import { useGlobalState } from '../context/global/Global';
import {
    DetailCompany,
    DetailJob,
    DetailBrother,
    Home,
    Login,
    DetailEvent,
    PageNotFound,
    DetailBook,
    DetailPost,
    ListEvent,
    ListJob,
    ListCompany,
    ListBook,
    ListBrother,
    LandingPage
} from './../pages';
import { useHistory } from '../hooks';

function Routes() {
    const { isAuthenticated } = useGlobalState();
    const history = useHistory();
    const location = useLocation();
    const match = useMatch('/r=:screen&i=:idScreen');

    useEffect(() => {
        if (!match || !isAuthenticated) return;

        if (match.params.screen && match.params.idScreen) {
            history.redirectTo(`/${match.params.screen}/${match.params.idScreen}`);
        } else {
            history.redirectTo(`/home`);
        }
    }, [location.pathname, isAuthenticated]);

    const privateRoutes = useRoutes([
        {
            element: <MainLayout />,
            children: [
                { path: '/home', element: <Home /> },
                { path: '/detailBook/:idBook', element: <DetailBook /> },
                { path: '/detailBrother/:idBrother', element: <DetailBrother /> },
                { path: '/detailCompany/:idCompany', element: <DetailCompany /> },
                { path: '/detailEvent/:idEvent', element: <DetailEvent /> },
                { path: '/detailJob/:idJob', element: <DetailJob /> },
                { path: '/detailPost/:idPost', element: <DetailPost /> },
                { path: '/listBook', element: <ListBook /> },
                { path: '/listCompany', element: <ListCompany /> },
                { path: '/listEvent', element: <ListEvent /> },
                { path: '/listJob', element: <ListJob /> },
                { path: '/listBrother', element: <ListBrother /> },
                { path: '/404', element: <PageNotFound /> }
            ]
        },
        { path: '/landingPage', element: <LandingPage /> },
        { path: "/", element: <Navigate to='/home' /> },
        { path: "/r=:screen&i=:idScreen", element: <Home /> },
        { path: "*", element: <Navigate to='/404' /> }
    ]);

    const publicRoutes = useRoutes([    
        { path: "/login", element: <Login /> },
        { path: '/landingPage', element: <LandingPage /> },
        { path: "/r=:screen&i=:idScreen", element: <Login /> },
        { path: "*", element: <Navigate to='/landingPage' /> },
    ]);

    return (
        <React.Fragment>
            {isAuthenticated ? (
                privateRoutes
            ) : (
                publicRoutes
            )}
        </React.Fragment>
    );
}

export default Routes;