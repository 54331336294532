import RestAPIModule from './../../RestApi';
import { IResponse, IJobDTO } from '../../../../model';
import {
    IJobApplyToJob,
    IJobBrotherIsCandidate,
    IJobGetById,
    IGetJobsPagedInput,
    IGetJobsPagedOutput
} from './Job.interface';

export default class JobRequest {
    public static URL_JOB = `${RestAPIModule.URL}/Vagas`;
    public static URL_JOB_GET_BY_ID = `${JobRequest.URL_JOB}/ListarById`;
    public static URL_JOB_BROTHER_IS_CANDIDATE = `${JobRequest.URL_JOB}/GetBrotherIsCandidate`;
    public static URL_JOB_APPLY_TO_JOB = `${JobRequest.URL_JOB}/CandidatarVaga`;
    public static URL_JOB_GET_PAGED = `${JobRequest.URL_JOB}/SearchJobs`;

    public static GetById(params: IJobGetById): Promise<IResponse<IJobDTO>> {
        return RestAPIModule.httpGet(`${JobRequest.URL_JOB_GET_BY_ID}?idVaga=${params.idJob}`).then((response) => response);
    }

    public static ApplyToJob(params: IJobApplyToJob): Promise<IResponse<IJobApplyToJob>> {
        return RestAPIModule.httpPost(`${JobRequest.URL_JOB_APPLY_TO_JOB}`, params).then((response) => response);
    }

    public static BrotherIsCandidate(params: IJobBrotherIsCandidate): Promise<IResponse<boolean>> {
        return RestAPIModule.httpGet(`${JobRequest.URL_JOB_BROTHER_IS_CANDIDATE}?idJob=${params.idJob}&idBrother=${params.idBrother}`).then((response) => response);
    }

    public static GetJobsPaged(params: IGetJobsPagedInput): Promise<IResponse<IGetJobsPagedOutput>> {
        return RestAPIModule.httpGet(`${JobRequest.URL_JOB_GET_PAGED}?searchTerm=${params.searchTerm}&page=${params.page}&amount=${params.amount}`).then((response) => response);
    }
}