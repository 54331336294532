import React from 'react';
import { Typography } from '@mui/material';

import { DefaultBox } from "./BrotherPost.style";
import { PageContainer } from "./BrotherPost.style";
import { IPropsView } from "./BrotherPost.interface";
import { InfinityScroll, PostCard } from "../../../../../components";

function BrotherPostView(props: IPropsView) {
    const {
        brotherPosts,
        postPageCount,
        postPage,
        infinityLoading,
        brotherName,
        brotherIcon,
        getNextBrotherPosts,
        redirectToDetailPost
    } = props;

    function renderBrotherPosts() {
        if (brotherPosts.length > 0) {
            return (
                <React.Fragment>
                    {brotherPosts.map((item, index) => {
                        item.nomeAutor = brotherName
                        item.fotoAutor = brotherIcon
                        return (
                            <PostCard
                                post={item}
                                redirectToDetailPost={redirectToDetailPost}
                                variant="outlined"
                                key={index}
                            />
                        )
                    })}

                    {(brotherPosts && (postPage < postPageCount)) && !infinityLoading && (
                        <InfinityScroll callBack={getNextBrotherPosts} />
                    )}
                </React.Fragment>
            )
        } else {
            return (
                <DefaultBox>
                    <Typography variant="overline">
                        Este obreiro ainda não fez postagens.
                    </Typography>
                </DefaultBox>
            )
        }
    }

    return (
        <PageContainer>
            {renderBrotherPosts()}
        </PageContainer>
    );
}

export default BrotherPostView;