import { IBannerViewProps } from "./Banner.interface";
import BannerView from "./Banner.view";

function Banner(props: IBannerViewProps) {
    const { isAuthenticated, redirect } = props;

    return (
        <BannerView
            isAuthenticated={isAuthenticated}
            redirect={redirect}
        />
    )
}

export default Banner;
