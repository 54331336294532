import React from "react";
import { Grid, Fab, useMediaQuery, useTheme } from "@mui/material";
import {
    LibraryBooks, Language, Event,
    Person, KeyboardArrowUp,
    Business, DomainAdd
} from "@mui/icons-material/";

import { ILandingPageView } from "./LandingPage.interface";
import { Banner, InfoCard, SocialCard, ScreenCarousel, MailCard, Header, Footer } from "./components";
import { FratVideo } from "../../assets";

import { ContainerStyled, DefaultTitle, LandingPageVideo, Section, SectionDivider, StyledBox, VideoBox } from "./LandingPage.style";

function LandingPageView(props: ILandingPageView) {
    const {
        screens, message, sendEmail, name, anchorElNav, sections, headerBackground, isAuthenticated,
        handleCloseNavMenu, handleOpenNavMenu, handleEmailFormChange,
        onSubmit, onHeaderButtonClick, ScrollTop, redirect
    } = props;

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <React.Fragment>
            <StyledBox id="top">
                <Header
                    anchorElNav={anchorElNav}
                    sections={sections}
                    isAuthenticated={isAuthenticated}
                    headerBackground={headerBackground}
                    redirect={redirect}
                    handleCloseNavMenu={handleCloseNavMenu}
                    handleOpenNavMenu={handleOpenNavMenu}
                    onHeaderButtonClick={onHeaderButtonClick}
                />

                <ContainerStyled maxWidth="lg" sx={{ marginTop: { sm: 15, md: "auto" } }}>
                    <Banner
                        isAuthenticated={isAuthenticated}
                        redirect={redirect}
                    />
                </ContainerStyled>
            </StyledBox>

            <ContainerStyled maxWidth="md">

                <Section id="about">
                    <DefaultTitle>
                        O que é o FRAT?
                    </DefaultTitle>
                    <SectionDivider />
                    <span>
                        {'Programa de benefícios desenvolvido entre irmãos com o mercado em geral, entidades civis e de governos, em prol da família maçônica da GLMMG.'}
                    </span>
                    <VideoBox controls>
                        <LandingPageVideo
                            src={`${FratVideo}`}
                            type="video/mp4"
                            title="Frat - vídeo de apresentação"
                        />
                    </VideoBox>
                    <SocialCard />
                </Section>

                <Section id="functionalities">
                    <DefaultTitle>
                        Principais Funcionalidades
                    </DefaultTitle>
                    <SectionDivider />

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4}>
                            <InfoCard
                                title="Pesquisa de Irmãos"
                                text="Aqui são feitas as buscas dos Irmãos filiados à GLMMG que já estão participando do programa FRAT. A pesquisa poderá ser realizada por nome, cidade, loja, etc."
                                icon={<Person fontSize="large" />}
                                bordered={false}
                                inLine={true}
                                height={230}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <InfoCard
                                title="Empresa de Irmãos"
                                text="Opção onde o participante do programa FRAT pesquisa as empresas ou negócios de seu interesse para realizar transações e contratações de serviços diretamente com os Irmãos."
                                icon={<Business fontSize="large" />}
                                bordered={false}
                                inLine={true}
                                height={230}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <InfoCard
                                title="Convênios"
                                text="A GLMMG possui convênios com empresas que estão oferecendo descontos exclusivos para Irmãos e seus familiares. Escolha um desconto aqui e economize!"
                                icon={<LibraryBooks fontSize="large" />}
                                bordered={false}
                                inLine={true}
                                height={230}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <InfoCard
                                title="Portal GLMMG"
                                text="Acesso ao conteúdo completo do site da GLMMG pelo APP FRAT. Mais um benefício!"
                                icon={<Language fontSize="large" />}
                                bordered={false}
                                inLine={true}
                                height={230}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <InfoCard
                                title="Eventos GLMMG"
                                text="Acesso exclusivo aos eventos de Iniciação, Elevação, Exaltação e Instalação de todas as Lojas jurisdicionadas à GLMMG por um período de até 7 dias."
                                icon={<Event fontSize="large" />}
                                bordered={false}
                                inLine={true}
                                height={230}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <InfoCard
                                title="Cadastro de Empresas"
                                text="Opção onde são realizados os cadastros de empresas e negócios dos participantes do programa FRAT."
                                icon={<DomainAdd fontSize="large" />}
                                bordered={false}
                                inLine={true}
                                height={230}
                            />
                        </Grid>
                    </Grid>
                </Section>

                <Section>
                    <DefaultTitle id="screens">
                        FRAT Telas
                    </DefaultTitle>
                    <SectionDivider />

                    <ScreenCarousel items={screens} />
                </Section>

                {/*  <Section>
                    <MailCard
                        message={message}
                        name={name}
                        email={sendEmail}
                        handleFormChange={handleEmailFormChange}
                        onSendClick={onSubmit}
                    />
                </Section> */}

            </ContainerStyled>

            <ScrollTop>
                <Fab size={matches ? "medium" : "large"}>
                    <KeyboardArrowUp />
                </Fab>
            </ScrollTop>

            <Footer />
        </React.Fragment>
    )
}

export default LandingPageView;
