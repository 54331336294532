import { Typography } from '@mui/material';

import { GenericCardList } from '../../../../components';
import { ICompanyListCardView } from './CompanyListCard.interface';

function CompanyListCardView(props: ICompanyListCardView) {
    const { title, site, nameCatagory, address, goToCompanyPage } = props;

    return (
        <GenericCardList title={title} goToPage={goToCompanyPage}>
            <Typography variant="body2" fontWeight="bold">{nameCatagory}</Typography>
            <Typography variant="body2" style={{wordBreak: "break-word"}}>Site: {site}</Typography>
            <Typography variant="body2">Endereço: {address}</Typography>
        </GenericCardList>
    );
}

export default CompanyListCardView;