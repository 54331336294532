import RestAPIModule from './../../RestApi';
import { IResponse } from '../../../../model';
import { IBrotherLanguageAdd, IBrotherLanguageAlter, IBrotherLanguageDelete } from './BrotherLanguage.interface';
import { ITblIdiomaObreiro } from '../../../../model/BrotherDTO.interface';

export default class BrotherLanguagesRequest {
    public static URL_BROTHER_LANGUAGES = `${RestAPIModule.URL}/IdiomaObreiros`;
    public static URL_BROTHER_LANGUAGES_ADD = `${BrotherLanguagesRequest.URL_BROTHER_LANGUAGES}/Incluir`;
    public static URL_BROTHER_LANGUAGES_DELETE = `${BrotherLanguagesRequest.URL_BROTHER_LANGUAGES}/Excluir`;
    public static URL_BROTHER_LANGUAGES_UPDATE = `${BrotherLanguagesRequest.URL_BROTHER_LANGUAGES}/Alterar`;

    public static Add(params: IBrotherLanguageAdd): Promise<IResponse<ITblIdiomaObreiro>> {
        return RestAPIModule.httpPost(`${BrotherLanguagesRequest.URL_BROTHER_LANGUAGES_ADD}`, params).then((response) => response);
    }

    public static Update(params: IBrotherLanguageAlter): Promise<IResponse<ITblIdiomaObreiro>> {
        return RestAPIModule.httpPut(`${BrotherLanguagesRequest.URL_BROTHER_LANGUAGES_UPDATE}`, params).then((response) => response);
    }

    public static Delete(params: IBrotherLanguageDelete): Promise<IResponse<ITblIdiomaObreiro>> {
        return RestAPIModule.httpDelete(`${BrotherLanguagesRequest.URL_BROTHER_LANGUAGES_DELETE}?IdIdiomaObreiro=${params.idIdiomaObreiro}`).then((response) => response);
    }
}