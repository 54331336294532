import { Box, Grid, Typography } from '@mui/material';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';

import { CardContentStyled, CardStyled, GridContainer, SocialButtons, Text, Title } from './SocialCard.style';

function SocialCardView() {
    return (
        <>
            <GridContainer container spacing={2}>
                <Grid item xs={12} sm={12} md={6} display={{ sm: "block", md: "flex" }} justifyContent="right">
                    <a href='https://play.google.com/store/apps/details?id=br.com.glmmg.frat&hl=pt_PT&gl=US' target="_blank">
                        <SocialButtons variant="contained">
                            <Box display="flex" alignItems="center">
                                <AndroidIcon fontSize='medium' />

                                <Box ml={1}>
                                    <Typography fontSize="12px" textTransform="lowercase">faça o download na</Typography>
                                    <Typography fontSize="16px" textAlign="left">Google Play</Typography>
                                </Box>
                            </Box>
                        </SocialButtons>
                    </a>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                    <a href='https://apps.apple.com/br/app/frat/id1577022060' target="_blank">
                        <SocialButtons variant="contained">
                            <Box display="flex" alignItems="center">
                                <AppleIcon fontSize='medium' />

                                <Box ml={1}>
                                    <Typography fontSize="12px" textTransform="lowercase">faça o download na</Typography>
                                    <Typography fontSize="16px" textAlign="left">Apple Store</Typography>
                                </Box>
                            </Box>
                        </SocialButtons>
                    </a>
                </Grid>
            </GridContainer>
        </>
    )
}

export default SocialCardView;