import { IconButton, Typography } from "@mui/material";

import { IPropsView } from "./ProfileModal.interface";
import { FormCardHeader } from "../../../../../components";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { CardContentBox, DefautlTextField, FormGroup, ImageContainer, ModalBox, ModalCard, ModalContainer, ProfileImg } from "./ProfileModal.style";

function ProfileModalView(props: IPropsView) {

    const { openState, nameBrother, occupationBrother, phoneBrother, photoBrother,
        emailBrother, masonicBiography, personalBiography, loading, onClose, handleInputChange, handleSubmit } = props;

    return (
        <ModalContainer open={openState} onClose={onClose}>
            <ModalBox >
                <ModalCard >
                    <CardContentBox>
                        <form onChange={(e) => handleInputChange(e)} onSubmit={(e) => handleSubmit(e)}>
                            <FormGroup style={{ marginTop: 0 }}>

                                <FormCardHeader
                                    loading={loading}
                                    onCloseClick={onClose}
                                    subtitle={"Informações pessoais"}
                                    title={"Editar Perfil"}
                                />

                                <input accept="image/*" id="icon-button-file" type="file" style={{ display: 'none' }} name="photoBrother" />
                                <ImageContainer>
                                    <label htmlFor="icon-button-file">
                                        <IconButton color="primary" aria-label="upload picture" component="span" >
                                            <ProfileImg src={photoBrother} />
                                        </IconButton>
                                    </label>

                                    <label htmlFor="icon-button-file">
                                        <Typography display="flex" alignItems="center" paddingLeft={1} sx={{ cursor: 'pointer' }}>Clique para alterar a foto <AddPhotoAlternateIcon /></Typography>
                                    </label>
                                </ImageContainer>

                                <DefautlTextField
                                    variant="outlined"
                                    label="Nome"
                                    name="nameBrother"
                                    value={nameBrother}
                                />

                                <DefautlTextField
                                    variant="outlined"
                                    label="Profissão"
                                    name="occupationBrother"
                                    value={occupationBrother}
                                />
                                <DefautlTextField
                                    variant="outlined"
                                    label="Telefone"
                                    name="phoneBrother"
                                    value={phoneBrother}
                                />
                                <DefautlTextField
                                    variant="outlined"
                                    label="Email"
                                    name="emailBrother"
                                    value={emailBrother}
                                />
                                <DefautlTextField
                                    variant="outlined"
                                    label="Biografia Maçonica"
                                    name="masonicBiography"
                                    value={masonicBiography}
                                />
                                <DefautlTextField
                                    variant="outlined"
                                    label="Biografia Profissional"
                                    name="personalBiography"
                                    value={personalBiography}
                                />
                            </FormGroup>
                        </form>

                    </CardContentBox>
                </ModalCard>
            </ModalBox>
        </ModalContainer >
    );
}

export default ProfileModalView;