import { Card, CardHeader, styled } from "@mui/material";

export const GenericCardListStyled = styled(Card)(() => ({
    borderRadius: 6,
    width: '100%',
    height: '100%'
}));

export const GenericCardListHeaderStyled = styled(CardHeader)(() => ({
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
}));

