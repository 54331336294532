import { useContext, useEffect, useState } from "react";

import { IJobDTO } from "../../model";
import ListJobView from "./ListJob.view";
import { useDoRequest, useSnackbar, useArray } from "../../hooks";
import { IGetJobsPagedInput } from "../../modules/api/endpoints/Job/Job.interface";
import { IModulesContext } from "../../context/modules/Modules.interface";
import { ModulesContext } from "../../context/modules/Modules";

function ListJob() {
    const snackbar = useSnackbar();
    const jobList = useArray<IJobDTO>([]);
    const { Analytics } = useContext<IModulesContext>(ModulesContext);

    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [searchTermRequest, setSearchTermRequest] = useState<string>("");

    const GetJobsPaged = useDoRequest((api) => api.Job.GetJobsPaged);

    function componentDidMount() {
        Analytics.logScreen('Listagem de vagas');
        getJobsPaged(page, searchTermRequest);
    }
    useEffect(componentDidMount, []);

    useEffect(() => {
        if (searchTerm === "") getJobsPaged(page, "");
    }, [searchTerm]);

    function getJobsPaged(pageProp: number, searchTerm: string) {
        if (pageProp > pageCount && pageCount != 0) return;

        const dto: IGetJobsPagedInput = {
            searchTerm: searchTerm,
            page: pageProp,
            amount: 9
        }

        GetJobsPaged.doRequest(dto).then((res) => {
            if (res.statusExecucao && res.dadosRetorno) {
                const data = res.dadosRetorno;
                setPageCount(data.pageCount);
                setSearchTermRequest(searchTerm);
                setPage(data.page);
                jobList.setValue(pageProp == 1 ? data.listJobs : [...jobList.value, ...data.listJobs]);
            } else {
                snackbar(res.mensagem || "Não foi possível listar as vagas.").error();
            }
        }).catch((error) => {
            snackbar("Erro ao listar as vagas.").error();
        });
    }

    function submitFilter() {
        Analytics.logScreenAction('Listagem de vagas', 'Buscar por Vaga');
        getJobsPaged(1, searchTerm);
    }

    function nextPage() {
        getJobsPaged(page + 1, searchTerm);
    }

    return (
        <ListJobView
            jobList={jobList.value}
            loading={GetJobsPaged.loading}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            page={page}
            pageCount={pageCount}
            submitFilter={submitFilter}
            nextPage={nextPage}
        />
    );
}

export default ListJob;