import { useContext, useEffect, useState } from "react";

import { IProps } from "./ProfileModal.interface";
import ProfileModalView from "./ProfileModal.view";
import { useDoRequest, useSnackbar } from "../../../../../hooks";
import { ModulesContext } from "../../../../../context/modules/Modules";
import { IModulesContext } from "../../../../../context/modules/Modules.interface";

function ProfileModal(props: IProps) {
    const { Mask, Encryption, Analytics } = useContext<IModulesContext>(ModulesContext);

    const { infoBrother, openState, setOpenState, setInfoBrother } = props;

    const UpdateBrotherPhoto = useDoRequest((api) => api.Brother.UpdatePhoto);
    const UpdateBrotherInfos = useDoRequest((api) => api.Brother.UpdateBasicInformation);

    const snackbar = useSnackbar();

    const [nameBrother, setNameBrother] = useState<string>(infoBrother.nameBrother);
    const [photoBrother, setPhotoBrother] = useState<string>(infoBrother.imageBrother)
    const [occupationBrother, setOccupationBrother] = useState<string>(infoBrother.occupationBrother);
    const [phoneBrother, setPhoneBrother] = useState<string>(infoBrother.phone);
    const [emailBrother, setEmailBrother] = useState<string>(infoBrother.email);
    const [masonicBiography, setMasonicBiography] = useState<string>(infoBrother.masonicBiography);
    const [personalBiography, setPersonalBiography] = useState<string>(infoBrother.personalBiography);

    useEffect(() => {
        if (UpdateBrotherPhoto.loading && UpdateBrotherInfos.loading) {
            snackbar("Informações atualizadas com sucesso!").success();
            setOpenState(false);
        }
    }, [UpdateBrotherPhoto.loading, UpdateBrotherInfos.loading]);

    async function handleInputChange(event: any) {
        event.preventDefault();
        let input = event.target.value;
        switch (event.target.name) {
            case 'photoBrother':
                input = event.target.files;
                let fileInput = await Encryption.readFileAsDataURL(input[0]);
                if (fileInput) {
                    const formatedFile = fileInput.toString().split(',')[1];
                    setPhotoBrother(formatedFile);
                }
                break;
            case 'nameBrother':
                setNameBrother(input);
                break;
            case 'occupationBrother':
                setOccupationBrother(input);
                break;
            case 'phoneBrother':
                setPhoneBrother(Mask.applyMask(Mask.MASK.CELLPHONE, input));
                break;
            case 'emailBrother':
                setEmailBrother(input);
                break;
            case 'masonicBiography':
                setMasonicBiography(input);
                break;
            case 'personalBiography':
                setPersonalBiography(input);
                break;
            default:
                break;
        }
    }

    function updatePhoto() {
        Analytics.logScreenAction('Detalhes de um irmão', 'Atualizar foto');
        let dto = {
            idObreiro: infoBrother.idBrother,
            fotoObreiro: photoBrother
        }

        let newInfoBrother = infoBrother;

        UpdateBrotherPhoto.doRequest(dto).then((res) => {
            if (res.dadosRetorno && res.statusExecucao) {
                newInfoBrother.imageBrother = res.dadosRetorno.fotoObreiro || "";
                setInfoBrother({ ...newInfoBrother });
            }
        }).catch((error) => {
            snackbar("Não foi possível atualizar foto.").error();
        });
    }

    function updateBasicInformation() {
        Analytics.logScreenAction('Detalhes de um irmão', 'Atualizar informações básicas');
        let dto = {
            idObreiro: infoBrother.idBrother,
            email: emailBrother,
            nomeObreiro: nameBrother,
            telefoneCelular: phoneBrother,
            profissaoObreiro: occupationBrother,
            biografiaMaconica: masonicBiography,
            biografiaProfissional: personalBiography
        }

        let newInfoBrother = infoBrother;
        UpdateBrotherInfos.doRequest(dto).then((res) => {
            if (res?.dadosRetorno && res?.statusExecucao) {
                newInfoBrother.email = res.dadosRetorno.email;
                newInfoBrother.nameBrother = res.dadosRetorno.nomeObreiro;
                newInfoBrother.occupationBrother = res.dadosRetorno.profissaoObreiro || "";
                newInfoBrother.phone = res.dadosRetorno.telefoneCelular || "";
                newInfoBrother.personalBiography = res.dadosRetorno.biografiaProfissional || "";
                newInfoBrother.masonicBiography = res.dadosRetorno.biografiaMaconica || "";
                setInfoBrother({ ...newInfoBrother });
            }
        }).catch((error) => {
            snackbar("Não foi possível atualizar informações.").error();
        });
    }

    function handleSubmit(event: any) {
        event.preventDefault();
        updatePhoto();
        updateBasicInformation();
    }

    return (
        <ProfileModalView
            openState={openState}
            nameBrother={nameBrother}
            occupationBrother={occupationBrother}
            phoneBrother={phoneBrother}
            emailBrother={emailBrother}
            masonicBiography={masonicBiography}
            personalBiography={personalBiography}
            photoBrother={Mask.formatBase64(photoBrother)}
            loading={UpdateBrotherPhoto.loading || UpdateBrotherInfos.loading}
            onClose={() => setOpenState(false)}
            handleSubmit={handleSubmit}
            handleInputChange={handleInputChange}
        />
    );
}

export default ProfileModal;