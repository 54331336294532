import React from 'react';

import { PostCard, Loading } from '../../components';
import { IHomeView } from './Home.interface';
import InfinityScroll from '../../components/infinityScroll/InfinityScroll';

import { ContainerStyled, NoContentContainer } from './Home.style';

function HomeView(props: IHomeView) {
    const {
        feed,
        loading,
        page,
        pageCount,
        infinityLoading,
        redirectToDetailPost,
        getNextPagedRequest,
        redirectToDetailBrother
    } = props;

    function renderCards() {
        if (feed.length >= 1) {
            return (
                <React.Fragment>
                    {feed.map((post, idx) => (
                        <PostCard
                            key={idx}
                            post={post}
                            redirectToDetailPost={redirectToDetailPost}
                            redirectToDetailBrother={redirectToDetailBrother}
                        />
                    ))}
                </React.Fragment>
            );
        } else {
            return (
                <NoContentContainer>
                    Nenhum dado encontrado.
                </NoContentContainer>
            );
        }
    }

    return (
        <ContainerStyled maxWidth="md">
            <Loading open={loading} />

            {!loading &&
                <React.Fragment>
                    {renderCards()}

                    {feed && (page < pageCount) && !infinityLoading && (
                        <InfinityScroll callBack={getNextPagedRequest} />
                    )}
                </React.Fragment>
            }

        </ContainerStyled >
    );
}

export default HomeView;