import { LoadingButton } from '@mui/lab';
import { Grid, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { CardStyled, InfinityScroll, Loading } from '../../components';
import { IListBrotherProps } from './ListBrother.interface';
import CardBrother from './components/CardBrother';

import { TextNoData, Title } from './ListBrother.style';

function ListBrotherView(props: IListBrotherProps) {
    const {
        brotherList, loading,
        searchTerm, page,
        pageCount,
        initialLoading,
        setSearchTerm, submitFilter,
        nextPage
    } = props;

    function renderCards() {
        if (brotherList.length >= 1) {
            return brotherList.map((brother, idx) => (
                <Grid item xs={12} sm={12} md={6} lg={6} key={idx}>
                    <CardBrother item={brother} />
                </Grid>
            ));
        } else {
            return (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextNoData>
                        Nenhum irmão encontrada.
                    </TextNoData>
                </Grid>
            );
        }
    }

    return (
        <CardStyled>
            <Loading open={initialLoading} />
            <Title variant="h3"> Obreiros </Title>

            <Grid container spacing={2} mt={1} mb={2} display="flex" alignItems="center">
                <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                    <TextField
                        value={searchTerm}
                        fullWidth
                        size="small"
                        label="Buscar obreiros"
                        id="search-jobs"
                        type="search"
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                    <LoadingButton
                        loading={loading}
                        disabled={loading}
                        variant="contained"
                        fullWidth
                        onClick={submitFilter}

                    >
                        <SearchIcon />
                    </LoadingButton>
                </Grid>
            </Grid>

            {!initialLoading &&
                <Grid container spacing={1}>
                    {renderCards()}
                </Grid>
            }

            {brotherList && (page < pageCount) && !loading && (
                <InfinityScroll callBack={nextPage} />
            )}
        </CardStyled>
    );
}

export default ListBrotherView;