import { styled, Box } from '@mui/material';
import Carousel from 'react-material-ui-carousel';

export const ImageBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
}));

export const Image = styled('img')(({ theme }) => ({
    borderRadius: 12,
    height: 620,
    width: "auto",
    [theme.breakpoints.down(400)]: {
        width: "100%"
    }
}));

export const CarouselStyled = styled(Carousel)(() => ({
    cursor: "grabbing",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
}));