import { Card, styled } from "@mui/material";

export const NoContentContainer = styled('div')(() => ({
    height: "80vh",
    display: "flex",
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: 'center',
}));

export const CardStyled = styled(Card)(() => ({
    borderRadius: 6, 
    marginBottom: 24
}));