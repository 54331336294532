import { IScreenCarousel } from './ScreenCarousel.interface';
import ScreenCarouselView from './ScreenCarousel.view';

function ScreenCarousel(props: IScreenCarousel) {
    const { items, onChange } = props;

    return (
        <ScreenCarouselView 
            items={items}
            onChange={onChange}
        />
    )
}

export default ScreenCarousel;