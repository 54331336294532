import { useNavigate, useLocation } from 'react-router-dom';

import { IUseHistoryActions } from './useHistory.interface';
import useLocalStorage from '../useLocalStorage/useLocalStorage';

function useHistory(): IUseHistoryActions {
    const navigate = useNavigate();
    const location = useLocation();
    const lastUrl = useLocalStorage<string>('lastUrl');

    const redirectTo = (redirectRoute: string) => {
        lastUrl.setValue(location.pathname);
        navigate(redirectRoute);
    };

    const getLastUrl = () => {
        return lastUrl;
    };

    const getRouteBack = () => {
        navigate(lastUrl.value || '');
    };

    return {
        redirectTo,
        getRouteBack,
        getLastUrl
    };
}

export default useHistory;