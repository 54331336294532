import RestAPIModule from './../../RestApi';
import { IResponse } from '../../../../model';
import { ILanguageDTO } from '../../../../model/LanguageDTO.interface';

export default class LanguagesRequest {
    public static URL_LANGUAGES = `${RestAPIModule.URL}/Idiomas`;

    public static Get(): Promise<IResponse<ILanguageDTO[]>> {
        return RestAPIModule.httpGet(`${LanguagesRequest.URL_LANGUAGES}`).then((response) => response);
    }
}