import { IProps } from "./PageNotFound.interface";

import { GoBackButton, NoBrothersText, PageContainer, PageNotFoundText, Title404 } from "./PageNotFound.style";

function PageNotFoundView(props: IProps) {
    const { goBackHome } = props;

    return (
        <PageContainer>
            <Title404>404</Title404>
            <PageNotFoundText variant="h2">Página não encontrada</PageNotFoundText>
            <NoBrothersText variant="h4">Não há nada por aqui...</NoBrothersText>
            <GoBackButton variant="contained" onClick={goBackHome}>Voltar para a home</GoBackButton>
        </PageContainer>
    );
}

export default PageNotFoundView