import {FormEvent, useContext, useEffect, useState} from 'react';

import LoginView from './Login.view';
import { useDoRequest, useAuth, useSnackbar, useHistory } from '../../hooks';
import { IBrotherLoginInput } from "../../modules/api/endpoints/brother/Brother.interface";
import { IModulesContext } from '../../context/modules/Modules.interface';
import { ModulesContext } from '../../context/modules/Modules';
import { useParams } from 'react-router-dom';
import {IDeepLinkContext} from "../../context/deepLink/DeepLink.interface";
import {DeepLinkContext} from "../../context/deepLink/DeepLink";

function Login() {
    const { signIn } = useAuth();
    const snackbar = useSnackbar();
    const navigation = useHistory();
    const { screen, idScreen } = useParams();

    const Login = useDoRequest((api) => api.Brother.Login);

    const { Analytics } = useContext<IModulesContext>(ModulesContext);
    const { setRedirectScreen } = useContext<IDeepLinkContext>(DeepLinkContext);

    const [valuesForm, setValuesForm] = useState<IBrotherLoginInput>({
        placet: '',
        password: ''
    });

    useEffect(componentDidMount, []);

    function componentDidMount() {
        if (screen && idScreen) {
            setRedirectScreen(`AuthScreen/${screen}/${idScreen}`);
        } else {
            setRedirectScreen(`AuthScreen/`);
        }

        return () => {
            setRedirectScreen(null);
        }
    }

    function handleSubmit() {
        Login.doRequest(valuesForm).then((res) => {
            if (res.statusExecucao && res.dadosRetorno) {
                snackbar("Login feito com sucesso!").success();
                signIn(res.dadosRetorno.token, res.dadosRetorno.obreiro);
                if (screen) {
                    navigation.redirectTo(`/r=${screen}&i=${idScreen}`);
                } else {
                    navigation.redirectTo('/home'); 
                }
                Analytics.logLogin();
            } else {
                snackbar(res.mensagem || "Não foi possível fazer login.").error();
            }
        }).catch((error) => {
            snackbar("Não foi possível fazer login.").error();
        });
    }

    function validationForm(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (valuesForm.placet === '' || valuesForm.placet.length === 0) {
            return;
        }
        if (valuesForm.password === '' || valuesForm.password.length === 0) {
            return;
        }
        handleSubmit();
    }

    function onChangeFormValues(typeData: string, value: string) {
        switch (typeData) {
            case ('placet'):
                setValuesForm({ ...valuesForm, placet: value })
                break;
            case ('password'):
                setValuesForm({ ...valuesForm, password: value })
                break;
            default:
                break;
        }
    }

    return (
        <LoginView
            validationForm={validationForm}
            onChangeFormValues={onChangeFormValues}
            loading={Login.loading}
        />
    );
}

export default Login;