import { formatDistanceToNowStrict, isPast } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ptBR from 'date-fns/locale/pt-BR';

import { useDoRequest, useHistory, useSnackbar } from "../../hooks";
import { IJobDTO } from "../../model";
import { IJobApplyToJob, IJobBrotherIsCandidate, IJobGetById } from "../../modules/api/endpoints/Job/Job.interface";
import { IModulesContext } from "../../context/modules/Modules.interface";
import { ModulesContext } from "../../context/modules/Modules";
import DetailJobView from "./DetailJob.view";
import { useGlobalState } from "../../context/global/Global";
import {IDeepLinkContext} from "../../context/deepLink/DeepLink.interface";
import {DeepLinkContext} from "../../context/deepLink/DeepLink";

function DetailJob() {
    const { idJob } = useParams();
    const { user } = useGlobalState();
    const snackbar = useSnackbar();
    const history = useHistory();

    const { Analytics, Mask } = useContext<IModulesContext>(ModulesContext);
    const { setRedirectScreen } = useContext<IDeepLinkContext>(DeepLinkContext);

    const [job, setJob] = useState<IJobDTO>();
    const [closed, setClosed] = useState<boolean>(true);
    const [alreadyApplied, setAlreadyApplied] = useState<boolean>(false);
    const [jobFormatedDate, setJobFormatedDate] = useState<string>('');
    const [jobFormatedFinishedDate, setJobFormatedFinishedDate] = useState<string>('');

    const GetById = useDoRequest((api) => api.Job.GetById);
    const ApplyToJob = useDoRequest((api) => api.Job.ApplyToJob);
    const BrotherIsCandidate = useDoRequest((api) => api.Job.BrotherIsCandidate);

    useEffect(componentDidMount, []);

    function componentDidMount() {
        Analytics.logScreen('Detalhes de uma vaga');
        setRedirectScreen(`Login/DetailJob/${idJob}`);

        getJob();
        getBrotherIsCandidate();

        return () => {
            setRedirectScreen(null);
        }
    }

    function getBrotherIsCandidate() {
        if(!user) return;
        
        if(idJob){
            const brotherCandidateObj: IJobBrotherIsCandidate = {
                idBrother: user.idObreiro,
                idJob: Number(idJob)
            }

            BrotherIsCandidate.doRequest(brotherCandidateObj).then((res) => {
                if (res.statusExecucao && res.dadosRetorno !== null && res.dadosRetorno !== undefined) {
                    setAlreadyApplied(res.dadosRetorno);
                } else {
                    snackbar(res.mensagem || "Não foi possível verificar se o usuário já se candidatou à vaga.").error();
                    history.redirectTo("/home");
                }
            }).catch((error) => {
                snackbar("Não foi possível verificar se o usuário já se candidatou à vaga.").error();
                history.redirectTo("/home");
            })
        } else {
            history.redirectTo("/404");
        }
    }

    function getJob() {
        if(idJob){
            const jobGetByIdObj: IJobGetById= {
                idJob: Number(idJob)
            }

            GetById.doRequest(jobGetByIdObj).then((res) => {
                if (res.statusExecucao && res.dadosRetorno) {
                    setJob(res.dadosRetorno);
                    setClosed(isPast(new Date(res.dadosRetorno?.dataFechamento)));
                    setJobFormatedDate(formatDistanceToNowStrict(new Date(res.dadosRetorno?.dataAbertura), {locale: ptBR}));
                    setJobFormatedFinishedDate(formatDistanceToNowStrict(new Date(res.dadosRetorno?.dataFechamento), {locale: ptBR}))
                } else {
                    snackbar(res.mensagem || "Não foi possível buscar os dados da vaga.").error();
                    history.redirectTo("/home");
                }
            }).catch((error) => {
                snackbar("Não foi possível buscar a vaga.").error();
                history.redirectTo("/home");
            });
        } else {
            history.redirectTo("/404");
        }
    }

    function applyToJob() {
        if(!user) return;

        Analytics.logScreenAction('Detalhes de uma vaga', 'Candidatar a uma vaga');

        const applyObj: IJobApplyToJob = {
            idVaga: Number(idJob),
            idObreiro: user.idObreiro
        }

        ApplyToJob.doRequest(applyObj).then((res) => {
            if (res.statusExecucao && res.dadosRetorno) {
                snackbar("Candidatura realizada!").success();
                getBrotherIsCandidate();
            } else {
                snackbar(res.mensagem || "Não foi possível se candidatar à vaga.").error();
                history.redirectTo("/home");
            }
        }).catch((error) => {
            snackbar("Não foi possível buscar se candidatar.").error();
            history.redirectTo("/home");
        })
    }

    return (
        <DetailJobView
            job={job}
            loading={GetById.loading}
            applyLoading={ApplyToJob.loading}
            alreadyApplied={alreadyApplied}
            closed={closed}
            jobCreationDate={jobFormatedDate}
            jobFinishedDate={jobFormatedFinishedDate}
            brotherTelephone={Mask.applyMask(Mask.MASK.CELLPHONE, job?.idObreiroNavigation.telefoneCelular)}
            remuneration={Mask.applyMask(Mask.MASK.MONEY, job?.remuneracao.toString())}
            applyToJob={applyToJob}
        />
    )
}

export default DetailJob;