import { Avatar, Box, Button, Container, styled, Tab } from '@mui/material';
import { TabContext } from '@mui/lab';

export const TabContextStyled = styled(TabContext)(() => ({
    '.css-13xfq8m-MuiTabPanel-root': {
        padding: '0px !important'
    }
}));

export const PageContainer = styled(Container)(() => ({
    alignItems: 'center',
    margin: 'auto',
    overflowX: "hidden"
}));

export const NoDataContainer = styled('div')(() => ({
    height: "80vh",
    display: "flex",
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: 'center',
}));

export const NameBrother = styled('h1')(({ theme }) => ({
    fontSize: 18,
    [theme.breakpoints.down("sm")]: {
        fontSize: 16
    }
}));

export const SecondayText = styled('span')(({ theme }) => ({
    textAlign: 'center',
    color: theme.palette.text.secondary,
    fontSize: 16,
    opacity: 0.8,
    [theme.breakpoints.down("sm")]: {
        fontSize: 14
    }
}));

export const IconTextBox = styled('div')(() => ({
    alignItems: 'center',
    display: 'flex',
    marginTop: 4
}));

export const ProfileCard = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down("xs")]: {
        flexDirection: 'column',
        alignContent: 'center'
    }
}));

export const ProfileInfo = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center'
}));

export const EditButton = styled(Button)(({ theme }) => ({
    height: 0,
    padding: 18,
    borderRadius: 16,
    color: theme.palette.text.secondary,
    borderColor: theme.palette.text.secondary,
    ':hover': {
        borderColor: theme.palette.text.secondary,
    }
}));

export const ProfileImg = styled(Avatar)(({ theme }) => ({
    height: '8rem',
    top: '23.9%',
    width: '8rem',
    marginLeft: 20,
    [theme.breakpoints.down("sm")]: {
        height: '6rem',
        top: '23.9%',
        width: '6rem',
    }
}));

export const TabBox = styled(Box)(() => ({
    borderBottom: 1,
    width: '100%',
    marginTop: 6,
    borderColor: 'divider'
}));

export const TabItem = styled(Tab)(() => ({
    width: '25%',
    textTransform: 'none'
}));