import { Card, styled } from '@mui/material';

export const PageContainer = styled('div')(() => ({
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
}));

export const DefaultBox = styled(Card)(({ theme }) => ({
    width: '100%',
    backgroundColor: theme.palette.mode == 'dark' ? "#1E1E1E" : '#F2F2F2',
    padding: 12,
    paddingTop: 6
}));