import { Avatar, Box, Button, Card, CardContent, CircularProgress, Modal, styled, TextField, Typography } from '@mui/material';

export const ModalContainer = styled(Modal)(() => ({
    alignItems: "center",
    display: "flex",
    justifyContent: "center"
}));

export const ModalBox = styled(Box)(({ theme }) => ({
    height: theme.breakpoints.values.sm,
    width: theme.breakpoints.values.sm,
    borderRadius: 12
}));

export const ModalCard = styled(Card)(() => ({
    height: '100%',
    width: '100%',
    overflowY: "scroll",
    padding: 12,
    borderRadius: 12
}));

export const CardContentBox = styled(CardContent)(( ) => ({
    paddingTop: 0
}));

export const ProfileImg = styled(Avatar)(({ theme }) => ({
    width: 100,
    height: 100,
    margin: 0,
    padding: 0,
    [theme.breakpoints.down("sm")]: {
        width: 80,
        height: 80
    }
}));

export const FormGroup = styled('div')(() => ({
    marginTop: 12
}));

export const ImageContainer = styled(Box)(() => ({
    display: "flex",
    alignItems: 'center', 
}));

export const ButtonSubmit = styled(Button)(() => ({
    marginTop: 12
}));

export const HelperText = styled(Typography)(() => ({
    display: "block"
}));

export const TitleText = styled(Typography)(() => ({
    fontSize: 24,
    lineHeight: 1
}));

export const DefautlTextField = styled(TextField)(() => ({
    width: '100%',
    marginTop: 16
}));

export const Loading = styled(CircularProgress)(() => ({
    color: 'white'
}));