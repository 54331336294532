import { createContext, useContext, useMemo } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { responsiveFontSizes } from '@mui/material';
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import { createTheme, ThemeProvider as MaterialThemeProvider } from '@mui/material/styles';

import shadows from './Shadows';
import typography from './Typography';
import { light, dark } from './Palettes';
import StylesGlobal from './StylesGlobal';
import { useLocalStorage } from '../../hooks';
import { IProps, IThemeContext } from './Theme.interface';
import React from 'react';

const ThemeContext = createContext({} as IThemeContext);

function ThemeProvider(props: IProps) {
    const darkMode = useLocalStorage<boolean>('darkMode', false);

    const theme = useMemo(() => responsiveFontSizes(
        createTheme({
            palette: darkMode.value ? dark : light,
            shadows,
            typography,
        })), [darkMode.value]);

    function toggleDarkMode() {
        darkMode.setValue(!darkMode.value);
    }

    return (
        <MaterialThemeProvider theme={theme}>
            <EmotionThemeProvider theme={theme}>
                <ThemeContext.Provider value={{darkMode: darkMode.value, toggleDarkMode}}>
                    <CssBaseline/>
                    <StylesGlobal />
                    {props.children}
                </ThemeContext.Provider>
            </EmotionThemeProvider>
        </MaterialThemeProvider>
    );
}

function useThemeState() {
    return useContext(ThemeContext);
}

export { ThemeProvider, ThemeContext, useThemeState };