import { useContext, useEffect, useState } from "react";
import { SelectChangeEvent } from "@mui/material";

import { IProps } from "./AcademicModal.interface";
import AcademicModalView from "./AcademicModal.view";
import { IInstructionLevelDTO } from "../../../../../model";
import { useGlobalState } from "../../../../../context/global/Global";
import { ModulesContext } from "../../../../../context/modules/Modules";
import { useArray, useDoRequest, useSnackbar } from "../../../../../hooks";
import { IModulesContext } from "../../../../../context/modules/Modules.interface";
import { IAddAcademicHistoryInput, IEditAcademicHistoryInput } from "../../../../../modules/api/endpoints/academicHistory/Academic.interface";

function AcademicModal(props: IProps) {
    const { openState, type, selectedAcademicHistory, allAcademicHistory, setOpenState, setAllHistory } = props;
    const { Analytics } = useContext<IModulesContext>(ModulesContext);

    const snackbar = useSnackbar();
    const { user } = useGlobalState();

    const [educationLevel, setEducationLevel] = useState<number | string>("");
    const [classBrother, setClassBrother] = useState<string>("");
    const [concluded, setConcluded] = useState<number>(0);
    const [nameSchool, setNameSchool] = useState<string>("");
    const [initialDate, setInitialDate] = useState<string | Date | null>(null);
    const [endDate, setEndDate] = useState<string | Date | null>(null);
    const instructionLevelList = useArray<IInstructionLevelDTO>([]);

    const AddAcademicHistory = useDoRequest((api) => api.AcademicHistory.AddAcademicHistory);
    const EditAcademicHistory = useDoRequest((api) => api.AcademicHistory.EditAcademicHistory);
    const GetInstructionLevel = useDoRequest((api) => api.InstructionLevel.GetInstructionLevel)

    function componentDidMount() {
        getInstructionLevel();
    }
    useEffect(componentDidMount, [])

    useEffect(() => {
        setEducationLevel(selectedAcademicHistory?.idNivelInstrucao || "");
        setClassBrother(selectedAcademicHistory?.nomeCurso || "");
        setNameSchool(selectedAcademicHistory?.nomeInstituicao || "");
        setConcluded(selectedAcademicHistory?.bitConcluido == false ? 0 : 1);
        setInitialDate(selectedAcademicHistory?.dataInicio || null);
        setEndDate(selectedAcademicHistory?.dataFim || null);
    }, [selectedAcademicHistory])

    function handleSubmit(event: any) {
        event.preventDefault();
        if (!user) return;

        if ((!initialDate || initialDate === "Invalid Date") || (concluded && (!endDate || endDate === "Invalid Date"))) {
            snackbar("As datas selecionadas são inválidas.").error();
            return;
        }

        if (type === "edit") {
            if (!selectedAcademicHistory || typeof educationLevel == 'string') return;

            const dto: IEditAcademicHistoryInput = {
                idHistoricoAcademico: selectedAcademicHistory.idHistoricoAcademico,
                nomeCurso: classBrother,
                nomeInstituicao: nameSchool,
                dataInicio: initialDate,
                dataFim: endDate,
                idNivelInstrucao: educationLevel,
                bitConcluido: (concluded !== 0)
            }

            editAcademicHistory(dto);
        } else if (type === "create") {
            if (typeof educationLevel == 'string') return;
            const dto: IAddAcademicHistoryInput = {
                idObreiro: user.idObreiro,
                nomeCurso: classBrother,
                nomeInstituicao: nameSchool,
                dataInicio: initialDate,
                dataFim: endDate,
                idNivelInstrucao: educationLevel,
                bitConcluido: (concluded !== 0)
            }

            addAcademicHistory(dto);
        }
    }

    function editAcademicHistory(dto: any) {
        Analytics.logScreenAction('Detalhes de um irmão', 'Editar histórico acadêmico');
        EditAcademicHistory.doRequest(dto).then((res) => {
            if (res.dadosRetorno && res.statusExecucao) {
                const alteredHistory = res.dadosRetorno;
                let allHistoryInstance = allAcademicHistory;

                allHistoryInstance?.forEach((history) => {
                    if (history.idHistoricoAcademico === alteredHistory.idHistoricoAcademico) {
                        history.nomeInstituicao = alteredHistory.nomeInstituicao;
                        history.nomeCurso = alteredHistory.nomeCurso;
                        history.bitConcluido = alteredHistory.bitConcluido;
                        history.idNivelInstrucao = alteredHistory.idNivelInstrucao;
                        history.idNivelInstrucaoNavigation.descricaoInstrucao = alteredHistory.idNivelInstrucaoNavigation.descricaoInstrucao;
                        history.dataInicio = alteredHistory.dataInicio;
                        history.dataFim = alteredHistory.dataFim;
                    }
                })

                snackbar("Histórico Acadêmico atualizado com sucesso.").success();
                setAllHistory(allHistoryInstance);
                onCloseAction();
            }
        }).catch((error) => {
            snackbar("Não foi possível atualizar histórico acadêmico.").error();
        });
    }

    function addAcademicHistory(dto: IAddAcademicHistoryInput) {
        Analytics.logScreenAction('Detalhes de um irmão', 'Adicionar histórico acadêmico');
        AddAcademicHistory.doRequest(dto).then((res) => {
            if (res.dadosRetorno && res.statusExecucao) {
                const newHistory = res.dadosRetorno;
                let allHistoryInstance = allAcademicHistory;

                allHistoryInstance?.push(newHistory);

                snackbar("Histórico Acadêmico adicionado com sucesso.").success();
                setAllHistory(allHistoryInstance);
                onCloseAction();
            }
        }).catch((error) => {
            snackbar("Não foi possível adicionar histórico acadêmico.").error();
        });
    }

    function getInstructionLevel() {
        GetInstructionLevel.doRequest("").then((res) => {
            if (res.dadosRetorno && res.statusExecucao) {
                instructionLevelList.setValue(res.dadosRetorno)
            } else {
                snackbar("Não foi possível buscar dados de nível de instrução.").error();
            }
        }).catch((error) => {
            snackbar("Não foi possível buscar dados de nível de instrução.").error();
        });

    }

    function handleInputChange(event: any) {
        event.preventDefault();
        let input = event.target.value;
        switch (event.target.name) {
            case 'nameSchool':
                setNameSchool(input);
                break;
            case 'classBrother':
                setClassBrother(input);
                break;
            default:
                break;
        }
    }

    function onCloseAction() {
        clearFields();
        setOpenState(false);
    }

    function clearFields() {
        setEducationLevel(0);
        setClassBrother("");
        setNameSchool("");
        setConcluded(0);
        setInitialDate(null);
        setEndDate(null);
    }

    function handleSelectChange(event: SelectChangeEvent<number>) {
        event.preventDefault();
        let input = Number(event.target.value);
        switch (event.target.name) {
            case 'selectEducationLevel':
                setEducationLevel(input)
                break;
            case 'selectConcluded':
                setConcluded(input);
                break;
            default:
                break;
        }
    }

    return (
        <AcademicModalView
            openState={openState}
            educationLevel={educationLevel}
            initialDate={initialDate}
            endDate={endDate}
            nameSchool={nameSchool}
            concluded={concluded}
            classBrother={classBrother}
            type={type}
            loading={EditAcademicHistory.loading || AddAcademicHistory.loading}
            instructionLevelList={instructionLevelList.value}
            onClose={() => setOpenState(false)}
            setEndDate={setEndDate}
            setInitialDate={setInitialDate}
            handleSubmit={(handleSubmit)}
            handleInputChange={handleInputChange}
            handleSelectChange={handleSelectChange}
        />
    )
}

export default AcademicModal;