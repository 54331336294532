import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Marker, Popup, TileLayer } from 'react-leaflet';

import {
    ContactTexts,
    ProfileBox,
    SectionDivider,
    SectionHeader,
    Site,
    MapContainerCompany,
    Labels
} from './DetailCompany.style';

import { BrotherRedirectCard, CardHeader, Loading, CardStyled } from "../../components";

import { IDetailCompanyView } from './DetailCompany.interface';

function DetailCompanyView(props: IDetailCompanyView) {
    const {
        companyObject,
        loading,
        companyInsertDate,
        getAddress,
    } = props;

    function renderMap() {
        if (!companyObject?.geoLatitude || !companyObject?.geoLongitude) return null;

        return (
            <MapContainerCompany
                center={[companyObject?.geoLatitude, companyObject?.geoLongitude]}
                zoom={12}
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[companyObject?.geoLatitude, companyObject?.geoLongitude]}>
                    <Popup>
                        {getAddress()}
                    </Popup>
                </Marker>
            </MapContainerCompany>
        );
    }

    function renderContent() {
        if (loading) return;
        return (
            <React.Fragment>
                <CardStyled>
                    <CardHeader
                        title={companyObject?.nomeEstabelecimento || ""}
                        subTitle={'Informações'} />

                    <Grid container spacing={1}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography fontWeight="bold" marginBottom={1}>
                                {companyObject?.idCategoriaNavigation?.nomeCategoria || ""}
                            </Typography>

                            <Typography color="text.secondary" variant="body2">
                                Descrição: <br /> {companyObject?.descricaoTextoPromocional || ""}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardStyled>

                {renderMap()}

                <CardStyled>
                    <Grid container columnSpacing={6}>
                        <Grid item lg={6} xs={12} rowSpacing={2} paddingTop={2}>
                            <SectionHeader>Contato</SectionHeader>

                            <SectionDivider />

                            <Grid item container rowSpacing={2} spacing={2} marginTop={1}>
                                <Grid item lg={12} xs={12}>
                                    <ProfileBox>
                                        <Labels>TELEFONE: </Labels>
                                        <ContactTexts>{companyObject?.telefone || ''}</ContactTexts>
                                    </ProfileBox>
                                </Grid>

                                {companyObject?.enderecoRua &&
                                    <Grid item lg={12} xs={12}>
                                        <ProfileBox>
                                            <Labels>ENDEREÇO: </Labels>
                                            <ContactTexts> {getAddress()}</ContactTexts>
                                        </ProfileBox>
                                    </Grid>
                                }

                                <Grid item lg={12} xs={12}>
                                    <ProfileBox>
                                        <Labels>EMAIL: </Labels>
                                        <ContactTexts>{companyObject?.email || ''}</ContactTexts>
                                    </ProfileBox>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <ProfileBox>
                                        <Labels>SITE: </Labels>
                                        <Site href={companyObject?.site} underline="hover" rel="noopener"
                                            target="_blank">{companyObject?.site || ''}</Site>
                                    </ProfileBox>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <ProfileBox>
                                        <Labels>DATA DE CADASTRO: </Labels>
                                        <ContactTexts> {companyInsertDate || ''}</ContactTexts>
                                    </ProfileBox>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <ProfileBox>
                                        <Labels>CNPJ: </Labels>
                                        <ContactTexts> {companyObject?.cnpjEstabelecimento || ''}</ContactTexts>
                                    </ProfileBox>
                                </Grid>
                            </Grid>
                        </Grid>

                        {companyObject?.idObreiroCadastroNavigation && companyObject?.idObreiroCadastroNavigation?.bitAtivo && (
                            <Grid item lg={6} xs={12} paddingTop={2}>
                                <SectionHeader>Irmão criador</SectionHeader>

                                <SectionDivider />

                                <BrotherRedirectCard
                                    type="centralized"
                                    hasContactInformation={false}
                                    brother={companyObject?.idObreiroCadastroNavigation}
                                    avatarSize="small"
                                    screenName={'Detalhes de um estabelecimento'}
                                />

                                <Grid item container rowSpacing={2} marginTop={1}>
                                    <Grid item lg={12} xs={12}>
                                        <ProfileBox>
                                            <Labels>EMAIL: </Labels>
                                            <ContactTexts>{companyObject?.idObreiroCadastroNavigation?.email}</ContactTexts>
                                        </ProfileBox>
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <ProfileBox>
                                            <Labels>PROFISSÃO: </Labels>
                                            <ContactTexts>{companyObject?.idObreiroCadastroNavigation?.profissaoObreiro}</ContactTexts>
                                        </ProfileBox>
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <ProfileBox>
                                            <Labels>TELEFONE: </Labels>
                                            <ContactTexts>{companyObject?.idObreiroCadastroNavigation?.telefoneCelular}</ContactTexts>
                                        </ProfileBox>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </CardStyled>
            </React.Fragment>
        )
    }

    return (
        <Box>
            <Loading open={loading} />
            {!loading && renderContent()}
        </Box>
    )
}

export default DetailCompanyView;