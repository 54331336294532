import { useState } from 'react';

import { InfoCardProps } from './InfoCard.interface';
import InfoCardView from './InfoCard.view';

function InfoCard(props: InfoCardProps) {
    const { icon, text, title, inLine, height, bordered } = props;

    const [cardShadow, setCardShadow] = useState<number>(3);

    return (
        <InfoCardView
            icon={icon}
            text={text}
            title={title}
            height={height}
            inLine={inLine}
            bordered={bordered}
            cardShadow={cardShadow}
            setCardShadow={setCardShadow}
        />
    )
}

export default InfoCard;