import { Box, CardContent, styled, Typography } from '@mui/material';

export const ContentContainer = styled(CardContent)(() => ({
    alignItems: 'center',
    display: "flex",
    flexDirection: "column",
    padding: 0
}))

export const DefaultText = styled(Typography)(() => ({
    marginTop: 12
}))