import React, { useContext } from 'react';
import { format } from 'date-fns';
import {
    Avatar, Box, Divider,
    List, ListItem,
    ListItemAvatar, ListItemText,
    Typography, Button
} from '@mui/material';

import { NoContentContainer } from './DetailEvent.style';

import { CardStyled, CardHeader, Loading } from "../../components";

import { IDetailEventView } from './DetailEvent.interface';
import { IEventConfirmationDTO } from '../../model';

import { IModulesContext } from "../../context/modules/Modules.interface";
import { ModulesContext } from "../../context/modules/Modules";

function DetailEventView(props: IDetailEventView) {
    const { event, openLink, loading } = props;

    const modules = useContext<IModulesContext>(ModulesContext);

    function RenderData() {
        return (
            <React.Fragment>
                {event != null ? (
                    <React.Fragment>
                        <CardStyled>
                            <CardHeader
                                title={event.idGrauNavigation?.tituloGrau || ""}
                                subTitle={'Informações'}
                                options={[
                                    <Typography variant="subtitle1">
                                        Data: {format(new Date(event.dataHoraEvento), 'dd/MM/yyyy HH:mm')}
                                    </Typography>
                                ]}
                            />
                            <Typography color="text.secondary" variant="body2">
                                Descrição: {event.descricaoPauta || ""}
                            </Typography>
                            {event.linkReuniao &&
                                <Box sx={{ mt: 1 }}>
                                    <Button variant="contained" onClick={() => openLink(event.linkReuniao || "")}>
                                        Acessar reunião
                                    </Button>
                                </Box>
                            }
                        </CardStyled>

                        <CardStyled>
                            <CardHeader
                                title={'Obreiros'}
                                subTitle={'Confirmações'}
                            />

                            {event?.tblConfirmacaoEventoCalendarioObreiros && event?.tblConfirmacaoEventoCalendarioObreiros?.length > 0 ? (
                                <List>
                                    {event?.tblConfirmacaoEventoCalendarioObreiros?.map((confirmacao, idx) => renderItemList(confirmacao, idx))}
                                </List>
                            ) : (
                                <Typography color="text.secondary" variant="body2">
                                    Nenhum irmão confirmado.
                                </Typography>
                            )}
                        </CardStyled>
                    </React.Fragment>
                ) : (
                    <NoContentContainer>
                        Nenhum dado encontrado.
                    </NoContentContainer>
                )}
            </React.Fragment>
        );
    }

    function renderItemList(item: IEventConfirmationDTO, idx: number) {
        return (
            <React.Fragment key={idx}>
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar
                            alt={item?.idObreiroNavigation?.nomeObreiro || ""}
                            src={modules.Mask.formatBase64(item?.idObreiroNavigation?.fotoObreiro)}
                        />
                    </ListItemAvatar>
                    <ListItemText
                        primary={item?.idObreiroNavigation?.nomeObreiro || ""}
                        secondary={
                            <React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    Data da confirmação:
                                </Typography>
                                {` ${format(new Date(item.dataHoraConfirmouPresenca), 'dd/MM/yyyy HH:mm')}`}
                            </React.Fragment>
                        }
                    />
                </ListItem>
                <Divider variant="middle" />
            </React.Fragment>
        );
    }

    return (
        <Box>
            <Loading open={loading} />

            {!loading &&
                <RenderData />
            }
        </Box>
    );
}

export default DetailEventView;