import { useContext, useEffect, useState } from "react";

import ListBookView from "./ListBook.view";
import { IBookDTO } from "../../model/BookDTO.interface";
import { useArray, useDoRequest, useHistory, useSnackbar } from "../../hooks";
import { IModulesContext } from "../../context/modules/Modules.interface";
import { ModulesContext } from "../../context/modules/Modules";

function ListBook() {
    const GetBooksPaged = useDoRequest((api) => api.Book.SearchBooks);
    const snackbar = useSnackbar();
    const history = useHistory();
    const { Analytics } = useContext<IModulesContext>(ModulesContext);

    const bookList = useArray<IBookDTO>([])
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);
    const [amountItemsPerPage, setAmountItemsPerPage] = useState<number>(8);

    const [loading, setLoading] = useState<boolean>(false);

    const [bookSearchTerm, setBookSearchTerm] = useState<string>("");

    function componentDidMount() {
        Analytics.logScreen('Listagem de livros');
        getBooksPaged();
    }
    useEffect(componentDidMount, []);

    useEffect(() => {
        if (bookSearchTerm === "") getBooksPaged();
    }, [bookSearchTerm]);

    function getBooksPaged() {

        const bookObj = {
            searchTerm: bookSearchTerm,
            page: 1,
            amount: amountItemsPerPage
        }
        setLoading(true);
        GetBooksPaged.doRequest(bookObj).then((res) => {
            if (res.statusExecucao && res?.dadosRetorno) {
                bookList.setValue(res.dadosRetorno.listBook);
                setPage(res.dadosRetorno.page);
                setPageCount(res.dadosRetorno.pageCount);
            } else {
                snackbar(res.mensagem || "Não foi possível buscar a lista de livros.").error();
                history.redirectTo('/home');
            }
            setLoading(false);
        }).catch((error) => {
            snackbar("Não foi possível buscar a lista de livros.").error();
            setLoading(false);
            history.redirectTo('/404');
        })
    }

    function getNextPagedRequest() {
        const bookObj = {
            searchTerm: bookSearchTerm,
            page: page + 1,
            amount: amountItemsPerPage
        }

        GetBooksPaged.doRequest(bookObj).then((res) => {
            if (res.statusExecucao && res.dadosRetorno) {
                const data = res.dadosRetorno;
                setPage(data.page);
                setPageCount(data.pageCount);
                bookList.setValue([...bookList.value, ...data.listBook]);
            } else {
                snackbar(res.mensagem || "Não foi possível buscar a lista de livros.").error();
            }
        }).catch((error) => {
            snackbar("Não foi possível buscar a lista de livros.").error();
        });
    }

    return (
        <ListBookView
            bookList={bookList.value}
            bookSearchTerm={bookSearchTerm}
            page={page}
            pageCount={pageCount}
            infinityLoading={GetBooksPaged.loading}
            loading={loading}
            getBooksPaged={getBooksPaged}
            getNextPagedRequest={getNextPagedRequest}
            setBookSearchTerm={setBookSearchTerm}
        />
    )

}

export default ListBook;