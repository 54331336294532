import { useContext } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import {
    AppBar, List, Toolbar, Drawer,
    IconButton, ListItemButton, Box,
    ListItemIcon, Divider, ListItemText
} from '@mui/material';

import { FratLogoBranca } from '../../../../assets';
import { Logo, MenuSection } from './TopBar.style';
import { MainLayoutContext } from '../../MainLayout';
import { IMainLayoutContext } from '../../MainLayout.interface';

function TopBar() {
    const {
        routesBar,
        openDrawer,
        closeDrawer,
        drawerStatus,
        redirectPage,
        actionsBar
    } = useContext<IMainLayoutContext>(MainLayoutContext);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={openDrawer}
                    >
                        <MenuIcon color='secondary'/>
                    </IconButton>
                    <Box sx={{ flexGrow: 1 }} display="flex" justifyContent="center">
                        <Logo src={FratLogoBranca} alt="Logo Frat" onClick={() => redirectPage("home")} />
                    </Box>
                </Toolbar>
            </AppBar>

            <Drawer open={drawerStatus} onClose={closeDrawer}>
                <List>
                    {routesBar.map(({ Label, Key, Icon }, index) => (
                        <MenuSection key={index} disablePadding>
                            <ListItemButton onClick={() => redirectPage(Key)}>
                                <ListItemIcon>
                                    <Icon />
                                </ListItemIcon>
                                <ListItemText primary={Label} />
                            </ListItemButton>
                        </MenuSection>
                    ))}
                </List>
                <Divider />
                <List>
                    {actionsBar.map(({ Label, Action, Icon }, index) => (
                        <MenuSection key={index} disablePadding>
                            <ListItemButton onClick={Action}>
                                <ListItemIcon>
                                    <Icon />
                                </ListItemIcon>
                                <ListItemText primary={Label} />
                            </ListItemButton>
                        </MenuSection>
                    ))}
                </List>
            </Drawer>
        </Box>
    );
}

export default TopBar;