import { Divider, styled, Avatar, Grid } from "@mui/material";

export const BrotherTexts = styled(Grid)(() => ({
    wordBreak: 'break-all',
    flexDirection: 'column',
    cursor: 'pointer',
}));

export const SectionDivider = styled(Divider)(() => ({
    marginTop: 12,
    marginBottom: 12
}));

export const ObreiroAvatar = styled(Avatar)(({theme}) => ({
    marginRight: 10,
    marginBottom: 10,
    cursor: 'pointer',
    boxShadow: `0px 0px 5px -1px ${theme.palette.mode == "dark" ? '#FFFFFF' : '#000000'}`,
    ':hover': {
        transition: 'box-shadow 0.8s 0s ease',
        boxShadow: `0px 0px 12px -1px ${theme.palette.mode == "dark" ? '#FFFFFF' : '#000000'}`
    }
}));