import RestAPIModule from './../../RestApi';
import { IResponse } from '../../../../model';
import { IBrotherDetailsInput, IBrotherLoginInput, IBrotherLoginOutput, IBrothersPagedInput, IBrothersPagedOutput, IBrotherUpdateBasicInformationInput, IBrotherUpdatePhotoInput, IContactUsInput } from "./Brother.interface";
import { IBrotherDetailsOutput, IBrotherDTO } from '../../../../model/BrotherDTO.interface';

export default class BrotherRequest {
    public static URL_BROTHER = `${RestAPIModule.URL}/Obreiros`;

    public static Login(params: IBrotherLoginInput): Promise<IResponse<IBrotherLoginOutput>> {
        return RestAPIModule.httpGet(`${BrotherRequest.URL_BROTHER}/Login?placet=${params.placet}&senha=${params.password}`).then((response) => response);
    }
    public static GetDetailsBrother(params: IBrotherDetailsInput): Promise<IResponse<IBrotherDetailsOutput>> {
        return RestAPIModule.httpGet(`${BrotherRequest.URL_BROTHER}/ObreiroDetalhe?idObreiro=${params.idBrother}`).then((response) => response);
    }
    public static UpdatePhoto(params: IBrotherUpdatePhotoInput): Promise<IResponse<IBrotherDTO>> {
        return RestAPIModule.httpPut(`${BrotherRequest.URL_BROTHER}/AlterarFoto`, params).then((response) => response);
    }
    public static UpdateBasicInformation(params: IBrotherUpdateBasicInformationInput): Promise<IResponse<IBrotherDTO>> {
        return RestAPIModule.httpPut(`${BrotherRequest.URL_BROTHER}/AlterarInoformacoesBasicas`, params).then((response) => response);
    }
    public static SearchBrother(params: IBrothersPagedInput): Promise<IResponse<IBrothersPagedOutput>> {
        return RestAPIModule.httpGet(`${BrotherRequest.URL_BROTHER}/SearchBrother?searchTerm=${params.searchTerm}&page=${params.page}&amount=${params.amount}`).then((response) => response);
    }
    public static ContactUs(params: IContactUsInput): Promise<IResponse<string>> {
        return RestAPIModule.httpGet(`${BrotherRequest.URL_BROTHER}/ContactUs?name=${params.name}&email=${params.email}&message=${params.message}`).then((response) => response);
    }
}