import { useContext, useState } from 'react';

import LanguagesView from './Languages.view';
import { ILanguages } from './Languages.interface';
import { useDoRequest, useSnackbar } from './../../../../../hooks';
import { ModulesContext } from './../../../../../context/modules/Modules';
import { ITblIdiomaObreiro } from './../../../../../model/BrotherDTO.interface';
import { IModulesContext } from './../../../../../context/modules/Modules.interface';
import { IBrotherLanguageDelete } from './../../../../../modules/api/endpoints/brotherLanguage/BrotherLanguage.interface';

function Languages(props: ILanguages) {
    const { languageData, isMyProfile, setLanguageData } = props;
    const { Analytics } = useContext<IModulesContext>(ModulesContext);

    const [addLanguageModalVisible, setAddLanguageModalVisible] = useState<boolean>(false);
    const [modalType, setModalType] = useState<'create' | 'edit'>('create');
    const [editItem, setEditItem] = useState<ITblIdiomaObreiro>();

    const BrotherLanguagesDelete = useDoRequest((api) => api.BrotherLanguages.Delete);

    const snackbar = useSnackbar();

    function closeLanguageModal() {
        setAddLanguageModalVisible(false);
    }

    function openLanguageModal(type: 'create' | 'edit', item?: ITblIdiomaObreiro) {
        setModalType(type);
        if(item){
            setEditItem(item);
        }
        setAddLanguageModalVisible(true);
    }

    function deleteLanguage(language: ITblIdiomaObreiro) {
        Analytics.logScreenAction('Detalhes de um irmão', 'Deletar idioma');
        const deleteObj: IBrotherLanguageDelete = {
            idIdiomaObreiro: language.idIdiomaObreiro
        }

        BrotherLanguagesDelete.doRequest(deleteObj).then((response) => {
            if(response?.dadosRetorno && response.statusExecucao){
                let deletedLanguage = response.dadosRetorno;
                let languageDataInstance = languageData;

                const index = languageDataInstance.findIndex((lang) => lang.idIdioma == deletedLanguage.idIdioma);
                languageDataInstance.splice(index, 1);

                setLanguageData(languageDataInstance);

                snackbar(response.mensagem || "Idioma deletado com sucesso.").success();
            } else {
                snackbar(response.mensagem || "Não foi possível buscar os dados dos idiomas.").error();
            }
        }).catch((error) => {
            snackbar("Não foi possível excluir idioma.").error();
        })
    }

    return (
        <LanguagesView
            addLanguageModalVisible={addLanguageModalVisible}
            isMyProfile={isMyProfile}
            languageData={languageData}
            modalType={modalType}
            editItem={editItem}
            closeLanguageModal={closeLanguageModal}
            openLanguageModal={openLanguageModal}
            setLanguageData={setLanguageData}
            deleteLanguage={deleteLanguage}
        />
    );
}

export default Languages;