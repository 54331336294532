import { FormEvent } from 'react';
import { Box, Typography, Grid } from '@mui/material';

import { ILoginView } from './Login.interface';
import { FratLogo, AppleStoreLogo, GooglePlayLogo } from './../../assets';
import { BoxStyled, ContainerStyled, TextFieldStyled, ButtonStyled, InputStyle, GridLeft, GridRight, Image } from "./Login.styles";

function LoginView(props: ILoginView) {
    const { onChangeFormValues, validationForm, loading } = props;

    return (
        <BoxStyled>
            <ContainerStyled maxWidth="xs">
                <Box display="flex" flexDirection="column" alignItems="center">
                    <img src={FratLogo} alt="Logo Frat" height="200px" />
                    <Typography color="#2B374F" component="h1" variant="h5" align="center">
                        Esta versão do FRAT está indisponível.
                    </Typography>
                    <Typography color="#2B374F" component="h1" variant="h5" align="center">
                        Por favor utilize os aplicativos:
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={(e: FormEvent<HTMLFormElement>) => validationForm(e)}
                        noValidate
                        sx={{ mt: 1 }}
                    >
                        {/*<TextFieldStyled*/}
                        {/*    className="input-style"*/}
                        {/*    label='Informe seu Placet'*/}
                        {/*    color="primary"*/}
                        {/*    variant="outlined"*/}
                        {/*    InputLabelProps={InputStyle}*/}
                        {/*    fullWidth*/}
                        {/*    onChange={(e: any) => onChangeFormValues('placet', e.target.value)}*/}
                        {/*/>*/}
                        {/*<TextFieldStyled*/}
                        {/*    className="input-style"*/}
                        {/*    label='Informe sua senha'*/}
                        {/*    type='password'*/}
                        {/*    color="primary"*/}
                        {/*    variant="outlined"*/}
                        {/*    InputLabelProps={InputStyle}*/}
                        {/*    fullWidth*/}
                        {/*    onChange={(e: any) => onChangeFormValues('password', e.target.value)}*/}
                        {/*/>*/}
                        {/*<Box display="flex" flexDirection="column" alignItems="center">*/}
                        {/*    <ButtonStyled*/}
                        {/*        type="submit"*/}
                        {/*        fullWidth*/}
                        {/*        variant="contained"*/}
                        {/*        loading={loading}*/}
                        {/*    >*/}
                        {/*        Acessar FRAT*/}
                        {/*    </ButtonStyled>*/}
                        {/*</Box>*/}
                        <Box sx={{ mt: 1 }}>
                            <Grid container>
                                <GridLeft item xs={12} sm={6} md={6}>
                                    <a target="_blank" href='https://play.google.com/store/apps/details?id=br.com.glmmg.frat'>
                                        <Image src={GooglePlayLogo} alt="Play Store" style={{maxHeight: '60px'}}/>
                                    </a>
                                </GridLeft>
                                <GridRight item xs={12} sm={6} md={6}>
                                    <a target="_blank" href='https://apps.apple.com/br/app/frat/id1577022060'>
                                        <Image src={AppleStoreLogo} alt="Apple Store" style={{maxHeight: '60px'}}/>
                                    </a>
                                </GridRight>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </ContainerStyled>
        </BoxStyled>
    );
}

export default LoginView;