import { createContext, useState } from 'react';
import {
    HomeOutlined as HomeIcon,
    BookOutlined as BookIcon,
    EventOutlined as EventIcon,
    Brightness4 as DarkThemeIcon,
    Brightness7 as LightThemeIcon,
    WorkOutlineOutlined as JobIcon,
    BusinessOutlined as CompanyIcon,
    PermIdentityOutlined as UserIcon,
    ExitToAppOutlined as SignOutIcon,
    People as PeopleIcon
} from '@mui/icons-material/';

import MainLayoutView from './MainLayout.view'
import { useAuth, useHistory } from '../../hooks';
import { useThemeState } from '../../context/theme/Theme';
import { useGlobalState } from '../../context/global/Global';
import { IRoutesBar, IActionsBar, IMainLayoutContext } from './MainLayout.interface';

export const MainLayoutContext = createContext({} as IMainLayoutContext);

function MainLayout() {
    const history = useHistory();
    const { signOut } = useAuth();
    const { user } = useGlobalState();
    const { darkMode, toggleDarkMode } = useThemeState();
    const [drawerStatus, setDrawerStatus] = useState<boolean>(false);

    function getUrlPage(page: string) {
        switch (page) {
            case 'company':
                return '/listCompany';
            case 'job':
                return '/listJob'
            case 'book':
                return '/listBook'
            case 'event':
                return '/listEvent'
            case 'brotherList':
                return '/listBrother'
            case 'brother':
                return `/detailBrother/${user?.idObreiro}`
            default:
                return '/home';
        }
    }

    function redirectPage(key: string) {
        history.redirectTo(getUrlPage(key));
        closeDrawer();
    }

    function exitApp() {
        signOut();
        closeDrawer();
    }

    function changeTheme() {
        toggleDarkMode();
        closeDrawer();
    }

    function openDrawer() {
        setDrawerStatus(true);
    }

    function closeDrawer() {
        setDrawerStatus(false);
    }

    const routesBar: IRoutesBar[] = [{
        Label: "Home",
        Key: "/",
        Icon: HomeIcon
    },   {
        Label: "Obreiros",
        Key: "brotherList",
        Icon: PeopleIcon
    }, {
        Label: "Empresas",
        Key: "company",
        Icon: CompanyIcon
    }, {
        Label: "Vagas",
        Key: "job",
        Icon: JobIcon
    }, {
        Label: "Livros",
        Key: "book",
        Icon: BookIcon
    }, {
        Label: "Eventos",
        Key: "event",
        Icon: EventIcon
    }, {
        Label: "Perfil",
        Key: `brother`,
        Icon: UserIcon
    }]

    const actionsBar: IActionsBar[] = [{
        Label: "Tema",
        Action: changeTheme,
        Icon: darkMode ? DarkThemeIcon : LightThemeIcon
    }, {
        Label: "Sair",
        Action: exitApp,
        Icon: SignOutIcon
    }]

    const sharedValues: IMainLayoutContext = {
        routesBar,
        actionsBar,
        openDrawer,
        closeDrawer,
        redirectPage,
        drawerStatus,
    }

    return (
        <MainLayoutContext.Provider value={sharedValues}>
            <MainLayoutView />
        </MainLayoutContext.Provider>
    );
}

export default MainLayout;