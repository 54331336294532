import { Button, FormGroup, styled, Typography } from "@mui/material";

export const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: 18
}));

export const SearchButton = styled(Button)(() => ({
    padding: 8, 
}));

export const CheckBoxFormGroup = styled(FormGroup)(({ theme }) => ({
    border: "1px solid", 
    paddingLeft: 8, 
    height: "40px", 
    borderRadius: "5px",
    borderColor: theme.palette.grey[700]
}));