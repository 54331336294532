import { useTheme, useMediaQuery } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { IScreenCarouselView } from './ScreenCarousel.interface';

import { CarouselStyled, Image, ImageBox } from './ScreenCarousel.style';

function ScreenCarouselView(props: IScreenCarouselView) {
    const { items, onChange } = props;

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <CarouselStyled
            NextIcon={<ArrowForwardIcon />}
            PrevIcon={<ArrowBackIcon />}
            animation="slide"
            duration={400}
            swipe={true}
            cycleNavigation
            navButtonsAlwaysInvisible={matches}
            navButtonsAlwaysVisible={!matches}
            navButtonsWrapperProps={{
                style: {
                    margin: "0px 24px"
                }
            }}
            indicatorIconButtonProps={{
                style: {
                    margin: "0px 8px",
                    padding: "4px"
                }
            }}
            onChange={(now, previus) => onChange ? onChange(now, previus) : null}
        >
            {
                items.map((item, i) => {
                    return (
                        <ImageBox key={i}>
                            <Image src={item.src} />
                        </ImageBox>
                    )
                })
            }
        </CarouselStyled>
    )
}

export default ScreenCarouselView;