import { IBrotherDTO } from '../../model';
import contextTypes from './contextTypes';
import { IStateReducer } from './Global.interface';

function createAction(type: string, payload: IStateReducer) {
    return ({
        type,
        payload
    });
}

function signInAction(token: string, user: IBrotherDTO) {
    return createAction(contextTypes.SIGN_IN, {token, isAuthenticated: false, user: user});
}

function setUserAction(user: IBrotherDTO) {
    return createAction(contextTypes.SET_USER, {user: user, token: '', isAuthenticated: false});
}

function signOutAction() {
    return createAction(contextTypes.SIGN_OUT, {token: '', isAuthenticated: false,  user: null});
}

export {
    signInAction,
    signOutAction,
    setUserAction
};