import { CardContent } from "@mui/material";
import { CardData } from "./CardStyled.style"
import { ICardStyled } from "./CardStyled.interface";

function CardStyled(props: ICardStyled) {
    const { children, style } = props;

    return (
        <CardData style={style}>
            <CardContent>
                {children}
            </CardContent>
        </CardData>
    )
}

export default CardStyled;