import { Typography } from "@mui/material";

import { AcademicModal } from "./../../";
import AddIcon from '@mui/icons-material/Add';
import { IPropsView } from "./AcademicHistory.interface";
import { AddButton, DefaultDisplayBox, PageContainer } from "./AcademicHistory.style";

function AcademicHistoryView(props: IPropsView) {

    const { renderAcademicHistory, isMyProfile, academicHistoryModalVisible, modalType,
        selectedAcademicHistory, allAcademicHistory, setAllHistory, academicHistoryModal, setAcademicModalVisible } = props;

    return (
        <PageContainer>
            <DefaultDisplayBox>
                <Typography variant="subtitle1" color="secondary">FORMAÇÃO ACADÊMICA</Typography>
                {isMyProfile && (
                    <AddButton onClick={() => academicHistoryModal(null, 'create')} >
                        <AddIcon />
                    </AddButton>
                )}
            </DefaultDisplayBox>
            {renderAcademicHistory}

            <AcademicModal
                setOpenState={setAcademicModalVisible}
                openState={academicHistoryModalVisible}
                type={modalType} selectedAcademicHistory={selectedAcademicHistory}
                allAcademicHistory={allAcademicHistory}
                setAllHistory={setAllHistory}
            />
        </PageContainer>
    );
}

export default AcademicHistoryView;