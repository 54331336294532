import RestAPIModule from '../../RestApi';
import { IInstructionLevelDTO, IResponse } from '../../../../model';
import { IAddAcademicHistoryInput, IDeleteAcademicHistoryInput, IEditAcademicHistoryInput } from './Academic.interface';
import { IHistoricoAcademicoObreiro } from '../../../../model/BrotherDTO.interface';

export default class AcademicRequest {
    public static URL_ACADEMIC = `${RestAPIModule.URL}/HistoricoAcademicoObreiro`;
    public static URL_INSTRUCTIONLEVEL = `${RestAPIModule.URL}/NivelInstrucao`;

    public static EditAcademicHistory(params: IEditAcademicHistoryInput): Promise<IResponse<IHistoricoAcademicoObreiro>> {
        return RestAPIModule.httpPut(`${AcademicRequest.URL_ACADEMIC}/Alterar`, params).then((response) => response);
    }

    public static AddAcademicHistory(params: IAddAcademicHistoryInput): Promise<IResponse<IHistoricoAcademicoObreiro>> {
        return RestAPIModule.httpPost(`${AcademicRequest.URL_ACADEMIC}/Incluir`, params).then((response) => response);
    }

    public static DeleteAcademicHistory(params: IDeleteAcademicHistoryInput): Promise<IResponse<IHistoricoAcademicoObreiro>> {
        return RestAPIModule.httpDelete(`${AcademicRequest.URL_ACADEMIC}/Excluir?IdHistoricoAcademico=${params.idHistoricoAcademico}`).then((response) => response);
    }
    public static GetInstructionLevel(params: null): Promise<IResponse<IInstructionLevelDTO>>{
        return RestAPIModule.httpGet(`${AcademicRequest.URL_INSTRUCTIONLEVEL}/Listar`).then((response) => response)
    }
}