import React from 'react';
import { format } from 'date-fns';
import SendIcon from '@mui/icons-material/Send';
import { Box, Grid, IconButton, Typography } from '@mui/material';

import { IBrotherRedirectCardView } from './BrotherRedirectCard.interface';
import { BrotherTexts, ObreiroAvatar, SectionDivider } from './BrotherRedirectCard.style';

function BrotherRedirectCardView(props: IBrotherRedirectCardView) {
    const {
        type, brother, goToBrotherCreatorPage,
        avatarSize, hasContactInformation, mask,
        getBrotherAge
    } = props;

    function renderCentralizedCard() {
        if(!brother) return null;

        const brotherBirthDate = format(new Date(brother?.dataNascimento), "dd'/'MM'/'yyyy");

        return (
            <Grid item container xs={12} lg={12} sm={12} md={12} xl={12} alignItems="center">
                <Grid item lg={12} xs={12} display="flex" justifyContent="center">
                    <ObreiroAvatar
                        style={avatarSize === "large" ? {width: 160, height: 160} : {width: 120, height: 120}}
                        alt="Foto do obreiro criador"
                        onClick={goToBrotherCreatorPage}
                        src={mask.formatBase64(brother?.fotoObreiro)}
                    />
                </Grid>

                <BrotherTexts item lg={9} md={9} sm={9} xs={9} onClick={goToBrotherCreatorPage}>
                    <Typography variant="h3">{brother?.nomeObreiro}</Typography>
                    <Typography variant="subtitle1">{brotherBirthDate}</Typography>
                </BrotherTexts>

                <Grid item lg={3} md={3} sm={3} display={{ xs: 'none', lg: 'flex' }} justifyContent="flex-end">
                    <IconButton onClick={goToBrotherCreatorPage}>
                        <SendIcon/>
                    </IconButton>
                </Grid>
            </Grid>
        );
    }

    function renderLinearCard() {
        if(!brother) return null;

        return (
            <Grid container spacing={2} alignItems="center" display="flex">
                <Grid item lg={2.8} xs={12} alignItems="center" display="flex" justifyContent="center">
                    <ObreiroAvatar
                        style={avatarSize === "large" ? {width: 160, height: 160} : {width: 120, height: 120}}
                        alt="Foto do obreiro criador"
                        onClick={goToBrotherCreatorPage}
                        src={mask.formatBase64(brother?.fotoObreiro)}
                    />
                </Grid>

                <Grid item lg={6.2} xs={12}>
                    <Box onClick={goToBrotherCreatorPage} style={{ cursor: 'pointer' }}>
                        <Typography variant="h2">{brother?.nomeObreiro} </Typography>
                        <Typography variant="h4">{`${getBrotherAge(brother?.dataNascimento)} anos`}</Typography>
                    </Box>

                    {hasContactInformation && (
                        <>
                            <SectionDivider />
                            <Typography variant="h5">Profissão: {brother?.profissaoObreiro}</Typography>
                            <Typography variant="h5">Email: {brother?.email}</Typography>
                            <Typography variant="h5">Telefone: {mask.applyMask(mask.MASK.CELLPHONE, brother?.telefoneCelular)}</Typography>
                        </>
                    )}
                </Grid>

                <Grid item lg={2} xs={12} display={{ xs: 'none', lg: 'flex' }} justifyContent="flex-end">
                    <IconButton onClick={goToBrotherCreatorPage}>
                        <SendIcon />
                    </IconButton>
                </Grid>
            </Grid>
        );
    }

    return (
        <React.Fragment>
            {type === "centralized" ? (
                renderCentralizedCard()
            ) : (
                renderLinearCard()
            )}
        </React.Fragment>
    );
}

export default BrotherRedirectCardView;