import { Grid, IconButton, styled, Typography } from '@mui/material';

export const ContentContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: "space-between",
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down("sm")]: {
        flexDirection: 'column'
    }
}));

export const Title = styled(Typography)(({ theme }) => ({
    color: "white",
    fontWeight: 700,
    fontSize: 100,
    [theme.breakpoints.down("sm")]: {
        fontSize: 80
    },
    [theme.breakpoints.down(370)]: {
        fontSize: 60
    }
}));

export const HelperText = styled(Typography)(({ theme }) => ({
    color: "white",
    fontWeight: 700,
    fontSize: 18,
    textDecoration: "underline",
    transition: " 0.2s",
    cursor: "pointer",
    ':hover': {
        color: theme.palette.primary.dark,
    }
}));

export const CellPhoneImg = styled('img')(({ theme }) => ({
    width: "100%",
    marginLeft: 24,
    [theme.breakpoints.down("sm")]: {
        marginTop: 24,
        marginLeft: 0
    }
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
    color: "white",
    opacity: 0.8,
    fontWeight: 400,
    fontSize: 24,
    [theme.breakpoints.down("md")]: {
        fontSize: 20,
    }
}));

export const ContentGrid = styled(Grid)(({ theme }) => ({
    display: "flex",
    height: "100%",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
        alignItems: "center"
    },
    [theme.breakpoints.down("sm")]: {
        width: "100%"
    }
}));

export const SocialIcons = styled(IconButton)(({ theme }) => ({
    color: "white",
    marginLeft: 6
}));