import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, styled, Typography } from '@mui/material';

export const PageContainer = styled('div')(() => ({
    width: '100%',
}));

export const DefaultBox = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: "space-between",
    marginTop: 12
}));

export const InfosBox = styled('div')(() => ({
    marginLeft: 12
}));

export const JobName = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: 18
}));

export const InfosText = styled(Typography)(() => ({
    display: 'block',
    marginTop: 2
}));

export const DeleteIconRed = styled(DeleteIcon)(({ theme }) => ({
    color: theme.palette.error.main
}));

export const AddButton = styled(IconButton)(({ theme }) => ({
    ':hover': {
        borderColor: theme.palette.text.secondary,
    }
}));