import { Box, Container, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import MenuIcon from "@mui/icons-material/Menu";

import { FratLogoBranca } from '../../../../assets';
import { IHeaderProps } from './Header.interface';

import { AppBarStyled, DownloadButton, HeaderButtonBox, HeaderButtons, ImageLogo, ResponsiveMenuBox } from './Header.style';

function HeaderView(props: IHeaderProps) {
    const { sections, anchorElNav, headerBackground, isAuthenticated, onHeaderButtonClick, handleCloseNavMenu, handleOpenNavMenu, redirect } = props;

    return (
        <AppBarStyled position="fixed" elevation={0} headerBackground={headerBackground}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <ImageLogo src={FratLogoBranca} style={{ cursor: "pointer" }} />

                    <Box sx={{ flexGrow: 1, display: 'flex' }} />

                    <HeaderButtonBox>
                        {sections.map((page, index) => (
                            <HeaderButtons
                                variant="contained"
                                key={index}
                                onClick={() => onHeaderButtonClick(page.id)}
                                sx={{ my: 2 }}
                            >
                                {page.name}
                            </HeaderButtons>
                        ))}

                        <DownloadButton variant="outlined" onClick={redirect}>
                            <Typography fontSize="16px" textAlign="left">
                                {isAuthenticated ? "Ir para Home" : "Fazer Login"}
                            </Typography>
                        </DownloadButton>
                    </HeaderButtonBox>

                    <ResponsiveMenuBox>
                        <IconButton
                            size="large"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            sx={{ color: "white" }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {sections.map((page, index) => (
                                <MenuItem key={index} onClick={() => onHeaderButtonClick(page.id)}>
                                    <Typography textAlign="center">{page.name}</Typography>
                                </MenuItem>
                            ))}

                            <MenuItem onClick={redirect}>
                                <Typography textAlign="center">
                                    Fazer Login
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </ResponsiveMenuBox>
                </Toolbar>
            </Container>
        </AppBarStyled>
    )
}

export default HeaderView;