import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, styled, Typography } from '@mui/material';

export const PageContainer = styled('div')(() => ({
    width: '100%',
}))

export const DefaultBox = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    marginTop: 12
}))

export const DefaultDisplayBox = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
}))

export const SchoolNameBox = styled('div')(() => ({
    marginLeft: 12
}))

export const SchoolName = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: 18
}))

export const DeleteIconRed = styled(DeleteIcon)(({ theme }) => ({
    color: theme.palette.error.main,
}))

export const InfosText = styled(Typography)(() => ({
    display: 'block',
    marginTop: 2
}))

export const LevelName = styled(Typography)(({ theme }) => ({
    color: 'white',
    display: 'inline-block',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.main,
    marginTop: 8,
    borderRadius: 4,
    padding: 4
}))

export const AddButton = styled(IconButton)(({ theme }) => ({
    ':hover': {
        borderColor: theme.palette.text.secondary,
    }
}))














