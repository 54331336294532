import {useContext} from "react";
import { setUserAction, signInAction, signOutAction } from '../../context/global/actions';
import { useGlobalDispatch, useGlobalState } from '../../context/global/Global';
import { IBrotherDTO } from '../../model';

import {IModulesContext} from "../../context/modules/Modules.interface";
import {ModulesContext} from "../../context/modules/Modules";

function useAuth() {
    const modules = useContext<IModulesContext>(ModulesContext);

    const {isAuthenticated, token} = useGlobalState();
    const globalDispatch = useGlobalDispatch();

    const setToken = (token: string, user: IBrotherDTO) => {
        const encryptedToken = modules.Encryption.encryptByAES(token, modules.Encryption.keys.AUTH);
        globalDispatch(signInAction(encryptedToken, user));
    };

    const setNewUser = (user: IBrotherDTO) => {
        globalDispatch(setUserAction(user));
    }

    const getToken = () => {
        return modules.Encryption.decryptByAES(token, modules.Encryption.keys.AUTH) || '';
    };

    const signIn = (token: string, user: IBrotherDTO) => {
        setToken(token, user);
    };

    const setUser = (user: IBrotherDTO) => {
        setNewUser(user);
    };

    const signOut = () => {
        globalDispatch(signOutAction());
    };

    return {
        isAuthenticated,
        setToken,
        getToken,
        signIn,
        signOut,
        setUser
    };
}

export default useAuth;