import {useState, createContext, useContext, useEffect, useMemo, useRef} from 'react';
import {IProps, IDeepLinkContext} from './DeepLink.interface';
import {DeepLinkModal} from '../../components';
import {IModulesContext} from "../modules/Modules.interface";
import {ModulesContext} from "../modules/Modules";

const DeepLinkContext = createContext({} as IDeepLinkContext);

function DeepLinkProvider(props: IProps) {
    const {children} = props;

    const openPopup = useRef<boolean>(true);
    const {UserSystem} = useContext<IModulesContext>(ModulesContext);

    const [visible, setVisible] = useState<boolean>(openPopup.current && !UserSystem.isDesktop());
    const [currentRedirect, setCurrentRedirect] = useState<string | null>(null);

    const linkAppleStore = useMemo(() => {return 'https://apps.apple.com/br/app/frat/id1577022060'}, []);
    const linkOpenApp = useMemo(getLinkOpenApp, [currentRedirect]);

    useEffect(() => changeVisible(!!currentRedirect), [currentRedirect]);

    function changeVisible(visibility: boolean) {
        if (openPopup.current && !UserSystem.isDesktop()) {
            setVisible(visibility);
        }
    }

    function setRedirectScreen(screen: string | null) {
        setCurrentRedirect(screen);
    }

    function onClose() {
        changeVisible(false);
        openPopup.current = false;
    }

    function getLinkOpenApp() {
        return `frat://${currentRedirect ?? ''}`
    }

    return (
        <DeepLinkContext.Provider value={{setRedirectScreen}}>
            {children}

            <DeepLinkModal
                visible={visible}
                linkOpenApp={linkOpenApp}
                linkDownloadAppleStore={linkAppleStore}
                showAppleStoreButton={UserSystem.isIOS()}
                onClick={() => changeVisible(false)}
                onClickClose={onClose}
            />
        </DeepLinkContext.Provider>
    );
}

export {DeepLinkContext, DeepLinkProvider};