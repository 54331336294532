import PostRequest from "./post/Post.request";
import EventRequest from "./event/Event.request";
import BrotherRequest from "./brother/Brother.request";
import CompanyRequest from "./company/Company.request";
import BookRequest from "./book/Book.request";
import JobRequest from "./Job/Job.request";
import ProfessionalHistory from "./professionalHistoryBrother/ProfessionalHistory.request";
import AcademicRequest from "./academicHistory/Academic.request";
import LanguagesRequest from "./languages/Languages.request";
import BrotherLanguagesRequest from "./brotherLanguage/BrotherLanguage.request";
import DegreeOfMeetingRequest from "./degreeOfMeeting/DegreeOfMeeting.request";
import CategoryRequest from "./category/Category.request";
import InstructionLevelRequest from "./InstructionLevel/InstructionLevel.request";

export default class RestAPIEndpoints {
    static Post = PostRequest;
    static Event = EventRequest;
    static Brother = BrotherRequest;
    static Languages = LanguagesRequest;
    static BrotherLanguages= BrotherLanguagesRequest;
    static Company = CompanyRequest;
    static Book = BookRequest;
    static Job = JobRequest;
    static ProfessionalHistory = ProfessionalHistory;
    static AcademicHistory = AcademicRequest;
    static DegreeOfMeeting = DegreeOfMeetingRequest;
    static Category = CategoryRequest;
    static InstructionLevel = InstructionLevelRequest;
}