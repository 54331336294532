import CryptoJS from 'crypto-js';

export default class Encryption {
    public keys = {
        AUTH: '1b671a64-40d5-491e-99b0-da01ff1f3341',
    }

    public encryption(text: string, salt: string) {
        if (!text || !salt) return "";
        try {
            // transforma o json em um array de bytes
            let dataToEncrypt = CryptoJS.enc.Utf8.parse(text);
            // cria uma key de 256 bits em SHA-3 usando o salt
            let key = CryptoJS.SHA3(salt, {outputLength: 256});
            // cria uma iv em Hex usando o salt
            let iv = CryptoJS.enc.Hex.parse(salt);
            // encripta o dado em AES
            let encrypted = CryptoJS.AES.encrypt(dataToEncrypt, key, {iv: iv});
            // retorna o dado no formato de base64
            return encrypted.toString();
        } catch (e) {
            return "";
        }
    }
    
    public encryptByAES(data: any, salt: string) {
        try {
            // converte dado em json
            const json = JSON.stringify(data)
            // transforma o json em um array de bytes
            const dataToEncrypt = CryptoJS.enc.Utf8.parse(json);
            // cria uma key de 256 bits em SHA-3 usando o salt
            const key = CryptoJS.SHA3(salt, {outputLength: 256});
            // cria uma iv em Hex usando o salt
            const iv = CryptoJS.enc.Hex.parse(salt);
            // encripta o dado em AES
            const encrypted = CryptoJS.AES.encrypt(dataToEncrypt, key, {iv});
            // retorna o dado no formato de base64
            return encrypted.toString();
        } catch (e) {
            return "";
        }
    }
    
    public encryptPassword(text: string, salt: string) {
        if (!text || !salt) return "";
        try {
            // transforma o json em um array de bytes
            let dataToEncrypt = CryptoJS.enc.Utf8.parse(text);
            // cria uma key de 256 bits em SHA-3 usando o salt
            let key = CryptoJS.SHA3(salt, {outputLength: 256});
            // cria uma iv em Hex usando o salt
            let iv = CryptoJS.enc.Hex.parse(salt);
            // encripta o dado em AES
            let encrypted = CryptoJS.AES.encrypt(dataToEncrypt, key, {iv: iv});
            // retorna o dado no formato de base64
            return encrypted.toString();
        } catch (e) {
            return "";
        }
    }

    public decryptByAES(ciphertext: string, salt: string) {
        try {
            // passa o dado em base64 para um array de bytes
            // const dataToDecrypt = CryptoJS.enc.Base64.parse(ciphertext);
            // cria uma key de 256 bits em SHA-3 usando o salt
            const key = CryptoJS.SHA3(salt, {outputLength: 256});
            // cria uma iv em Hex usando o salt
            const iv = CryptoJS.enc.Hex.parse(salt);
            // descripta o dado em AES
            const decrypted = CryptoJS.AES.decrypt(ciphertext, key, {iv: iv});
            // retorna o dado em formato json
            const json = CryptoJS.enc.Utf8.stringify(decrypted);
            // converte de json para o dado original
            return JSON.parse(json);
        } catch (e) {
            return null;
        }
    }
    
    public convertToBase64(data: any) {
        try {
            const json = JSON.stringify(data)
            const words = CryptoJS.enc.Utf8.parse(json);
            return CryptoJS.enc.Base64.stringify(words);
        } catch (e) {
            return "";
        }
    }
    
    public convertFromBase64(base64: string) {
        try {
            const words = CryptoJS.enc.Base64.parse(base64);
            const json = CryptoJS.enc.Utf8.stringify(words)
            return JSON.parse(json);
        } catch (e) {
            return null;
        }
    }
    
    public readFileAsText(file: File): Promise<FileReader['result']> {
        return new Promise((resolve, reject) => {
            try {
                const fileReader = new FileReader();
                fileReader.onload = () => resolve(fileReader.result);
                fileReader.readAsText(file);
            } catch (error) {
                reject({error});
            }
        });
    }
    
    public readFileAsDataURL(file: File): Promise<FileReader['result']> | string {
        if (!file) return "";
        return new Promise((resolve, reject) => {
            try {
                const fileReader: FileReader = new FileReader();
                fileReader.onload = () => resolve(fileReader.result);
                fileReader.readAsDataURL(file);
            } catch (error) {
                reject({error});
            }
        });
    }
    
    public readFilesAsDataURL(files: File[] = []) {
        return Array.from(files).reduce(async (filesAsDataUrl: Promise<any[]>, file: File) => {
            const accumulator: any[] = await filesAsDataUrl;
            const fileAsDataUrl = await this.readFileAsDataURL(file);
            accumulator.push(fileAsDataUrl);
            return Promise.all(accumulator);
        }, Promise.all([]));
    }
    
    public readFilesAsText(files: File[] = []) {
        return Array.from(files).reduce(async (filesAsText: Promise<any[]>, file: File) => {
            const accumulator: any[] = await filesAsText;
            const fileAsText = await this.readFileAsText(file);
            accumulator.push(fileAsText);
            return Promise.all(accumulator);
        }, Promise.all([]));
    }
}