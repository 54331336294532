import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { differenceInYears, format, parse } from "date-fns";
import { useDoRequest, useHistory, useSnackbar } from "../../hooks";

import { IPostDTO } from "../../model";
import { IInfoBrother } from "./DetailBrother.interface";
import { useGlobalState } from "../../context/global/Global";
import {
    IHistoricoAcademicoObreiro,
    IHistoricoProfissionalObreiro,
    ITblIdiomaObreiro
} from "../../model/BrotherDTO.interface";

import { IModulesContext } from "../../context/modules/Modules.interface";
import { ModulesContext } from "../../context/modules/Modules";

import DetailBrotherView from "./DetailBrother.view";
import { IGetByIdBrotherPaged } from "../../modules/api/endpoints/post/Post.interface";
import {IDeepLinkContext} from "../../context/deepLink/DeepLink.interface";
import {DeepLinkContext} from "../../context/deepLink/DeepLink";

function DetailBrother() {
    const { idBrother } = useParams();

    const snackbar = useSnackbar();
    const history = useHistory();
    const { user } = useGlobalState();

    const { Analytics, Mask } = useContext<IModulesContext>(ModulesContext);
    const { setRedirectScreen } = useContext<IDeepLinkContext>(DeepLinkContext);

    const [postAmount, setPostAmount] = useState<number>(4);
    const [postPage, setPostPage] = useState<number>(1);
    const [postPageCount, setPostPageCount] = useState<number>(1);

    const [tabSelect, setTabSelect] = useState<string>("1");
    const [academicRecordData, setAcademicRecordData] = useState<IHistoricoAcademicoObreiro[]>([]);
    const [languageData, setLanguageData] = useState<ITblIdiomaObreiro[]>([]);
    const [professionalHistory, setProfessionalHistory] = useState<IHistoricoProfissionalObreiro[]>([]);
    const [infoBrother, setInfoBrother] = useState<IInfoBrother>();
    const [brotherPosts, setBrotherPosts] = useState<IPostDTO[]>([]);
    const [modalEditState, setModalEditState] = useState<boolean>(false);

    const GetDetailsBrother = useDoRequest((api) => api.Brother.GetDetailsBrother);
    const GetByIdBrotherPaged = useDoRequest((api) => api.Post.GetByIdBrotherPaged);
    const GetByIdBrotherNextPage = useDoRequest((api) => api.Post.GetByIdBrotherPaged);

    useEffect(componentDidMount, [idBrother]);

    useEffect(() => {
        if (postPage === 1) return;

        getNextBrotherPosts();
    }, [postPage]);

    function componentDidMount() {
        Analytics.logScreen('Detalhes de um irmão');
        setRedirectScreen(`AuthScreen/BrotherDetail/${idBrother}`);

        getBrotherDetails();
        getBrotherPosts();

        return () => {
            setRedirectScreen(null);
        }
    }

    function handleTabChange(newValue: string) {
        setTabSelect(newValue);
    }

    function formatPhone(phone: string) {
        return Mask.applyMask(Mask.MASK.CELLPHONE, phone);
    }

    function getBrotherAge(birthDate?: string | Date) {
        if (!birthDate) return '';

        const formatedDate = format(new Date(birthDate), "dd'/'MM'/'yyyy");
        const date = parse(formatedDate, "dd/MM/yyyy", new Date());
        const age = differenceInYears(new Date(), date);

        return age.toString();
    }

    function getBrotherDetails() {
        if (idBrother) {
            const idNumberBrother = { idBrother: Number(idBrother) };

            GetDetailsBrother.doRequest(idNumberBrother).then((res) => {
                const data = res?.dadosRetorno;

                if (data && res?.statusExecucao) {
                    const brotherAge = getBrotherAge(new Date(data.dataNascimento));
                    setAcademicRecordData(data.tblHistoricoAcademicoObreiros);
                    setLanguageData(data.tblIdiomaObreiros);
                    setProfessionalHistory(data.tblHistoricoProfissionalObreiros);

                    setInfoBrother({
                        nameBrother: data.nomeObreiro,
                        occupationBrother: data.profissaoObreiro,
                        imageBrother: data.fotoObreiro,
                        personalBiography: data.biografiaProfissional,
                        masonicBiography: data.biografiaMaconica,
                        phone: formatPhone(data.telefoneCelular),
                        email: data.email,
                        ufBrother: data.ufObreiro,
                        orienteBrother: data.orienteObreiro,
                        idBrother: data.idObreiro,
                        bornDate: format(new Date(data.dataNascimento), "dd'/'MM'/'yyyy"),
                        initiationDate: format(new Date(data.dataIniciacao), "dd'/'MM'/'yyyy"),
                        instalationDate: format(new Date(data.dataInstalacao), "dd'/'MM'/'yyyy"),
                        exaltationDate: format(new Date(data.dataExaltacao), "dd'/'MM'/'yyyy"),
                        elevationDate: format(new Date(data.dataElevacao), "dd'/'MM'/'yyyy"),
                        ageBrother: brotherAge
                    });
                } else {
                    snackbar("Erro ao buscar os dados do obreiro.").error();
                    history.redirectTo('/home');
                }
            }).catch((error) => {
                snackbar("Não foi possível buscar os dados do obreiro.").error();
                history.redirectTo('/home');
            });
        }
    }

    function getBrotherPosts() {
        if (idBrother) {
            const dto: IGetByIdBrotherPaged = {
                idBrother: Number(idBrother),
                page: postPage,
                amount: postAmount
            }

            GetByIdBrotherPaged.doRequest(dto).then((res) => {
                if (res?.dadosRetorno && res?.statusExecucao) {
                    setBrotherPosts(res.dadosRetorno.listFeed);
                    setPostPageCount(res.dadosRetorno.pageCount);
                    setPostPage(res.dadosRetorno.page);
                    setPostAmount(res.dadosRetorno.amount);
                }
            }).catch((error) => {
                snackbar("Não foi possível buscar posts do obreiro.").error();
            });
        }
    }

    function getNextBrotherPosts() {
        const dto: IGetByIdBrotherPaged = {
            idBrother: Number(idBrother),
            page: postPage + 1,
            amount: postAmount
        }

        GetByIdBrotherNextPage.doRequest(dto).then((res) => {
            if (res?.dadosRetorno && res?.statusExecucao) {
                setBrotherPosts([...brotherPosts, ...res.dadosRetorno.listFeed]);
                setPostPage(res?.dadosRetorno.page);
                setPostPageCount(res.dadosRetorno.pageCount);
                setPostAmount(res.dadosRetorno.amount);
            }
        }).catch((error) => {
            snackbar("Não foi possível buscar posts do obreiro.").error();
        });
    }

    return (
        <DetailBrotherView
            tabSelect={tabSelect}
            academyRecordData={academicRecordData}
            languageData={languageData}
            professionalHistory={professionalHistory}
            brotherPosts={brotherPosts}
            postPageCount={postPageCount}
            postPage={postPage}
            infoBrother={infoBrother}
            modalEditState={modalEditState}
            isMyProfile={user?.idObreiro === infoBrother?.idBrother}
            loading={GetByIdBrotherPaged.loading || GetDetailsBrother.loading}
            infinityLoading={GetByIdBrotherNextPage.loading}
            setInfoBrother={setInfoBrother}
            getNextBrotherPosts={getNextBrotherPosts}
            setProfessionalHistory={setProfessionalHistory}
            setModalEditState={setModalEditState}
            handleTabChange={handleTabChange}
            setLanguageData={setLanguageData}
        />
    );
}

export default DetailBrother;