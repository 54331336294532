import SendIcon from '@mui/icons-material/EditRounded';
import { Box, Divider, Grid, Typography } from "@mui/material";

import { IProps } from "./Information.interface";
import { EditButton } from "../../../DetailBrother.style";
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { IconTextBox, InfosText, PageContainer } from "./Information.style";

function InformationView(props: IProps) {
    const { infoBrother, isMyProfile, setModalEditState } = props;

    return (
        <PageContainer>
            {isMyProfile && (
                <Grid item display="flex" alignItems="center" justifyContent="end">
                    <EditButton
                        variant="outlined"
                        startIcon={<SendIcon />}
                        onClick={() => setModalEditState(true)}
                    >
                        Editar Perfil
                    </EditButton>
                </Grid>
            )}

            <Grid container columnSpacing={6}>
                <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1" color="secondary" mt={2}>BIOGRAFIA PESSOAL</Typography>
                    <Box>
                        <InfosText variant="body1">
                            {infoBrother.personalBiography || "Esse obreiro não possui biografia pessoal."}
                        </InfosText>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1" color="secondary" mt={2}>BIOGRAFIA MAÇONICA</Typography>
                    <Box>
                        <InfosText variant="body1">
                            {infoBrother.masonicBiography || "Esse obreiro não possui biografia maçonica."}
                        </InfosText>
                    </Box>
                </Grid>

                <Grid item xs={12} mt={2}><Divider /></Grid>

                <Grid item xs={12} sm={12} md={6}>
                    <Typography variant="subtitle1" color="secondary" mt={2}>DATAS</Typography>
                    <Box width="100%">
                        <InfosText variant="body1">
                            {"Nascimento: " + infoBrother.bornDate || "Esse obreiro não possui uma data de nascimento cadastrada."}
                        </InfosText>

                        <InfosText variant="body1">
                            {"Iniciação: " + infoBrother.initiationDate || "Esse obreiro não possui uma data de iniciação cadastrada."}
                        </InfosText>

                        <InfosText variant="body1">
                            {"Elevação: " + infoBrother.elevationDate || "Esse obreiro não possui uma data de elevação cadastrada."}
                        </InfosText>

                        <InfosText variant="body1">
                            {"Exaltação: " + infoBrother.exaltationDate || "Esse obreiro não possui uma data de exxaltação cadastrada."}
                        </InfosText>

                        <InfosText variant="body1">
                            {"Instalação: " + infoBrother.instalationDate || "Esse obreiro não possui uma data de instalação cadastrada."}
                        </InfosText>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                    <Typography variant="subtitle1" color="secondary" mt={2}>CONTATO</Typography>
                    <Box width="100%">
                        <IconTextBox>
                            <ContactPhoneIcon style={{ marginRight: 6 }} />
                            <InfosText variant="body1">
                                {infoBrother.phone || "Esse obreiro não possui telefone para contato."}
                            </InfosText>
                        </IconTextBox>

                        <IconTextBox>
                            <ContactMailIcon style={{ marginRight: 6 }} />
                            <InfosText variant="body1">
                                {infoBrother.email || "Esse obreiro não possui email para contato."}
                            </InfosText>
                        </IconTextBox>
                    </Box>
                </Grid>
            </Grid>
        </PageContainer>
    );
}

export default InformationView;