import { useContext } from 'react';
import { useHistory } from '../../hooks';
import { differenceInYears, format, parse } from 'date-fns';
import { ModulesContext } from '../../context/modules/Modules';
import BrotherRedirectCardView from './BrotherRedirectCard.view';
import { IBrotherRedirectCard } from './BrotherRedirectCard.interface';
import { IModulesContext } from '../../context/modules/Modules.interface';

function BrotherRedirectCard(props: IBrotherRedirectCard) {
    const { brother, type, hasContactInformation, avatarSize, screenName } = props;

    const history = useHistory();

    const { Mask, Analytics } = useContext<IModulesContext>(ModulesContext);

    function goToBrotherCreatorPage() {
        history.redirectTo(`/detailBrother/${brother?.idObreiro}`);
        Analytics.logScreenAction(screenName, 'Redirecionamento para o irmão criador')
    }

    function getBrotherAge(birthDate: number | Date) {
        if (!birthDate) return '';
        const formatedDate = format(new Date(birthDate), "dd'/'MM'/'yyyy");
        const date = parse(formatedDate, "dd/MM/yyyy", new Date());
        const age = differenceInYears(new Date(), date);
        return age.toString();
    }

    return (
        <BrotherRedirectCardView
            type={type}
            goToBrotherCreatorPage={goToBrotherCreatorPage}
            avatarSize={avatarSize}
            hasContactInformation={hasContactInformation}
            getBrotherAge={getBrotherAge}
            mask={Mask}
            brother={brother}
        />
    );
}

export default BrotherRedirectCard;