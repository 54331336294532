import { useContext } from "react";
import { BrowserRouter } from "react-router-dom";

import { useAuth } from "./hooks";
import Routes from './routes/Routes';
import { ModulesContext } from "./context/modules/Modules";
import { IModulesContext } from "./context/modules/Modules.interface";

function App() {
    const { getToken } = useAuth();
    const { RestApi, Firebase } = useContext<IModulesContext>(ModulesContext);
    RestApi.setToken(getToken());
    Firebase.initFirebase();

    return (
        <BrowserRouter>
            <Routes />
        </BrowserRouter>
    );
}

export default App;