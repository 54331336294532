import { ListItem, styled } from "@mui/material";

export const Logo = styled('img')(() => ({
    height: "40px",
    cursor: "pointer",
    marginLeft: "-48px"
}));

export const MenuSection = styled(ListItem)(() => ({
    minWidth: '200px',
    width: '100%',
    'a': { color: '#ffffff !important' }
}));