import { IMailCard } from './MailCard.interface';

import MailCardView from './MailCard.view';

function MailCard(props: IMailCard) {
    const { email, message, name, onSendClick, handleFormChange } = props;

    return (
        <MailCardView 
            name={name}
            email={email}
            message={message}
            onSendClick={onSendClick}
            handleFormChange={handleFormChange}
        />
    )
}

export default MailCard;