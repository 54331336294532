import { AppBar, Box, Button, styled } from "@mui/material";

import { IAppBar } from "./Header.interface";

export const AppBarStyled = styled(AppBar, {shouldForwardProp: (headerBackground => headerBackground !== "headerBackground")})<IAppBar>(({headerBackground}) => ({
    backgroundColor: headerBackground ? "rgba(135, 168, 195, 0.99)" : "transparent", 
    paddingTop: 8,
    paddingBottom: 8,
    zIndex: 10,
    transition: 'all 360ms linear'
}));

export const HeaderButtonBox = styled(Box)(({ theme }) => ({
    flexGrow: 0, 
    display: 'flex', 
    [theme.breakpoints.down(830)]: {
        display: "none"
    }
}));

export const ImageLogo = styled('img')(({ theme }) => ({
    [theme.breakpoints.down(830)]: {
        width: 72,
        height: 72
    }
}));

export const HeaderButtons = styled(Button)(({ theme }) => ({
    marginLeft: 4,
    marginRight: 4,
    color: 'white', 
    display: 'block',
    backgroundColor: "transparent",
    [theme.breakpoints.down(900)]: {
        padding: 6
    }
}));

export const ResponsiveMenuBox = styled(Box)(({ theme }) => ({
    flexGrow: 1, 
    display: 'none', 
    alignItems: "end", 
    justifyContent: "right",
    [theme.breakpoints.down(830)]: {
        display: "flex"
    }
}));

export const DownloadButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    color: "white",
    alignSelf: "center",
    borderTopLeftRadius: 52,
    borderTopRightRadius: 52,
    borderBottomRightRadius: 52,
    marginLeft: 4,
    marginRight: 4,
    boxShadow: "4px 4px 4px 2px rgba(0, 0, 0, 0.2)",
    height: "100%",
    ':hover': {
        backgroundColor: theme.palette.primary.main,
    }
}));