import { Box, Button, Divider, Paper, styled, Typography } from "@mui/material";

export const PaperStyled = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    boxShadow: `8px 8px 8px 8px ${theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.5)" : "rgba(0, 0, 0, 0.2)"}`,
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center",
    flexDirection: "column",
    paddingTop: 16,
    paddingBottom: 24,
    marginTop: 16
}));

export const FooterTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: 72,
    fontWeight: 700
}));

export const AboutBox = styled(Box)(() => ({
    marginBottom: 6,
    height: 48,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}));

export const ContactBox = styled(Box)(() => ({
    marginTop: 6,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 500
}));

export const AboutButton = styled(Button)(({ theme }) => ({
    color: theme.palette.mode === "dark" ? "white" : theme.palette.primary.main,
    "&:hover": {
        backgroundColor: 'transparent'
    }
}));

export const FooterDivider = styled(Divider)(() => ({
    marginLeft: 3,
    marginRight: 3,
    height: "70%",
    borderRightWidth: 2
}));

export const SocialIconButton = styled(Button)(({ theme }) => ({
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    padding: 4,
    marginRight: 6,
    marginLeft: 6,
    width: 48,
    minWidth: 48,
    color: "white",
    backgroundColor: theme.palette.primary.dark,
    boxShadow: "4px 4px 4px 2px rgba(0, 0, 0, 0.2)",
    ':hover': {
        backgroundColor: theme.palette.primary.main
    }
}));

export const GLMMG = styled('a')(({ theme }) => ({
    color: theme.palette.info.main,
    textDecoration: "none"
}));