import { useContext } from 'react';

import { SideBarText } from './components';
import { FratLogoBranca } from '../../../../assets';
import { MainLayoutContext } from '../../MainLayout';
import { IMainLayoutContext } from '../../MainLayout.interface';
import { Logo, SideBarContainer, SideBarIcon } from './SideBar.style';

function SideBar() {
    const { routesBar, redirectPage, actionsBar } = useContext<IMainLayoutContext>(MainLayoutContext);

    return (
        <SideBarContainer>
            <SideBarIcon>
                <Logo src={FratLogoBranca} alt="Logo Frat" onClick={() => redirectPage('home')} />
            </SideBarIcon>
            {routesBar.map(({ Label, Key, Icon }, index) => (
                <SideBarText key={index} text={Label} Icon={Icon} onClick={() => redirectPage(Key)} />
            ))}
            {actionsBar.map(({ Label, Action, Icon }, index) => (
                <SideBarText key={index} text={Label} Icon={Icon} onClick={Action} />
            ))}
        </SideBarContainer>
    );
}

export default SideBar;