import { LoadingButton } from "@mui/lab";
import { Container, Box, TextField, styled, Grid } from "@mui/material";

export const BoxStyled = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    overflowX: 'hidden',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#2B374F',
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.down("sm")]: {
        paddingLeft: 30,
        paddingRight: 30,
    },
}));

export const ContainerStyled = styled(Container)(({ theme }) => ({
    padding: 10,
    width: '100%',
    display: 'flex',
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff'
}));

export const TextFieldStyled = styled(TextField)(() => ({
    marginBottom: 10,
    '& label.Mui-focused': {
        color: '#2B374F',
    },
    '& .MuiOutlinedInput-root': {
        '& input': {
            color: 'rgba(0, 0, 0, 0.5)',
        },

        '& fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
        },
        '&:hover fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.5)',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.5)',
        },
    },
}));

export const ButtonStyled = styled(LoadingButton)(() => ({
    width: '50%',
    marginTop: '10px',
    backgroundColor: '#2B374F'
}));

export const InputStyle = {
    style: {
        color: '#2B374F'
    }
};

export const Image = styled('img')(() => ({
    height: '100%',
    maxWidth: '190px'
}));

export const GridLeft = styled(Grid)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    [theme.breakpoints.down("sm")]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}));

export const GridRight = styled(Grid)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    [theme.breakpoints.down("sm")]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}));