import { useContext, useEffect } from "react";
import { useHistory } from "../../hooks";
import PageNotFoundView from "./PageNotFound.view";
import { ModulesContext } from "../../context/modules/Modules";
import { IModulesContext } from "../../context/modules/Modules.interface";

function PageNotFound() {
    const navigation = useHistory();
    const { Analytics } = useContext<IModulesContext>(ModulesContext);

    function componentDidMount() {
        Analytics.logScreen('Página não encontrada');
    }
    useEffect(componentDidMount, [])

    function goBackHome() {
        navigation.redirectTo('/home');
    }

    return (
        <PageNotFoundView goBackHome={goBackHome} />
    )
}

export default PageNotFound;