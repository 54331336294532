import RestAPIModule from './../../RestApi';
import { ICompanyDTO, IResponse } from '../../../../model';
import { ICompanyGetById, ICompanySearch, ICompanySearchResponse } from './Company.interface';

export default class CompanyRequest {
    public static URL_COMPANY = `${RestAPIModule.URL}/Estabelecimentos`;
    public static URL_COMPANY_GET_BY_ID = `${CompanyRequest.URL_COMPANY}/GetById`;
    public static URL_COMPANY_SEARCH = `${CompanyRequest.URL_COMPANY}/SearchCompany`;

    public static GetById(params: ICompanyGetById): Promise<IResponse<ICompanyDTO>> {
        return RestAPIModule.httpGet(`${CompanyRequest.URL_COMPANY_GET_BY_ID}?idEstabelecimento=${params.idEstabelecimento}&latitude=${params.latitude}&longitude=${params.longitude}`).then((response) => response);
    }

    public static SearchCompany(params: ICompanySearch): Promise<IResponse<ICompanySearchResponse>> {
        return RestAPIModule.httpGet(`${CompanyRequest.URL_COMPANY_SEARCH}?${params.idCategoria && `idCategoria=${params.idCategoria}`}&searchTerm=${params.searchTerm}&${params.covenant != null && `covenant=${params.covenant}&`}page=${params.page}&amount=${params.amount}`).then((response) => response);
    }
}