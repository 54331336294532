import { Box, Button, Card, Link, styled, Typography } from "@mui/material";

export const SectionCard = styled(Card)(() => ({
    borderRadius: 6, 
    marginBottom: 24
}));

export const Company = styled(Link)(() => ({
    textDecoration: "none",
    cursor: "pointer",
}));

export const InformationBox = styled(Box)(() => ({
    marginTop: 6,
    marginBottom: 18,
}));

export const Oriente = styled(Typography)(() => ({
    fontWeight: 'lighter',
}));

export const IconLabelBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    marginTop: 6,
    marginBottom: 6,
}));

export const ApplyButton = styled(Button)(({theme}) => ({
    borderRadius: 16,
    marginTop: 10,
    backgroundColor: theme.palette.primary.main
}));

export const OpenText = styled(Typography)(() => ({
    color: "#66bb6a",
    paddingRight: 2
}));

export const CloseText = styled(Typography)(() => ({
    color: "#f44336",
    paddingRight: 2
}));

export const SituationBox = styled(Box)(() => ({
    alignItems: "center", 
    display: "flex", 
    paddingLeft: 10
}));

export const Ball = styled('div')(({theme}) => ({
    width: '2px',
    height: '2px',
    borderRadius: 4,
    marginTop: 3,
    marginLeft: 8,
    marginRight: 8,
    display: "block",
    backgroundColor: theme.palette.mode == 'dark' ? '#FFFFFF' : '#000000',
    [theme.breakpoints.down("sm")]: {
        display: "none"
    }
}));