import { useContext } from 'react';

import { format } from 'date-fns';
import {
    CardContent, CardHeader,
    Typography, CardMedia
} from '@mui/material';

import { IPostCard } from './PostCard.interface';
import { CardStyled, AvatarStyled, cursorPointer } from './PostCard.style';

import { IModulesContext } from '../../../context/modules/Modules.interface';
import { ModulesContext } from '../../../context/modules/Modules';

function PostCard(props: IPostCard) {
    const { post,  variant, redirectToDetailBrother, redirectToDetailPost } = props;

    const modules = useContext<IModulesContext>(ModulesContext);

    return (
        <CardStyled variant={variant}>
            <CardHeader
                sx={cursorPointer}
                onClick={() => redirectToDetailBrother ? redirectToDetailBrother(post.idObreiroAutor) : null}
                avatar={post.fotoAutor != null ? (
                    <AvatarStyled aria-label="recipe" src={modules.Mask.formatBase64(post.fotoAutor)} />
                ) : (
                    <AvatarStyled aria-label="recipe">
                        {post.nomeAutor ? post?.nomeAutor[0] : ""}
                    </AvatarStyled>
                )}
                title={post.nomeAutor}
                subheader={`${format(new Date(post.dataCriacao), 'dd/MM/yyyy')}`}
            />

            {post?.banner &&
                <CardMedia
                    sx={cursorPointer}
                    onClick={() => redirectToDetailPost ? redirectToDetailPost(post.idPublicacao) : null}
                    height="250"
                    component="img"
                    image={modules.Mask.formatBase64(post.banner)}
                    alt={`${post.nomeAutor} - ${post.titulo}`}
                />
            }

            <CardContent
                sx={cursorPointer}
                onClick={() => redirectToDetailPost ? redirectToDetailPost(post.idPublicacao) : null}
            >
                <Typography variant="body2" color="text.secondary">
                    {post.resumo}
                </Typography>
            </CardContent>

        </CardStyled>
    )
}

export default PostCard;