import { createContext, useContext, useReducer } from 'react';

import contextTypes from './contextTypes';
import { useLocalStorage } from '../../hooks';
import {
    IProps,
    IStateReducer,
    IActionReducer,
    IGlobalContext
} from './Global.interface';
import { IBrotherDTO } from '../../model';

const GlobalContext = createContext({} as IGlobalContext);

function GlobalProvider(props: IProps) {
    const authToken = useLocalStorage<string>('authToken');
    const currentUser = useLocalStorage<IBrotherDTO>('currentUser');

    const [state, dispatch] = useReducer(reducer, {isAuthenticated: !!authToken.value, token: authToken.value, user: currentUser.value});

    function reducer(state: IStateReducer, action: IActionReducer) {
        switch (action.type) {
            case contextTypes.SIGN_IN:
                const {token} = action.payload;
                authToken.setValue(token);
                if (action.payload.user) currentUser.setValue(action.payload.user);
                return {...state, token, isAuthenticated: true, user: action.payload.user};
            case contextTypes.SIGN_OUT:
                authToken.remove();
                currentUser.remove();
                return {...state, token: '', isAuthenticated: false, user: null};
            case contextTypes.SET_USER:
                const {user} = action.payload;
                if (user) currentUser.setValue(user);
                return {...state, user: user};
            default:
                throw new Error();
        }
    }

    return (
        <GlobalContext.Provider value={{state, dispatch}}>
            {props.children}
        </GlobalContext.Provider>
    );
}

function useGlobalState() {
    const {state} = useContext(GlobalContext);
    return state;
}

function useGlobalDispatch() {
    const {dispatch} = useContext(GlobalContext);
    return dispatch;
}

export { GlobalContext, GlobalProvider, useGlobalState, useGlobalDispatch };