import React from 'react';
import { Box } from '@mui/material';

import { ICardHeader } from './CardHeader.interface';
import { Header, Title, SubTitle, Ball, SectionDivider } from './CardHeader.style';

function CardHeader(props: ICardHeader) {
    const { title, subTitle, options } = props;

    return (
        <Header>
            {subTitle && (
                <SubTitle>{subTitle}</SubTitle>
            )}

            <Title variant="h1"> {title} </Title>

            {options && options.length >= 1 && (
                <Box display={{ xs: "block", sm: "flex" }} alignItems="center">
                    {options.map((component, idx) => (
                        <React.Fragment key={idx}>
                            {component}
                            {(idx !== options.length - 1) && <Ball />}
                        </React.Fragment>
                    ))}
                </Box>
            )}

            <SectionDivider />
        </Header>
    )
}

export default CardHeader;