import { useEffect, useRef } from 'react';

import { IInfinityScroll } from './InfinityScroll.interface';

import { Loading, LoadingContainer } from './InfinityScroll.style';

function InfinityScroll(params: IInfinityScroll) {
    const { callBack } = params;

    const containerRef = useRef<any>();

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0
        }

        const observer = new IntersectionObserver(([entry]) => {
            if(entry.isIntersecting) {
                if(containerRef.current){
                    observer?.disconnect();
                }
                callBack();
            }
        }, options);
        
        if(containerRef.current){
            observer.observe(containerRef.current);
        }

        return () => {
            observer.disconnect();
        }
    }, [])

    return (
        <LoadingContainer>
            <Loading ref={containerRef}/>
        </LoadingContainer>
    )
}

export default InfinityScroll