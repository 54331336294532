import { Card, Avatar, styled } from "@mui/material";

export const CardStyled = styled(Card)(() => ({
    borderRadius: 6,
    marginBottom: 24,
    width: '100%',
}));

export const AvatarStyled = styled(Avatar)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'row'
}));

export const cursorPointer = {
    cursor: 'pointer',
}
