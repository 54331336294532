import { initializeApp } from "firebase/app";
import { Analytics, getAnalytics } from "firebase/analytics";

export default class Firebase {
    static analytics: Analytics;

    static firebaseConfig = {
        apiKey: "AIzaSyDdBMURX-kzyIgGta-PYnVrn5r03Vrryzc",
        authDomain: "frat-glmmg.firebaseapp.com",
        databaseURL: "https://frat-glmmg.firebaseio.com",
        projectId: "frat-glmmg",
        storageBucket: "frat-glmmg.appspot.com",
        messagingSenderId: "328071655734",
        appId: "1:328071655734:web:1629f6f932154844b2c397",
        measurementId: "G-7P1ZF4SMZX"
    };

    public analytics () {
        return Firebase.analytics;
    }

    public initFirebase () {
        const app = initializeApp(Firebase.firebaseConfig);
        Firebase.analytics = getAnalytics(app);
    }
}