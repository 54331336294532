import { Box, Grid } from '@mui/material';
import { Outlet } from "react-router-dom";

import { SideBar, TopBar } from './components';
import { BoxStyled, smUp, xsDown } from './MainLayout.style';

function MainLayoutView() {
    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box sx={smUp}>
                    <TopBar />
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" justifyContent="center">
                <Box sx={smUp}>
                    <BoxStyled maxWidth="md">
                        <Outlet />
                    </BoxStyled>
                </Box>
            </Grid>

            <Grid item xs={2} sm={3.5} md={3} lg={3} xl={2.5}
                display="flex"
                alignItems="flex-start"
                justifyContent={{ xs: "center", md: "left", lg: "left", sm: "center" }}
                width={{ xs: "50px" }}
                paddingLeft={{ sm: "50px", xs: "0px" }}
            >
                <Box sx={xsDown}>
                    <SideBar />
                </Box>
            </Grid>
            <Grid item xs={10} sm={7.5} md={9} lg={9} xl={9.5}>
                <Box sx={xsDown}>
                    <BoxStyled maxWidth="md">
                        <Outlet />
                    </BoxStyled>
                </Box>
            </Grid>
        </Grid>
    );
}

export default MainLayoutView;