import { IProps } from "./DeepLinkModal.interface";
import DeepLinkModalView from "./DeepLinkModal.view";

function DeepLinkModal(props: IProps) {
    const { visible, linkOpenApp, linkDownloadAppleStore, showAppleStoreButton, onClick, onClickClose } = props;

    return (
        <DeepLinkModalView
            visible={visible}
            linkOpenApp={linkOpenApp}
            linkDownloadAppleStore={linkDownloadAppleStore}
            showAppleStoreButton={showAppleStoreButton}
            onClick={onClick}
            onClickClose={onClickClose}
        />
    )
}

export default DeepLinkModal;