import { Box, Container, Divider, styled, Typography } from '@mui/material';

import { FratBackground } from '../../assets';

export const ContainerStyled = styled(Container)(() => ({
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 0,
    paddingRight: 0,
    padding: 16
}));

export const StyledBox = styled(Box)(() => ({
    zIndex: 100,
    minHeight: '100vh',
    width: "100%",
    backgroundImage: `url(${FratBackground})`,
    position: "relative",
    backgroundPosition: "bottom center",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
}));

export const Section = styled('div')(() => ({
    marginTop: 16,
    marginBottom: 16
}));

export const DefaultTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.dark,
    fontWeight: 700,
    fontSize: 36,
    marginTop: 16,
    marginBottom: 16,
    [theme.breakpoints.down("md")]: {
        fontSize: 30
    }
}));

export const ScrollToTop = styled(Box)(({ theme }) => ({
    display: "flex",
    position: 'fixed', 
    bottom: 52, 
    right: 52,
    [theme.breakpoints.down("md")]: {
        bottom: 24, 
        right: 24,
    },
    [theme.breakpoints.down("sm")]: {
        bottom: 12, 
        right: 12,
    },
    [theme.breakpoints.down(360)]: {
        display: "none"
    }
}));

export const SectionDivider = styled(Divider)(() => ({
    width: "25%",
    marginBottom: 24
}));

export const LandingPageVideo = styled('source')(({ theme }) => ({
    width: "100%",
    height: "500px",
    border: "10px solid", 
    borderRadius: 20,
    [theme.breakpoints.down("md")]: {
        height: "300px"
    }
}));

export const VideoBox = styled('video')(() => ({
    width: "100%",
    height: "100%",
    border: "10px solid", 
    marginTop: "15px",
    marginBottom: "15px",
    borderRadius: 20
}));