import { Box, Grid } from "@mui/material";
import {
    Apple as AppleIcon,
    Android as AndroidIcon
} from '@mui/icons-material/';

import { MobileFrat } from "../../../../assets";
import { IBannerViewProps } from "./Banner.interface";

import { CellPhoneImg, ContentContainer, ContentGrid, HelperText, SocialIcons, Subtitle, Title } from "./Banner.style";

function BannerView(props: IBannerViewProps) {
    const { isAuthenticated, redirect } = props;

    return (
        <ContentContainer>
            <Grid container spacing={2}>
                <ContentGrid item sm={12} md={6}>
                    <Title>Frat APP</Title>
                    <Subtitle>Mais um benefício para você irmão da GLMMG!</Subtitle>

                    <Box display="flex" alignItems="center" mt={2} mb={2}>
                        <Subtitle sx={{textDecoration: "none", cursor: "inherit", fontSize: 18}}>Faça já o download do FRAT: </Subtitle>

                        <Box display={{xs: "none", sm: "flex"}} alignItems="center">
                            <a href='https://apps.apple.com/br/app/frat/id1577022060' target="_blank">
                                <SocialIcons>
                                    <AppleIcon/>
                                </SocialIcons>
                            </a>

                            <a href='https://play.google.com/store/apps/details?id=br.com.glmmg.frat&hl=pt_PT&gl=US' target="_blank">
                                <SocialIcons>
                                    <AndroidIcon/>
                                </SocialIcons>
                            </a>
                        </Box>
                    </Box>

                    <Box display={{xs: "flex", sm: "none"}} alignItems="center" justifyContent="center">
                        <a href='https://apps.apple.com/br/app/frat/id1577022060' target="_blank">
                            <SocialIcons>
                                <AppleIcon fontSize="large"/>
                            </SocialIcons>
                        </a>

                        <a href='https://play.google.com/store/apps/details?id=br.com.glmmg.frat&hl=pt_PT&gl=US' target="_blank">
                            <SocialIcons>
                                <AndroidIcon fontSize="large"/>
                            </SocialIcons>
                        </a>
                    </Box>

                    <Box display="flex"  alignItems="center" mt={2}>
                        <HelperText onClick={redirect}>{!isAuthenticated && "JÁ É MEMBRO DO FRAT? FAÇA LOGIN"}</HelperText>
                    </Box>
                </ContentGrid>
                
                <ContentGrid item sm={12} md={6}>
                    <CellPhoneImg src={MobileFrat} />
                </ContentGrid>
            </Grid>
        </ContentContainer>
    )
}

export default BannerView;
