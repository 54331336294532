import { Facebook, Google, Instagram, Twitter } from '@mui/icons-material';
import { Box, Typography, Grid } from '@mui/material';

import { AboutBox, ContactBox, AboutButton, FooterTitle, GLMMG, PaperStyled, SocialIconButton } from './Footer.style';

export default function FooterView() {
    return (
        <PaperStyled elevation={24}>
            <FooterTitle>
                FRAT
            </FooterTitle>

            <Box display="flex" justifyContent="center" alignItems="center">
                <a href='https://www.facebook.com/glmmg/' target="_blank" rel="noreferrer">
                    <SocialIconButton variant="contained">
                        <Facebook />
                    </SocialIconButton>
                </a>

                <a href="https://www.instagram.com/glmmg.oficial/" target="_blank" rel="noreferrer">
                    <SocialIconButton variant="contained">
                        <Instagram />
                    </SocialIconButton>
                </a>
            </Box>

            <ContactBox>
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={12} md={12} justifyContent="center" display="flex">
                        <span>
                            Av. Brasil, 478 - Santa Efigênia - BH/MG
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} justifyContent="center" display="flex">
                        <span>
                            +55 (31) 3218-1400
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} justifyContent="center" display="flex">
                        <span>
                            app@frat.com.br
                        </span>
                    </Grid>
                </Grid>
            </ContactBox>

            <AboutBox>
                <AboutButton variant='text' onClick={() => window.open("http://www.frat.com.br/termosdeuso.html", "_blank")}>
                    Termos & Condições
                </AboutButton>
            </AboutBox>

            <Box>
                <Typography>Copyright ©2019 Designed by <GLMMG href='https://www.glmmg.org.br/'>GLMMG</GLMMG></Typography>
            </Box>
        </PaperStyled >
    )
}
