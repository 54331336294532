import { Button, styled, Typography } from "@mui/material";

export const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: 18
}));

export const SearchButton = styled(Button)(() => ({
    padding: 8, 
}));

export const TextNoData = styled('div')(() => ({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: 'center'
}));