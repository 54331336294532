import { useContext } from 'react';
import { Avatar, Box } from '@mui/material';

import { ModulesContext } from '../../../context/modules/Modules';
import { IGenericCardListProps } from './GenericCardList.interface';
import { IModulesContext } from '../../../context/modules/Modules.interface';
import { GenericCardListStyled, GenericCardListHeaderStyled } from './GenericCardList.style';

function GenericCardList(props: IGenericCardListProps) {
    const { Mask } = useContext<IModulesContext>(ModulesContext);
    const { goToPage, title, children, avatarUrl, showAvatar = false } = props;

    return (
        <GenericCardListStyled variant="outlined">
            <GenericCardListHeaderStyled
                onClick={goToPage}
                title={Mask.redutorString(title, 24)}
                titleTypographyProps={{ variant: "h3" }}
                subheader={
                    <Box marginTop={1}>
                        {showAvatar ? (
                            <div style={{display: "flex", alignItems: "center",}}>
                                <Avatar src={Mask.formatBase64(avatarUrl)}/>
                                <div style={{marginLeft: 12}}>
                                    {children}
                                </div>
                            </div>
                        ) : (
                            children
                        )}
                    </Box>
                }
            />
        </GenericCardListStyled>
    );
}

export default GenericCardList;