import { Autocomplete, CircularProgress, Grid, IconButton, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import { CardStyled, InfinityScroll, Loading } from '../../components';
import { IDegreeOfMeetingSelectList, IListEventView } from './ListEvent.interface';
import { EventListCard } from './components';
import { IEventDTO } from '../../model';

import { SearchButton, TextNoData, Title } from './ListEvent.style';

function ListEventView(props: IListEventView) {
    const {
        searchTerm,
        pageCount,
        page,
        eventList,
        degreeOfMeetingList,
        degreeOfMeetingSelected,
        eventDate,
        loading,
        infinityLoading,
        setEventDate,
        setSearchTerm,
        getNextPage,
        setDegreeOfMeetingSelected
    } = props;

    function renderCompanies() {
        if (eventList.length >= 1) {
            return eventList.map((event: IEventDTO, idx: number) => (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={idx}>
                    <EventListCard event={event} />
                </Grid>
            ));
        } else {
            return (
                <Grid item xs={12} sm={12} md={12} lg={12} mt={2} display="flex" alignItems="center" justifyContent="center">
                    <TextNoData>
                        Nenhum evento encontrado.
                    </TextNoData>
                </Grid>
            );
        }
    }

    return (
        <CardStyled>
            <Loading open={loading} />
            <Title variant="h3">Eventos</Title>

            <Grid container spacing={2} mt={1} mb={2} display="flex" alignItems="center">
                <Grid item xs={12} lg={6} sm={6} md={6} xl={6}>
                    <TextField
                        value={searchTerm}
                        fullWidth
                        size={"small"}
                        label="Buscar eventos"
                        id="search-events"
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </Grid>


                <Grid item xs={12} lg={6} sm={6} md={6} xl={6}>
                    <Autocomplete
                        size={"small"}
                        id="select-category"
                        noOptionsText={''}
                        freeSolo
                        options={degreeOfMeetingList}
                        value={degreeOfMeetingSelected}
                        onChange={(event, newValue: IDegreeOfMeetingSelectList | string | null) => {
                            if (typeof newValue == "string") return;
                            setDegreeOfMeetingSelected(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} label="Selecionar Categorias" />}
                    />
                </Grid>

                <Grid item xs={12} lg={6} sm={6} md={6} xl={6}>
                    <DatePicker
                        label="Data de início"
                        inputFormat="dd/MM/yyyy"
                        value={eventDate}
                        InputProps={{
                            endAdornment: (
                                eventDate && (
                                    <IconButton onClick={() => setEventDate(null)}>
                                        <ClearIcon fontSize={"medium"} />
                                    </IconButton>
                                )

                            )
                        }}
                        InputAdornmentProps={{
                            position: "start"
                        }}
                        onChange={(newValue: Date | string | null) => {
                            setEventDate(newValue || "");
                        }}
                        renderInput={(params: any) => <TextField fullWidth size={"small"} {...params} />}
                    />
                </Grid>

                <Grid item xs={12} lg={6} sm={6} md={6} xl={6}>
                    <SearchButton
                        fullWidth
                        variant="contained"
                        size={'small'}
                        disabled={infinityLoading}
                        onClick={(e) => getNextPage(1)}
                    >
                        {infinityLoading ? (
                            <CircularProgress style={{color: "white"}} size={24}/>
                        ) : (
                            <SearchIcon />
                        )}
                    </SearchButton>
                </Grid>
            </Grid>

            <Grid container spacing={1}>
                {renderCompanies()}
            </Grid>

            {eventList && (page < pageCount) && !infinityLoading && (
                <InfinityScroll callBack={getNextPage} />
            )}
        </CardStyled>
    );
}

export default ListEventView;