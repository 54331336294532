import { Box, styled } from "@mui/material";

export const Logo = styled('img')(({theme}) => ({
    height: "40px",
    cursor: "pointer",
    marginTop: 6, 
    marginRight: 4,
    [theme.breakpoints.down("sm")]: {
        marginRight: 0,
    }
}));

export const NormalItemsList = styled(Box)(() => ({
    width: "100%",
    display: 'flex', 
    alignItems: 'center',
    justifyContent: 'flex-end'
}));

export const SideBarIcon = styled(Box)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: 'center',
    cursor: "pointer",
    padding: "10px",
    marginBottom: "10px",
    borderRadius: "30px",
    [theme.breakpoints.down("md")]: {
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "0px",
        paddingRight: "0px",
        justifyContent: "center",
    }
}));

export const SideBarContainer = styled(Box)(({theme}) => ({
    paddingTop: "20px",
    paddingBottom: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    position: "fixed",
    marginTop: "20px",
    marginRight: "auto",
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "0px",
        paddingRight: "0px",
    },
}));