import React from 'react';
import { ISideBarTextView } from './SideBarText.interface';


import { LinkText, Text } from './SideBarText.style';

function SideBarText(props: ISideBarTextView) {
    const { Icon, text, onClick } = props;
    
    return (
        <LinkText onClick={onClick}>
            <Icon />
            <Text variant="h4">
                {text}
            </Text>
        </LinkText>
    )
}

export default SideBarText;