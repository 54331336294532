export default class Mask {
    public MASK = {
        UF: "##",
        HOUR: "##:##",
        DATE: "##/##/####",
        DATE_HOUR: "##/##/#### ##:##",
        PHONE: "(##) ####-####",
        CELLPHONE: "(##) #####-####",
        RG: "##.###.###",
        CPF: "###.###.###-##",
        CNPJ: "##.###.###/####-##",
        POSTAL_CODE: "#####-###",
        PERCENTAGE: "##.##",
        CARD: "#### #### #### ####",
        CARD_EXPIRATION_1: "##/####",
        CARD_EXPIRATION_2: "##/##",
        CVV: "###",
        MONEY: "####,##"
    }

    /**
     * Removes the mask form the text
     * @param {String} text - Text that will be unmasked
     * @returns {String} Unmasked text
     */
    public unmask(text: string) {
        return text.replace(/\./g, "").replace(/-/g, "")
            .replace(/\//g, "").replace(/\(/g, "")
            .replace(/\)/g, "").replace(/:/g, "")
            .replace(/ /g, "").replace(/,/g, "");
    }

    /**
     * Apply a mask on the text
     * @param {String} mask - Mask to be applied
     * @param {String} text - Text to apply mask
     * @returns {String} Masked text
     */
    public applyMask(mask: any, text: string | undefined): string {
        if (!text) return '';
        if (mask && mask.length > 0 && text && text.length > 0) {
            text = this.unmask(text);
            let out = "";
            let i = 0;
            let j = 0;
            while (i < mask.length && j < text.length) {
                if (mask[i] === '#') {
                    out += text[j];
                    j++;
                } else {
                    out += mask[i];
                }
                i++;
            }
            return out;
        }
        return text;
    }

    /**
     * Apply a generic phone mask on the text
     * @param {String} text Text to apply mask
     * @returns {String} Masked text
     */
    public genericPhoneMask(text: string) {
        if (text.length <= this.MASK.PHONE.length) return this.applyMask(this.MASK.PHONE, text);
        else return this.applyMask(this.MASK.CELLPHONE, text);
    }

    /**
     * Apply a cpf or cnpj mask, depending on the length of the text
     * @param {String} text Text to apply mask
     * @returns {String} Masked text
     */
    public formatCpfCnpj(text: string) {
        if (text.length <= this.MASK.CPF.length) return this.applyMask(this.MASK.CPF, text);
        else return this.applyMask(this.MASK.CNPJ, text);
    }

    /**
     * Apply a decimal mask
     * @param {String} value Text to apply mask
     * @param {String} prefix Prefix of the decimal
     * @returns {String} Masked text
     */
    public formatDecimal(value: string, prefix = "") {
        if (typeof (value) !== "string") return "";
        value = this.unmaskDecimal(value, prefix);
        value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
        if (prefix) value = `${prefix} ${value}`;
        return `${value.substring(0, value.length - 3)},${value.substring(value.length - 2, value.length)}`;
    }

    /**
     * Remove decimal mask
     * @param {String} value Text to remove mask
     * @param {String} prefix Prefix of the decimal
     * @returns {String} Unmasked text
     */
    public unmaskDecimal(value: any, prefix = "") {
        value = this.unmask(value.replace(prefix, "").replace("^0+", ""));
        value = this.NaN0(Number(value)) / 100;
        return value.toFixed(2);
    }

    /**
      * Tests if is not a number, returning 0 if true and the original value if false.
     */
    public NaN0(value: any) {
        return isNaN(value) ? 0 : value;
    };

    /**
      * Safely parse JSON (no errors).
     */
    public parseJSON(data: any) {
        if (!data) return "";
        try {
            return JSON.parse(data);
        } catch (e) {
            return "";
        }
    }

    /**
      * Adds the header to the base64.
     */
    public formatBase64(base64: any, format = "png") {
        switch (format) {
            case 'png':
                return `data:image/${format};base64,${base64}`;
            case "mpeg":
            case "mp4":
            case "mp3":
            case "m4a":
                return `data:audio/${format};base64,${base64}`;
            default: return base64;
        }
    }

    /**
      * Splits the base64 into header and pure base64.
     */
    static splitBase64(str: string) {
        let [header, base64] = str.split(",");
        return { header, base64 };
    }

    static normalizeStr(str: string) {
        if (!str) return "";
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    /**
      * Escapes a string for insertion into HTML.
     */
    static escapeHtml(str: string) {
        return str.replace(/&/g, '&amp;').replace(/</g, '&lt;')
            .replace(/>/g, '&gt;').replace(/'/g, '&#39;')
            .replace(/"/g, '&quot;');
    }

    /**
      * Unescapes HTML special chars
     */
    static unescapeHtml(str: string) {
        return str.replace(/&amp;/g, '&').replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>').replace(/&#39;/g, "'")
            .replace(/&quot;/g, '"');
    }

    /**
      * Remove HTML tags from string.
     */
    static removeHtmlTags(str: string) {
        return str.replace(/<[^>]*>/g, '');
    }

    /**
      * Reverse a string.
     */
    static reverseString(str: string) {
        return str.split('').reverse().join('');
    }

    /**
      * Uppercase the first char of a string.
     */
    static upperFirstLetter(str: string) {
        return str.charAt(0).toUpperCase() + str.substr(1);
    }

    public redutorString(str: string, length: number) {
        if (!str) return;
        if (str.length > length - 1) {
            return str.substring(0, length - 1).concat('...');
        }
        return str;
    }
}