import { Button, Card, Divider, styled, TextField, Typography } from "@mui/material";

export const CardTextField = styled(TextField)(() => ({
    '& fieldset': {
        borderTopLeftRadius: 32,
        borderTopRightRadius: 52,
        borderBottomRightRadius: 52
    },
    marginBottom: 16
}));

export const SendButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    color: "white",
    borderTopLeftRadius: 52,
    borderTopRightRadius: 52,
    borderBottomRightRadius: 52,
    ':hover': {
        backgroundColor: theme.palette.primary.main,
    }
}));

export const EmailCard = styled(Card)(() => ({
    marginTop: 16, 
    marginBottom: 32, 
    borderRadius: "6px"
}));

export const InfoDivider = styled(Divider)(() => ({
    width: "40%",
    marginTop: 12,
    marginBottom: 12
}));

export const InfoTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.dark,
    fontWeight: 400,
    lineHeight: 1.1,
    fontSize: 36,
    [theme.breakpoints.down("md")]: {
        fontSize: 30
    }
}));