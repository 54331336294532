import { logEvent } from 'firebase/analytics';
import Firebase from '../firebase/Firebase';

export default class AnalyticsModule {

    public logLogin() {
        logEvent(Firebase.analytics, 'login');
    }

    public logScreen(screen: string) {
        logEvent(Firebase.analytics, screen);
    }

    public logScreenAction(screen: string, action: string) {
        logEvent(Firebase.analytics, `${screen} - Ação: ${action}`);
    }

}