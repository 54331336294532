import { LoadingButton } from "@mui/lab";
import { Autocomplete, Grid, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

import { IProps } from "./ListBook.interface";
import { IBookDTO } from "../../model/BookDTO.interface";
import { CardStyled, InfinityScroll, Loading } from "../../components";

import { TextNoData, Title } from "./ListBook.style";
import { BookCard } from "./components";

function ListBookView(props: IProps) {
    const {
        setBookSearchTerm,
        getNextPagedRequest,
        getBooksPaged,
        bookList,
        bookSearchTerm,
        page,
        infinityLoading,
        pageCount,
        loading
    } = props;

    function renderBooks() {
        if (bookList.length >= 1) {
            return (
                bookList.map((book: IBookDTO, idx: number) => {
                    return (
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={idx}>
                            <BookCard
                                book={book}
                            />
                        </Grid>
                    )
                })
            );
        } else {
            return (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextNoData>
                        Nenhum livro encontrado.
                    </TextNoData>
                </Grid>
            );
        }
    }

    return (
        <CardStyled>
            <Loading open={loading} />
            <Title variant="h3">Livros</Title>

            <Grid container spacing={2} mt={1} mb={2} display="flex" alignItems="center">
                <Grid item sm={10} md={10} lg={10} xl={10} xs={12} >
                    <TextField
                        value={bookSearchTerm}
                        fullWidth
                        size="small"
                        label="Buscar livros"
                        id="search-books"
                        onChange={(e) => setBookSearchTerm(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12} sm={2} md={2} lg={2} xl={2} >
                    <LoadingButton
                        variant="contained"
                        fullWidth
                        loading={loading}
                        disabled={loading}
                        onClick={getBooksPaged}

                    >
                        <SearchIcon />
                    </LoadingButton>
                </Grid>
            </Grid>

            {!loading &&
                <Grid container spacing={1}>
                    {renderBooks()}
                </Grid>
            }

            {bookList && (page < pageCount) && !infinityLoading && (
                <InfinityScroll callBack={getNextPagedRequest} />
            )}
        </CardStyled>
    );
}

export default ListBookView;