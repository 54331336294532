import React from 'react';

import FooterView from './Footer.view';

function Footer() {
    return (
        <FooterView />
    )
}

export default Footer;