import { useState, createContext } from 'react';
import { Snackbar, SnackbarProps } from '@mui/material/';
import { IProps, ISnackbarContext } from './Snackbar.interface';

const SnackbarContext = createContext({} as ISnackbarContext);

function SnackbarProvider(props: IProps) {
    const {children} = props;

    const [content, setContent] = useState<JSX.Element | undefined>(undefined);
    const [snackbarData, setSnackbarData] = useState<SnackbarProps>({
        open: false, message: '', onClose: () => {
        }
    });

    const close = () => setSnackbarData(prevData => ({...prevData, open: false}));
    const open = (content: JSX.Element | undefined, data: SnackbarProps) => {
        setContent(content);
        setSnackbarData({...data, open: true, onClose: close})
    };

    return (
        <SnackbarContext.Provider value={{open, close}}>
            {children}
            <Snackbar {...snackbarData}>{content}</Snackbar>
        </SnackbarContext.Provider>
    );
}

export { SnackbarContext, SnackbarProvider };