import { Typography } from "@mui/material";
import { GenericCardList } from "../../../../components";
import { IBookCardViewProps } from "./BookCard.interface";

function BookCardView(props: IBookCardViewProps) {
    const { title, author, publisher, redirectToDetailBook } = props;

    return (
        <GenericCardList title={title} goToPage={redirectToDetailBook}>
            <Typography variant="body2"> Autor(a): {author} </Typography>
            <Typography variant="body2"> Editora: {publisher} </Typography>
        </GenericCardList>
    );
}

export default BookCardView;