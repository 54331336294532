import { useContext, useMemo } from 'react';
import Alert from '@mui/material/Alert';
import { Close as CloseIcon } from '@mui/icons-material/';
import { IconButton, SnackbarProps } from '@mui/material/';
import { SnackbarContext } from '../../context/snackbar/Snackbar';

import { IVariant, IVertical, IHorizontal, ISeverities } from './useSnackbar.interface';

const severities: ISeverities = {
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error'
};

function useSnackbar(
    variant: IVariant = 'filled',
    vertical: IVertical = 'bottom',
    horizontal: IHorizontal = 'center',
    autoHideDuration: number = 2500
) {
    const { open, close } = useContext(SnackbarContext);

    const snackbarData: SnackbarProps = useMemo(() => ({
        anchorOrigin: { vertical, horizontal },
        autoHideDuration
    }), [vertical, horizontal, autoHideDuration]);

    const snackbar = (message: string | JSX.Element) => ({
        neutral: () => open(undefined,
            {
                ...snackbarData,
                message,
                action: (
                    <IconButton size="small" aria-label="close" color="inherit" onClick={close}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                )
            }
        ),
        success: () => open(
            <Alert variant={variant} severity={severities.SUCCESS} onClose={close}>
                {message}
            </Alert>,
            snackbarData
        ),
        warning: () => open(
            <Alert variant={variant} severity={severities.WARNING} onClose={close}>
                {message}
            </Alert>,
            snackbarData
        ),
        info: () => open(
            <Alert variant={variant} severity={severities.INFO} onClose={close}>
                {message}
            </Alert>,
            snackbarData
        ),
        error: () => open(
            <Alert variant={variant} severity={severities.ERROR} onClose={close}>
                {message}
            </Alert>,
            snackbarData
        ),
    });

    return snackbar;
}

export default useSnackbar;