import React from 'react';
import {Backdrop, CircularProgress} from "@mui/material";
import {IProps} from "./Loading.interface";

function Loading(props: IProps) {

    return (
        <Backdrop {...props} >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

export default Loading;