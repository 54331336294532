import RestAPIModule from '../../RestApi';
import { IResponse } from '../../../../model';
import { IInstructionLevelDTO } from '../../../../model';

export default class InstructionLevelRequest {
    public static URL_INSTRUCTIONLEVEL = `${RestAPIModule.URL}/NivelInstrucao`;

    public static GetInstructionLevel(): Promise<IResponse<IInstructionLevelDTO[]>> {
        return RestAPIModule.httpGet(`${InstructionLevelRequest.URL_INSTRUCTIONLEVEL}/Listar`).then((response) => response);
    }
}