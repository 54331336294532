import RestAPIModule from './../../RestApi';
import { ICategoryDTO, IResponse } from '../../../../model';

export default class CategoryRequest {
    public static URL_CATEGORY = `${RestAPIModule.URL}/Categorias`;
    public static URL_CATEGORY_SEARCH = `${CategoryRequest.URL_CATEGORY}/Pesquisar`;

    public static Search(): Promise<IResponse<ICategoryDTO[]>> {
        return RestAPIModule.httpGet(`${CategoryRequest.URL_CATEGORY_SEARCH}`).then((response) => response);
    }
}

