import { useContext, useEffect, useState } from 'react';

import ListEventView from './ListEvent.view'
import { IDegreeMeetingDTO, IEventDTO } from '../../model';
import { useDoRequest, useHistory, useSnackbar } from '../../hooks';
import { IDegreeOfMeetingSelectList } from './ListEvent.interface';
import { IEventSearchWithPaginationInput } from '../../modules/api/endpoints/event/Event.interface';
import { format } from 'date-fns';
import { IModulesContext } from '../../context/modules/Modules.interface';
import { ModulesContext } from '../../context/modules/Modules';

function ListEvent() {
    const snackbar = useSnackbar();
    const history = useHistory();
    const { Analytics } = useContext<IModulesContext>(ModulesContext);

    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);
    const [amount] = useState<number>(8);
    const [searchTerm, setSearchTerm] = useState<string>("");

    const [degreeOfMeetingList, setDegreeOfMeetingList] = useState<IDegreeOfMeetingSelectList[]>([]);
    const [degreeOfMeetingSelected, setDegreeOfMeetingSelected] = useState<IDegreeOfMeetingSelectList | null>(null);

    const [eventDate, setEventDate] = useState<string | Date | null>(null);

    const [eventList, setEventList] = useState<IEventDTO[]>([]);

    const DegreeOfMeetingGetAll = useDoRequest((api) => api.DegreeOfMeeting.Get);

    const EventNextPageSearch = useDoRequest((api) => api.Event.SearchWithPagination);

    function componentDidMount() {
        Analytics.logScreen('Listagem de eventos');
        getDegreeOfMeeting();
        getNextPage(1);
    }
    useEffect(componentDidMount, []);

    function getDegreeOfMeeting() {
        DegreeOfMeetingGetAll.doRequest("").then((response) => {
            if(response?.statusExecucao && response?.dadosRetorno?.length) {
                const degreeOfMeetingList = response.dadosRetorno;
                let letFilteredList: IDegreeOfMeetingSelectList[] = [];

                degreeOfMeetingList.forEach((degree: IDegreeMeetingDTO) => {
                    letFilteredList.push({label: degree.tituloGrau, value: degree.idGrau});
                });

                setDegreeOfMeetingList(letFilteredList);
            } else {
                snackbar(response.mensagem || "Não foi possível buscar os dados dos graus de reunião.").error();
                history.redirectTo("/home");
            }
        }).catch((error) => {
            snackbar("Não foi possível buscar os dados dos graus de reunião.").error();
            history.redirectTo("/home");
        })
    }

    useEffect(() => {
        if (searchTerm === "") getNextPage();
    }, [searchTerm]);

    function getNextPage(initialPage?: number) {
        Analytics.logScreenAction('Listagem de eventos', 'Buscar por Evento');

        let pagedObj: IEventSearchWithPaginationInput = {
            strTermoPesquisa: searchTerm,
            page: initialPage ? initialPage : page + 1,
            amount: amount,
        };

        if(degreeOfMeetingSelected) {
            pagedObj.idGrauReuniao = degreeOfMeetingSelected.value;
        } 

        if(eventDate) {
            pagedObj.dataEvento = format(new Date(eventDate), 'MM-dd-yyyy');
        }

        EventNextPageSearch.doRequest(pagedObj).then((response) => {
            if(response?.statusExecucao && response?.dadosRetorno) {
                const list = response?.dadosRetorno.listCalendarios;
                if (initialPage) {
                    setEventList(list);
                } else {
                    setEventList([...eventList, ...list]);
                }
                
                setPage(response?.dadosRetorno.page);
                setPageCount(response?.dadosRetorno.pageCount);
            } else {
                snackbar(response.mensagem || "Não foi possível buscar os dados dos eventos.").error();
                history.redirectTo("/home");
            }
        }).catch((error) => {
            snackbar("Não foi possível buscar os dados dos eventos.").error();
            history.redirectTo("/home");
        });
    }

    return (
        <ListEventView 
            searchTerm={searchTerm}
            pageCount={pageCount}
            page={page}
            eventList={eventList}
            eventDate={eventDate}
            degreeOfMeetingList={degreeOfMeetingList}
            degreeOfMeetingSelected={degreeOfMeetingSelected}
            loading={DegreeOfMeetingGetAll.loading}
            infinityLoading={EventNextPageSearch.loading}
            setEventDate={setEventDate}
            setSearchTerm={setSearchTerm}
            getNextPage={getNextPage}
            setDegreeOfMeetingSelected={setDegreeOfMeetingSelected}
        />
    )
}

export default ListEvent;