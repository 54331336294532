import { Container, styled } from '@mui/material';

export const ContainerStyled = styled(Container)(() => ({
    margin: 'auto',
    display: 'flex',
    paddingLeft: 0,
    paddingRight: 0,
    flexDirection: 'column',
}));

export const NoContentContainer = styled('div')(() => ({
    height: "80vh",
    display: "flex",
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: 'center',
}));