import { LoadingButton } from "@mui/lab";
import { Box, Divider, IconButton, Typography, styled } from "@mui/material";

export const HeaderBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 8,
    width: "100%"
}));

export const InfoHeader = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
}));

export const IconButtonStyled = styled(IconButton)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '5px'
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        marginBottom: 12
    }
}));

export const EditButton = styled(LoadingButton)(({ theme }) => ({
    height: 0,
    padding: 18,
    borderRadius: 16,
    borderColor: theme.palette.text.secondary,
    ':hover': {
        borderColor: theme.palette.text.secondary,
    }
}));

export const HeaderDivider = styled(Divider)(() => ({
    marginTop: 10,
    marginBottom: 10,
}));