import { styled, Typography } from '@mui/material';

export const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main
}));

export const TextNoData = styled('div')(() => ({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: 'center'
}));