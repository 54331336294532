import { Button, Card, Divider, styled, Typography } from "@mui/material";

export const Title = styled(Typography)(() => ({
    marginTop: 10,
    marginBottom: 10,
}));

export const SectionCard = styled(Card)(() => ({
    borderRadius: 6, 
    marginBottom: 24
}));

export const PageNotFoundText = styled(Typography)(({theme}) => ({
    color: theme.palette.text.primary,
    marginBottom: 18
}));

export const GoBackButton = styled(Button)(({theme}) => ({
    textTransform: "uppercase",
    backgroundColor: theme.palette.primary.main
}));

export const NoContentContainer = styled('div')(() => ({
    height: "80vh",
    display: "flex",
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: 'center',
}));

export const Banner = styled('img')(({theme}) => ({
    width: '100%',
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    objectFit: "contain",
    [theme.breakpoints.up("sm")]: {
        height: '380px'
    },
    [theme.breakpoints.down("xs")]: {
        height: '320px'
    },
}));

export const LightText = styled(Typography)(() => ({
    fontWeight: 'lighter', 
    opacity: '0.8', 
    marginBottom: 4,
    marginTop: 4
}));

export const SectionDivider = styled(Divider)(() => ({
    marginTop: 12,
    marginBottom: 12
}));

export const EditButton = styled(Button)(({ theme }) => ({
    height: 0,
    padding: 18,
    borderRadius: 16,
    color: theme.palette.text.secondary,
    borderColor: theme.palette.text.secondary,
    ':hover': {
        borderColor: theme.palette.text.secondary,
    }
}));