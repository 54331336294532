import { styled, Typography } from '@mui/material';

export const ContentContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
}));

export const FlexDiv = styled('div')(() => ({
    display: 'flex',
    width: '100%',
    marginTop: 6
}));

export const TitleText = styled(Typography)(({ theme }) => ({
    textAlign: "center",
    color: theme.palette.primary.contrastText,
    fontWeight: 700,
    marginRight: 10,
    fontSize: 32
}));

export const DefaultText = styled(Typography)(({ theme }) => ({
    textAlign: "center",
    color: theme.palette.primary.contrastText,
    fontWeight: 700,
    fontSize: 16,
    width: 100
}));