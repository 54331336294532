import React from 'react';
import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { IFormCardHeader } from './FormCardHeader.interface';
import {
    EditButton, HeaderBox, HeaderDivider,
    Subtitle, IconButtonStyled, InfoHeader
} from './FormCardHeader.style';

function FormCardHeader(props: IFormCardHeader) {
    const { onCloseClick, title, subtitle, loading } = props;

    return (
        <React.Fragment>
            <HeaderBox>
                <InfoHeader>
                    <IconButtonStyled onClick={onCloseClick}>
                        <CloseIcon />
                    </IconButtonStyled>
                    <Box>
                        <Typography variant='h3'>{title}</Typography>
                        <Subtitle variant="caption" color="primary" display="block">{subtitle}</Subtitle>
                    </Box>
                </InfoHeader>

                <EditButton type='submit' variant="contained" loading={loading}>
                    <Typography variant='h6' lineHeight={1}>Salvar</Typography>
                </EditButton>
            </HeaderBox>

            <HeaderDivider />
        </React.Fragment>
    )
}

export default FormCardHeader;