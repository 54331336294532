import {isIOS, isAndroid, isTablet, isMobile} from "react-device-detect";

export default class UserSystem {
    public isIOS() {
        return (isIOS);
    }

    public isAndroid() {
        return (isAndroid);
    }

    public isTablet() {
        return (isTablet);
    }

    public isMobile() {
        return (isMobile);
    }

    public isDesktop() {
        return (!(isMobile || isTablet));
    }
}