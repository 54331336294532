import { Typography } from '@mui/material';

import { GenericCardList } from '../../../../components';
import { IEventListCardView } from './EventListCard.interface';

function EventListCardView(props: IEventListCardView) {
    const { title, date, hour, titleGrau, goToEventPage } = props;

    return (
        <GenericCardList title={title} goToPage={goToEventPage}>
            <Typography variant="body2" fontWeight="bold">{titleGrau}</Typography>
            <Typography variant="body2">Data do evento: {date}</Typography>
            <Typography variant="body2">Horário de início: {hour}</Typography>
        </GenericCardList>
    );
}

export default EventListCardView;