import RestAPIModule from './../../RestApi';
import { IResponse, IEventDTO } from '../../../../model';
import { IEventSearchWithPaginationInput, IEventSearchWithPaginationOutput, IGetEventByIdInput } from './Event.interface';

export default class EventRequest {
    public static URL_EVENT = `${RestAPIModule.URL}/Calendario`;
    public static URL_EVENT_GET_BY_ID = `${EventRequest.URL_EVENT}/GetById`;
    public static URL_EVENT_SEARCH_WITH_PAGINATION = `${EventRequest.URL_EVENT}/PesquisarComPaginacao`;

    public static GetEventById(params: IGetEventByIdInput): Promise<IResponse<IEventDTO>> {
        return RestAPIModule.httpGet(`${EventRequest.URL_EVENT_GET_BY_ID}?IdCalendario=${params.idCalendario}`).then((response) => response);
    }

    public static SearchWithPagination(params: IEventSearchWithPaginationInput): Promise<IResponse<IEventSearchWithPaginationOutput>> {
        return RestAPIModule.httpGet(`${EventRequest.URL_EVENT_SEARCH_WITH_PAGINATION}?strTermoPesquisa=${params.strTermoPesquisa}${params.idGrauReuniao ? `&idGrauReuniao=${params.idGrauReuniao}` : ""}${params.dataEvento ? `&dataEvento=${params.dataEvento}` : ""}&page=${params.page}&amount=${params.amount}`).then((response) => response);
    }
}