import { useHistory } from '../../../../hooks';
import CardListJobView from './CardListJob.view';
import { ICardListJobProps } from './CardListJob.interface';

function CardListJob(props: ICardListJobProps) {
    const { item } = props;

    const navigate = useHistory();

    function redirectToJobDetail() {
        navigate.redirectTo(`/detailJob/${item.idVaga}`);
    }

    return (
        <CardListJobView
            title={item?.nomeVaga || ""}
            companyName={item?.nomeEmpresa || "--"}
            description={item?.descricaoVaga || "--"}
            redirect={redirectToJobDetail}
        />
    );
}

export default CardListJob;