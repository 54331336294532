import { Typography } from '@mui/material';
import { GenericCardList } from '../../../components';
import { ICardBrotherViewProps } from './CardBrother.interface';

function CardBrotherView(props: ICardBrotherViewProps) {
    const { name, job, horizon, state, brotherPhoto, redirect } = props;

    return (
        <GenericCardList title={name} avatarUrl={brotherPhoto} showAvatar goToPage={redirect}>
            <Typography variant="body2"> Cidade: {horizon} - {state} </Typography>
            <Typography variant="body2"> Profissão: {job} </Typography>
        </GenericCardList>
    );
}

export default CardBrotherView;