import { format } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ModulesContext } from '../../context/modules/Modules';
import { IModulesContext } from '../../context/modules/Modules.interface';

import { useDoRequest, useHistory, useSnackbar } from '../../hooks';
import { ICompanyDTO } from '../../model';
import { ICompanyGetById } from '../../modules/api/endpoints/company/Company.interface';

import DetailCompanyView from './DetailCompany.view';
import {IDeepLinkContext} from "../../context/deepLink/DeepLink.interface";
import {DeepLinkContext} from "../../context/deepLink/DeepLink";

function DetailCompany() {
    const { idCompany } = useParams();
    const history = useHistory();
    const snackbar = useSnackbar();

    const { Analytics } = useContext<IModulesContext>(ModulesContext);
    const { setRedirectScreen } = useContext<IDeepLinkContext>(DeepLinkContext);

    const CompanyGetById = useDoRequest((api) => api.Company.GetById);

    const [companyObject, setCompanyObject] = useState<ICompanyDTO>();
    const [companyInsertDate, setCompanyInsertDate] = useState<string>('');

    useEffect(componentDidMount, [idCompany]);

    function componentDidMount() {
        Analytics.logScreen('Detalhes de um estabelecimento');
        setRedirectScreen(`Login/DetailCompany/${idCompany}`);

        getCompany();

        return () => {
            setRedirectScreen(null);
        }
    }

    function getCompany() {
        if (idCompany) {
            const companyObj: ICompanyGetById = {
                idEstabelecimento: Number(idCompany),
                latitude: 0,
                longitude: 0
            };

            CompanyGetById.doRequest(companyObj).then((res) => {
                if (res.statusExecucao && res?.dadosRetorno) {
                    setCompanyObject(res.dadosRetorno);
                    if (res.dadosRetorno?.idObreiroCadastroNavigation?.dataNascimento && res.dadosRetorno?.dataCadastro) {
                        setCompanyInsertDate(format(new Date(res.dadosRetorno?.dataCadastro), "dd'/'MM'/'yyyy"));
                    }
                } else {
                    snackbar(res.mensagem || "Não foi possível buscar os dados da empresa.").error();
                    history.redirectTo('/home');
                }
            }).catch((error) => {
                snackbar("Não foi possível buscar os dados empresa.").error();
                history.redirectTo('/404');
            })
        } else {
            history.redirectTo('/404');
        }
    }

    function getAddress(): string {
        let adress = ''
        if (companyObject?.enderecoRua) adress = companyObject?.enderecoRua
        if (companyObject?.enderecoNumero) adress = adress.concat(`, ${companyObject?.enderecoNumero}`)
        if (companyObject?.enderecoBairro) adress = adress.concat(`, ${companyObject?.enderecoBairro}`)
        if (companyObject?.enderecoCidade) adress = adress.concat(`, ${companyObject?.enderecoCidade}`)
        if (companyObject?.enderecoUf) adress = adress.concat(` - ${companyObject?.enderecoUf}`)

        return adress;
    }

    return (
        <DetailCompanyView
            loading={CompanyGetById.loading}
            companyObject={companyObject}
            companyInsertDate={companyInsertDate}
            getAddress={getAddress}
        />
    );
}

export default DetailCompany;