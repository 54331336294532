import { IProps } from "./BrotherPost.interface";
import BrotherPostView from "./BrotherPost.view";
import { useHistory } from "../../../../../hooks";

function BrotherPost(props: IProps) {
    const { brotherName, brotherIcon, brotherPosts, postPage, postPageCount, infinityLoading, getNextBrotherPosts } = props;
    const history = useHistory();

    function redirectToDetailPost(idPost: string | number) {
        history.redirectTo(`/detailPost/${idPost}`);
    }

    return (
        <BrotherPostView
            brotherName={brotherName}
            brotherIcon={brotherIcon}
            brotherPosts={brotherPosts}
            postPage={postPage}
            infinityLoading={infinityLoading}
            postPageCount={postPageCount}
            getNextBrotherPosts={getNextBrotherPosts}
            redirectToDetailPost={redirectToDetailPost}
        />
    );
}

export default BrotherPost;