import AddIcon from '@mui/icons-material/Add';
import { Box, Typography } from "@mui/material";

import { WorkExperienceModal } from "./../../";
import { IPropsView } from "./ProfessionalHistory.interface";
import { AddButton, PageContainer } from "./ProfessionalHistory.style";

function ProfessionalHistoryView(props: IPropsView) {

    const { 
        renderProfessionalHistory, 
        workModalVisible, 
        selectedExperience, 
        allExperiences, 
        modalType, 
        isMyProfile, 
        setAllExperiences, 
        workExperienceModal, 
        closeModal 
    } = props;

    return (
        <PageContainer>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="subtitle1" color="secondary">EXPERIÊNCIA DE TRABALHO</Typography>
                {isMyProfile && (
                    <AddButton onClick={() => workExperienceModal(null, 'create')} >
                        <AddIcon />
                    </AddButton>
                )}
            </Box>
            {renderProfessionalHistory}

            <WorkExperienceModal
                type={modalType}
                modalVisible={workModalVisible}
                selectedExperience={selectedExperience}
                allExperiences={allExperiences}
                setAllExperiences={setAllExperiences}
                onClose={closeModal}
            />
        </PageContainer>
    );
}

export default ProfessionalHistoryView;