import BookCardView from "./BookCard.view";
import { useHistory } from "../../../../hooks";
import { IBookCardProps } from "./BookCard.interface";

function BookCard(props: IBookCardProps) {
    const { book } = props;
    const history = useHistory();

    function redirectToDetailBook() {
        history.redirectTo(`/detailBook/${book.idLivro}`);
    }

    return (
        <BookCardView
            title={book.tituloLivro || ""}
            author={book.autorLivro || "--"}
            publisher={book.editoraLivro || "--"}
            redirectToDetailBook={redirectToDetailBook}
        />
    );
}

export default BookCard;