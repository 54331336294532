import { useContext, useEffect, useState } from "react";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useParams } from "react-router-dom";

import { useDoRequest, useHistory, useSnackbar } from "../../hooks";
import { IPostGetById } from "../../modules/api/endpoints/post/Post.interface";
import DetailPostView from "./DetailPost.view";

import { IPostDTOGetById } from "../../model";
import { IModulesContext } from "../../context/modules/Modules.interface";
import { ModulesContext } from "../../context/modules/Modules";
import { DeepLinkContext } from "../../context/deepLink/DeepLink"
import {IDeepLinkContext} from "../../context/deepLink/DeepLink.interface";

function DetailPost() {
    const { idPost } = useParams<string>();
    const snackbar = useSnackbar();
    const history = useHistory();

    const { Analytics } = useContext<IModulesContext>(ModulesContext);
    const { setRedirectScreen } = useContext<IDeepLinkContext>(DeepLinkContext);

    const Post = useDoRequest((api) => api.Post.GetById);
    const [post, setPost] = useState<IPostDTOGetById>();
    const [postFormatedDate, setPostFormatedDate] = useState<string>('');

    useEffect(componentDidMount, [idPost]);

    function componentDidMount() {
        Analytics.logScreen('Detalhes de uma publicação');
        setRedirectScreen(`Login/DetailPost/${idPost}`);

        getPost();

        return () => {
            setRedirectScreen(null);
        }
    }

    function getPost() {
        if (idPost) {
            const postObj: IPostGetById = {
                idPost: Number(idPost)
            }

            Post.doRequest(postObj).then((response) => {
                if (response.statusExecucao && response.dadosRetorno) {
                    setPost(response.dadosRetorno);
                    setPostFormatedDate(format(new Date(response.dadosRetorno?.dataCriacao), "dd'/'MM'/'yyyy", { locale: ptBR }));
                } else {
                    snackbar(response.mensagem || "Não foi possível buscar os dados do post.").error();
                    history.redirectTo('/home');
                }
            }).catch((error) => {
                snackbar("Não foi possível buscar postagem.").error();
                history.redirectTo('/404');
            })
        } else {
            history.redirectTo('/404');
        }
    }

    function goBackHome() {
        history.redirectTo("/");
    }

    function getPostText() {
        return {__html: post?.texto ?? ""};
     }; 

    return (
        <DetailPostView
            post={post}
            loading={Post.loading}
            postFormatedDate={postFormatedDate}
            goBackHome={goBackHome}
            getPostText={getPostText}
        />
    )
}

export default DetailPost;