import { useEffect, useState, useContext } from "react";
import { format } from "date-fns";
import ptBR from 'date-fns/locale/pt-BR';
import { Edit, Delete } from "@mui/icons-material";
import SchoolIcon from '@mui/icons-material/School';
import { Box, CircularProgress, IconButton } from "@mui/material";

import { IProps } from "./AcademicHistory.interface";
import AcademicHistoryView from "./AcademicHistory.view";
import { useDoRequest, useSnackbar } from "../../../../../hooks";
import { ModulesContext } from "../../../../../context/modules/Modules";
import { IModulesContext } from "../../../../../context/modules/Modules.interface";
import { IHistoricoAcademicoObreiro } from "../../../../../model/BrotherDTO.interface";
import { DefaultBox, InfosText, LevelName, SchoolName, SchoolNameBox } from "./AcademicHistory.style";

function AcademicHistory(props: IProps) {
    const { Analytics } = useContext<IModulesContext>(ModulesContext);

    const { academicHistoryData, isMyProfile } = props;

    const snackbar = useSnackbar();
    const DeleteAcademicHistory = useDoRequest((api) => api.AcademicHistory.DeleteAcademicHistory);

    const [selectedAcademicHistory, setSelectedAcademicHistory] = useState<IHistoricoAcademicoObreiro | null>();

    const [allHistory, setAllHistory] = useState<IHistoricoAcademicoObreiro[]>();

    const [academicModalVisible, setAcademicModalVisible] = useState<boolean>(false);
    const [modalType, setModalType] = useState<"create" | "edit">('create');

    useEffect(() => {
        if (!academicHistoryData) return;
        setAllHistory(academicHistoryData);
    }, [academicHistoryData])

    function renderAcademicHistory(academicRecord: IHistoricoAcademicoObreiro[] | undefined) {

        if (!academicRecord) return;

        if (academicRecord.length > 0) {
            return (
                academicRecord.map((item, idx) => (
                    <DefaultBox key={idx} >
                        <div style={{ display: 'flex', width: '100%' }}>
                            <SchoolIcon color="primary" style={{ marginTop: 3 }} />

                            <SchoolNameBox>
                                <SchoolName variant="subtitle2">{item.nomeInstituicao}</SchoolName>
                                <InfosText variant="body1">{item.nomeCurso}</InfosText>

                                <InfosText variant="body2">
                                    {format(new Date(item.dataInicio), "MMM' de 'yyyy", { locale: ptBR })} - {format(new Date(item.dataFim), "MMM' de 'yyyy", { locale: ptBR })}
                                    {item.bitConcluido ? " (Concluído)" : " (Não concluído)"}
                                </InfosText>
                                
                                <LevelName>{item.idNivelInstrucaoNavigation?.descricaoInstrucao}</LevelName>
                            </SchoolNameBox>
                        </div>

                        {isMyProfile && (
                            <Box alignItems="flex-start" display="flex" >
                                <IconButton onClick={() => academicHistoryModal(item, 'edit')}>
                                    <Edit />
                                </IconButton>
                                <IconButton onClick={() => deleteAcademicHistory(item.idHistoricoAcademico)} >
                                    {!DeleteAcademicHistory.loading ? (
                                        <Delete color="error" />
                                    ) : (
                                        <CircularProgress size={24} color="error"/>
                                    )}
                                </IconButton>
                            </Box>
                        )}

                    </DefaultBox>
                ))
            )
        } else {
            return (
                <DefaultBox >
                    <InfosText variant="overline">Este obreiro não possui histórico acadêmico.</InfosText>
                </DefaultBox>
            )
        }
    }

    function deleteAcademicHistory(idHistory: number) {
        Analytics.logScreenAction('Detalhes de um irmão', 'Deletar histórico acadêmico');

        const params = {
            idHistoricoAcademico: idHistory
        }

        DeleteAcademicHistory.doRequest(params).then((res) => {
            if (res?.dadosRetorno && res?.statusExecucao) {

                let academicHistoryInstance = allHistory?.filter((item) => item.idHistoricoAcademico !== idHistory);

                setAllHistory(academicHistoryInstance);

                snackbar("Histórico deletado com sucesso!").success();
            }
        }).catch((error) => {
            snackbar("Não foi possível deletar histórico").error();
        });
    }

    function academicHistoryModal(experience: IHistoricoAcademicoObreiro | null, type: 'create' | 'edit') {
        setModalType(type);
        if (experience) {
            setSelectedAcademicHistory({ ...experience });
        } else {
            setSelectedAcademicHistory(null);
        }

        setAcademicModalVisible(true);
    }

    return (
        <AcademicHistoryView
            renderAcademicHistory={renderAcademicHistory(allHistory)}
            selectedAcademicHistory={selectedAcademicHistory}
            isMyProfile={isMyProfile}
            academicHistoryModalVisible={academicModalVisible}
            modalType={modalType}
            allAcademicHistory={allHistory}
            setAllHistory={setAllHistory}
            setAcademicModalVisible={setAcademicModalVisible}
            academicHistoryModal={academicHistoryModal}
        />
    );
}

export default AcademicHistory;