import { Autocomplete, InputLabel, MenuItem, TextField, Select, Grid } from '@mui/material';

import { IPropsView } from './LanguageModal.interface';
import { FormCardHeader } from '../../../../../components';
import { CardContentBox, FormControlStyled, ModalBox, ModalCard, ModalContainer } from './LanguageModal.style';

function LanguageModalView(props: IPropsView) {
    const {
        modalVisible,
        situationLevel,
        modalType,
        languageArray,
        addLoading,
        selectedLanguage,
        onClose,
        handleSelectChange,
        handleSubmit,
    } = props;

    return (
        <ModalContainer open={modalVisible} onClose={onClose}>
            <ModalBox>
                <ModalCard>
                    <CardContentBox>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <FormCardHeader
                                onCloseClick={onClose}
                                title={modalType == "edit" ? "Editar idioma" : "Adicionar idioma"}
                                subtitle={"Lista de idiomas"}
                                loading={addLoading}
                            />

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    {modalType === "edit" ? (
                                        <TextField
                                            variant="outlined"
                                            label="Idioma"
                                            disabled={true}
                                            name="language"
                                            value={selectedLanguage || ""}
                                            required
                                            fullWidth
                                        />
                                    ) : (
                                        <Autocomplete
                                            disablePortal
                                            id="selectLanguage"
                                            options={languageArray}
                                            onChange={(event, value) => handleSelectChange("language", value?.value)}
                                            renderInput={(params) => <TextField {...params} required label="Idioma" />}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <FormControlStyled>
                                        <InputLabel id="selectEducationLevel">Habilidade</InputLabel>
                                        <Select
                                            labelId="habilityLevel"
                                            id="habilityLevel"
                                            value={situationLevel || ''}
                                            label="Habilidade"
                                            required
                                            fullWidth
                                            onChange={(event: any) => handleSelectChange("hability", event.target.value)}
                                        >
                                            <MenuItem value={1}> Básico </MenuItem>
                                            <MenuItem value={2}> Intermediário </MenuItem>
                                            <MenuItem value={3}> Avançado </MenuItem>
                                            <MenuItem value={4}> Fluente </MenuItem>
                                        </Select>
                                    </FormControlStyled>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContentBox>
                </ModalCard>
            </ModalBox>
        </ModalContainer>
    );
}

export default LanguageModalView;