import { FormEvent, useContext, useEffect, useState } from 'react'
import _ from "lodash";

import LanguageModalView from './LanguageModal.view';
import { useDoRequest, useSnackbar } from '../../../../../hooks';
import { useGlobalState } from '../../../../../context/global/Global';
import { ModulesContext } from '../../../../../context/modules/Modules';
import { IProps, ISelectLanguageArray } from './LanguageModal.interface';
import { ITblIdiomaObreiro } from '../../../../../model/BrotherDTO.interface';
import { IModulesContext } from '../../../../../context/modules/Modules.interface';
import { IBrotherLanguageAdd, IBrotherLanguageAlter } from '../../../../../modules/api/endpoints/brotherLanguage/BrotherLanguage.interface';

function LanguageModal(props: IProps) {
    const { 
        modalVisible, 
        modalType, 
        userLanguageList, 
        editItem, 
        onClose, 
        setLanguagesList 
    } = props;
    const { Analytics } = useContext<IModulesContext>(ModulesContext);

    const [languageArray, setLanguageArray] = useState<ISelectLanguageArray[]>([]);
    const [selectedLanguage, setSelectedLanguage] = useState<number | string>();
    const [situationLevel, setSituationLevel] = useState<number | string>();

    const snackbar = useSnackbar();

    const { user } = useGlobalState();

    const LanguagesGet = useDoRequest((api) => api.Languages.Get);
    const BrotherLanguagesAdd = useDoRequest((api) => api.BrotherLanguages.Add);
    const BrotherLanguagesAlter = useDoRequest((api) => api.BrotherLanguages.Update);

    useEffect(() => {
        getAllLanguages();
    }, [])

    useEffect(() => {
        if(!modalVisible) return;
        if(!editItem || modalType === "create"){
            getAllLanguages();
        } else {
            setSelectedLanguage(editItem.idIdiomaNavigation.idioma || '');
            setSituationLevel(getSituationLevel(editItem) || '');
        }
    }, [modalVisible])

    function getSituationLevel(item: ITblIdiomaObreiro) {
        if(item.bitBasico) return 1;
        if(item.bitIntermediario) return 2;
        if(item.bitAvancado) return 3;
        if(item.bitFluente) return 4;
    }

    function getAllLanguages() {
        LanguagesGet.doRequest("").then((response) => {
            if(response?.dadosRetorno && response.statusExecucao){
                let allLanguagesFiltered = response.dadosRetorno;

                allLanguagesFiltered = _.uniqBy(allLanguagesFiltered, function (e) {
                    return e.idioma;
                });

                userLanguageList.forEach((language) => {
                    allLanguagesFiltered = allLanguagesFiltered.filter((lang) => (lang.idIdioma != language.idIdioma) && (lang.idioma != language?.idIdiomaNavigation?.idioma));
                });

                let allLanguagesSelect: ISelectLanguageArray[] = [];

                allLanguagesFiltered.forEach((lang) => {
                    allLanguagesSelect.push({label: lang.idioma, value: lang.idIdioma});
                });

                setLanguageArray(allLanguagesSelect);
                setSelectedLanguage('');
                setSituationLevel('');
            } else {
                snackbar(response.mensagem || "Não foi possível buscar os dados dos idiomas.").error();
            }
        }).catch((error) => {
            snackbar("Não foi possível obter os idiomas.").error();
        })
    }
    
    function handleSelectChange(type: "hability" | "language", value?: number) {
        if(type === "language") {
            setSelectedLanguage(value);
        } else {
            setSituationLevel(value);
        }
    }

    function getHability(value: number) {
        return value == situationLevel;
    }

    function handleSubmit(event: FormEvent){
        event.preventDefault();
        if(!selectedLanguage) return;

        if(modalType == 'create'){
            addLanguage();
        } else {
            editLanguage();
        }
    }

    function editLanguage(){
        if(!editItem || !user) return;
        
        Analytics.logScreenAction('Detalhes de um irmão', 'Editar idioma');

        const languageObj: IBrotherLanguageAlter = {
            idIdiomaObreiro: editItem.idIdiomaObreiro,
            idIdioma: editItem?.idIdioma,
            idObreiro: user.idObreiro,
            bitBasico: getHability(1),
            bitIntermediario: getHability(2),
            bitAvancado: getHability(3),
            bitFluente: getHability(4),
        }

        BrotherLanguagesAlter.doRequest(languageObj).then((response) => {
            if(response?.dadosRetorno && response.statusExecucao){
                const alteredLanguage = response?.dadosRetorno;
                let listObj = userLanguageList;

                listObj?.forEach((lang) => {
                    if(lang.idIdioma == alteredLanguage.idIdioma){
                        lang.bitAvancado = alteredLanguage.bitAvancado;
                        lang.bitBasico = alteredLanguage.bitBasico;
                        lang.bitFluente = alteredLanguage.bitFluente;
                        lang.bitIntermediario = alteredLanguage.bitIntermediario;
                    }
                });

                setLanguagesList(listObj);
                snackbar("Idioma alterado com sucesso!").success();
                onClose();
            } else {
                snackbar(response.mensagem || "Não foi possível alterar o idioma.").error();
            }
        }).catch((error) => {
            snackbar("Não foi possível alterar idioma.").error();
        })
    }

    function addLanguage(){
        if(!user) return;
        
        Analytics.logScreenAction('Detalhes de um irmão', 'Adicionar idioma');
        
        const languageObj: IBrotherLanguageAdd = {
            idIdioma: Number(selectedLanguage),
            idObreiro: user.idObreiro,
            bitBasico: getHability(1),
            bitIntermediario: getHability(2),
            bitAvancado: getHability(3),
            bitFluente: getHability(4),
        }

        BrotherLanguagesAdd.doRequest(languageObj).then((response) => {
            if(response?.dadosRetorno && response.statusExecucao){
                let listObj = userLanguageList;
                listObj.push(response.dadosRetorno);
                setLanguagesList(listObj);
                snackbar("Idioma adicionado com sucesso!").success();
                onClose();
            } else {
                snackbar(response.mensagem || "Não foi possível cadastrar novo idioma.").error();
            }
        }).catch((error) => {
            snackbar("Não foi possível cadastrar novo idioma.").error();
        })
    }

    function onCloseModal() {
        clearFields();
        onClose();
    }

    function clearFields() {
        setSelectedLanguage('');
        setSituationLevel('');
    }   

    return (
        <LanguageModalView 
            modalVisible={modalVisible}
            situationLevel={situationLevel}
            selectedLanguage={selectedLanguage}
            languageArray={languageArray}
            modalType={modalType}
            addLoading={BrotherLanguagesAdd.loading || BrotherLanguagesAlter.loading}
            onClose={onCloseModal}
            handleSubmit={handleSubmit}
            handleSelectChange={handleSelectChange}
        />
    )
}

export default LanguageModal;