import { useContext, useEffect, useState } from "react";
import {  format  } from "date-fns";
import {  ptBR  } from "date-fns/locale";
import { Box, IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

import {  IProps  } from "./ProfessionalHistory.interface";
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import ProfessionalHistoryView from "./ProfessionalHistory.view";
import { useDoRequest, useSnackbar } from "./../../../../../hooks";
import { ModulesContext } from "./../../../../../context/modules/Modules";
import { IModulesContext } from "./../../../../../context/modules/Modules.interface";
import {  IHistoricoProfissionalObreiro  } from "./../../../../../model/BrotherDTO.interface";
import {  DefaultBox, DeleteIconRed, InfosBox, InfosText, JobName  } from "./ProfessionalHistory.style";

function ProfessionalHistory(props: IProps) {
    const { professionalHistory, isMyProfile, setProfessionalHistory } = props;
    const { Analytics } = useContext<IModulesContext>(ModulesContext);

    const [allExperiences, setAllExperiences] = useState<IHistoricoProfissionalObreiro[]>();

    const snackbar = useSnackbar();

    const ProfessionalHistoryDelete = useDoRequest((api) => api.ProfessionalHistory.Delete);

    useEffect(() => {
        if  (!professionalHistory) return;
        setAllExperiences(professionalHistory);
    }, [professionalHistory])

    const [selectedExperience, setSelectedExperience] = useState<IHistoricoProfissionalObreiro | null>();

    const [workModalVisible, setWorkModalVisible] = useState<boolean>(false);
    const [modalType, setModalType] = useState<"create" | "edit">('create');

    function deleteExperience(idPorfessionalHistory: number) {
        Analytics.logScreenAction('Detalhes de um irmão', 'Deletar histórico profissional');
        ProfessionalHistoryDelete.doRequest(idPorfessionalHistory).then((response) => {
            if(response?.dadosRetorno && response.statusExecucao){
                snackbar(response.mensagem || "Experiência profissional deletada com sucesso!").success();
                let allExperiencesArray = allExperiences;

                const newArray = allExperiencesArray?.filter((item) => item.idHistoricoProfissional != response.dadosRetorno?.idHistoricoProfissional);

                if(newArray){
                    if(newArray?.length > 0){
                        setProfessionalHistory(newArray);
                    }  else {
                        setProfessionalHistory([]);
                    }
                }
                
            } else {
                snackbar(response.mensagem || "Não foi possível deletar os dados da experiência.").error();
            }
        }).catch((error) => {
            snackbar("Não foi possível deletar os dados da experiência.").error();
        })
    }

    function renderProfessionalHistory(professionalHistory: IHistoricoProfissionalObreiro[]) {
        if (professionalHistory.length > 0) {
            return (professionalHistory.map((item: IHistoricoProfissionalObreiro, idx) => (
                <DefaultBox key={idx}>
                    <div style={{ display: 'flex' }}>
                        <WorkHistoryIcon color="primary" style={{ marginTop: 3 }} />

                        <InfosBox>

                            <JobName variant="subtitle2">
                                {item.nomeCargo}
                            </JobName>

                            <InfosText variant="body1">
                                {item.nomeEmpresa}
                            </InfosText>

                            <InfosText variant="body2">
                                {format(new Date(item.dataInicio), "MMM' de 'yyyy", { locale: ptBR })} -
                                {item.dataFim ? format(new Date(item.dataFim), "MMM' de 'yyyy", { locale: ptBR }) : "Atual"}
                            </InfosText>

                            <InfosText variant="caption">
                                {item.descricaoAtividade}
                            </InfosText>

                        </InfosBox>
                    </div>

                    {isMyProfile && (
                        <Box>
                            <IconButton onClick={() => workExperienceModal(item, 'edit')}>
                                <EditIcon />
                            </IconButton>
                            <IconButton onClick={() => deleteExperience(item.idHistoricoProfissional)}>
                                <DeleteIconRed />
                            </IconButton>
                        </Box>
                    )}
                </DefaultBox>
            ))
            )
        } else {
            return (
                <DefaultBox>
                    <InfosText variant="overline">Este obreiro não possui histórico profissional.</InfosText>
                </DefaultBox>
            )
        }
    }

    function workExperienceModal(experience: IHistoricoProfissionalObreiro | null, type: 'create' | 'edit') {
        setModalType(type);
        if  (experience)  {
            setSelectedExperience({  ...experience  });
        } else {
            setSelectedExperience(null);
        }
        setWorkModalVisible(true);
    }

    function closeModal() {
        setWorkModalVisible(false);
    }

    return (
        <ProfessionalHistoryView
            renderProfessionalHistory={renderProfessionalHistory(professionalHistory)}
            modalType={modalType}
            workModalVisible={workModalVisible}
            selectedExperience={selectedExperience}
            allExperiences={allExperiences}
            isMyProfile={isMyProfile}
            workExperienceModal={workExperienceModal}
            setAllExperiences={setAllExperiences}
            closeModal={closeModal}
        />
    );
}

export default ProfessionalHistory;