import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ModulesContext } from '../../context/modules/Modules';
import { IModulesContext } from '../../context/modules/Modules.interface';

import { useDoRequest, useHistory, useSnackbar } from '../../hooks';
import { IBookDTO } from '../../model/BookDTO.interface';
import { IBookGetById } from '../../modules/api/endpoints/book/Book.interface';
import DetailBookView from './DetailBook.view';
import {IDeepLinkContext} from "../../context/deepLink/DeepLink.interface";
import {DeepLinkContext} from "../../context/deepLink/DeepLink";

function DetailBook() {
    const { idBook } = useParams();
    const history = useHistory();
    const snackbar = useSnackbar();

    const { Analytics } = useContext<IModulesContext>(ModulesContext);
    const { setRedirectScreen } = useContext<IDeepLinkContext>(DeepLinkContext);

    const GetById = useDoRequest((api) => api.Book.GetById);
    const [book, setBook] = useState<IBookDTO>();

    useEffect(componentDidMount, [idBook]);

    function componentDidMount() {
        Analytics.logScreen('Detalhes de um livro');
        setRedirectScreen(`AuthScreen/BookDetail/${idBook}`);

        getBook();

        return () => {
            setRedirectScreen(null);
        }
    }

    function getBook() {
        if (idBook) {
            const bookGetByIdObj: IBookGetById = {
                idBook: Number(idBook)
            }

            GetById.doRequest(bookGetByIdObj).then((res) => {
                if (res.statusExecucao && res.dadosRetorno) {
                    setBook(res.dadosRetorno);
                } else {
                    snackbar("Não foi possível buscar os dados do livro.").error();
                    history.redirectTo("/home");
                }
            }).catch((error) => {
                snackbar("Não foi possível buscar livro.").error();
                history.redirectTo("/home");
            });
        } else {
            history.redirectTo("/404");
        }
    }

    return (
        <DetailBookView
            book={book}
            loading={GetById.loading}
        />
    )
}

export default DetailBook;