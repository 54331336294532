
import CardBrotherView from './CardBrother.view';
import { ICardBrotherProps } from './CardBrother.interface';
import { useHistory } from '../../../hooks';

function CardBrother(props: ICardBrotherProps) {
    const { item } = props;

    const navigate = useHistory();

    function redirectToJobDetail() {
        navigate.redirectTo(`/detailBrother/${item.idObreiro}`);
    }
    
    return (
        <CardBrotherView
            name={item?.nomeObreiro || ""}
            job={item?.profissaoObreiro || "--"}
            horizon={item?.orienteObreiro || "--"}
            state={item?.ufObreiro || "--"}
            brotherPhoto={item?.fotoObreiro || ''}
            redirect={redirectToJobDetail}
        />
    );
}

export default CardBrother;