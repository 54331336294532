import RestAPIModule from './../../RestApi';
import { IResponse, IPostDTO } from '../../../../model';
import { IGetVotesPagedInput, IGetVotesPagedOutput, IPostGetById, IGetPostsByIdBrotherInput, IGetByIdBrotherPaged } from './Post.interface';

export default class PostRequest {
    public static URL_POST = `${RestAPIModule.URL}/FeedPotencia`;
    public static URL_POST_GET_BY_ID = `${PostRequest.URL_POST}/GetById`;
    public static URL_POST_GET_BY_ID_PAGED = `${PostRequest.URL_POST}/GetByIdBrotherPaged`;

    public static GetPostsPaged(params: IGetVotesPagedInput): Promise<IResponse<IGetVotesPagedOutput>> {
        return RestAPIModule.httpGet(
            `${PostRequest.URL_POST}/SearchFeed?searchTerm=${params.searchFeed}&isDeleted=false&page=${params.page}&amount=${params.amount}`
        ).then((response) => response);
    }

    public static GetById(params: IPostGetById): Promise<IResponse<any>> {
        return RestAPIModule.httpGet(`${PostRequest.URL_POST_GET_BY_ID}?idPublicacao=${params.idPost}`).then((response) => response);
    }

    public static GetPostsByIdBrother(params: IGetPostsByIdBrotherInput): Promise<IResponse<IPostDTO[]>> {
        return RestAPIModule.httpGet(`${PostRequest.URL_POST}/GetByIdBrother?idBrother=${params.idBrother}`).then((response) => response);
    }

    public static GetByIdBrotherPaged(params: IGetByIdBrotherPaged): Promise<IResponse<IGetVotesPagedOutput>> {
        return RestAPIModule.httpGet(`${PostRequest.URL_POST_GET_BY_ID_PAGED}?idBrother=${params.idBrother}&page=${params.page}&amount=${params.amount}`).then((response) => response);
    }
}