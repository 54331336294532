import React from "react";
import { Grid, Typography } from "@mui/material";
import { CardHeader, CardStyled, Loading } from "../../components";

import { IProps } from "./DetailBook.interface";

import { ContentContainer, DefaultText } from "./DetailBook.style";

function DetailBookView(props: IProps) {
    const { book, loading } = props;

    return (
        <React.Fragment>
            <Loading open={loading} />

            {!loading && (
                <CardStyled>
                    <CardHeader
                        title={book?.tituloLivro || ""}
                        subTitle='Informações'
                        options={[
                            <Typography variant="subtitle1">Número ISBN: {book?.isbnLivro}</Typography>,
                        ]}
                    />

                    <ContentContainer >
                        <Grid container >
                            <Grid item xs={12} lg={12} >
                                <DefaultText variant="h4" >Sinopse: </DefaultText>
                                <DefaultText variant="body1" marginBottom={4}>{book?.sinopseLivro}</DefaultText>

                                <DefaultText variant="h4" >Informações: </DefaultText>
                                <DefaultText variant="body1" ><strong>Autor: </strong>{book?.autorLivro}</DefaultText>
                                <DefaultText variant="body1" ><strong>Edição: </strong>{book?.edicaoLivro}</DefaultText>
                                <DefaultText variant="body1" ><strong>Editora: </strong>{book?.editoraLivro}</DefaultText>
                            </Grid>
                        </Grid>
                    </ContentContainer>
                </CardStyled>
            )}
        </React.Fragment>
    )
}

export default DetailBookView;