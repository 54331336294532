import { Autocomplete, Checkbox, CircularProgress, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import React from 'react';

import { CardStyled, InfinityScroll, Loading } from '../../components';
import { ICategoriesSelectList, IListCompanyView } from './ListCompany.interface';
import { CompanyListCard } from './components';
import { ICompanyDTO } from '../../model';

import { CheckBoxFormGroup, SearchButton, Title } from './ListCompany.style';

function ListCompanyView(props: IListCompanyView) {
    const {
        searchTerm, pageCount, page, covenant,
        companyList, categoriesList, loading,
        categorySelected, infinityLoading,
        getNextPage, setCovenant, setCategorySelected,
        setSearchTerm
    } = props;

    function renderCards() {
        if (companyList.length >= 1) {
            return companyList.map((company: ICompanyDTO, idx: number) => (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={idx}>
                    <CompanyListCard company={company} />
                </Grid>
            ));
        } else {
            return (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={1} mt={2} display="flex" alignItems="center" justifyContent="center">
                    <Typography>Nenhum estabelecimento encontrado.</Typography>
                </Grid>
            );
        }
    }

    return (
        <CardStyled>
            <Loading open={loading} />
            <Title variant="h3">Estabelecimentos</Title>

            <Grid container spacing={2} mt={1} mb={2} display="flex" alignItems="center">
                <Grid item xs={12} lg={12} sm={12} md={12} xl={12}>
                    <TextField
                        value={searchTerm}
                        fullWidth
                        size={"small"}
                        label="Buscar empresas"
                        id="search-companies"
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12} lg={6} sm={6} md={6} xl={6}>
                    <Autocomplete
                        size={"small"}
                        id="select-category"
                        noOptionsText={''}
                        freeSolo
                        value={categorySelected}
                        options={categoriesList}
                        onChange={(event, newValue: ICategoriesSelectList | string | null) => {
                            if (typeof newValue == "string") return;
                            setCategorySelected(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} label="Selecionar Categorias" />}
                    />
                </Grid>

                <Grid item xs={12} lg={3} sm={6} md={3} xl={3}>
                    <CheckBoxFormGroup>
                        <FormControlLabel control={<Checkbox checked={covenant} onChange={() => setCovenant(!covenant)} />} label="Convênio" sx={{ height: '100%' }} />
                    </CheckBoxFormGroup>
                </Grid>

                <Grid item xs={12} lg={3} sm={12} md={3} xl={3}>
                    <SearchButton
                        fullWidth
                        variant="contained"
                        size={'small'}
                        disabled={infinityLoading}
                        onClick={(e) => getNextPage(1)}
                    >
                        {infinityLoading ? (
                            <CircularProgress style={{ color: "white" }} size={24} />
                        ) : (
                            <SearchIcon />
                        )}
                    </SearchButton>
                </Grid>
            </Grid>

            {!loading &&
                <Grid container spacing={1}>
                    {renderCards()}
                </Grid>
            }

            {companyList && (page < pageCount) && !infinityLoading && (
                <InfinityScroll callBack={getNextPage} />
            )}

        </CardStyled>
    )
}

export default ListCompanyView;