import {CircularProgress, styled} from "@mui/material";

export const Loading = styled(CircularProgress)(({theme}) => ({
    color: theme.palette.primary.main,
    marginTop: 10,
    marginBottom: 10,
}));

export const LoadingContainer = styled('div')(() => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}));