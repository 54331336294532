import { Box, Card, CardContent, Modal, FormControl, styled } from '@mui/material';

export const ModalContainer = styled(Modal)(() => ({
    alignItems: "center",
    display: "flex",
    justifyContent: "center"
}));

export const ModalBox = styled(Box)(({ theme }) => ({
    maxHeight: theme.breakpoints.values.sm,
    width: theme.breakpoints.values.sm,
    borderRadius: 12
}));

export const ModalCard = styled(Card)(() => ({
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    padding: 12,
    borderRadius: 12
}));

export const CardContentBox = styled(CardContent)(() => ({
    paddingTop: 0
}));

export const FormControlStyled = styled(FormControl)(() => ({
    width: '100%'
}));