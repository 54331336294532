import { useContext, useEffect, useState } from 'react';

import ListCompanyView from './ListCompany.view'
import { ICategoryDTO, ICompanyDTO } from '../../model';
import { ICompanySearch } from '../../modules/api/endpoints/company/Company.interface';
import { useDoRequest, useHistory, useSnackbar } from '../../hooks';
import { ICategoriesSelectList } from './ListCompany.interface';
import { IModulesContext } from '../../context/modules/Modules.interface';
import { ModulesContext } from '../../context/modules/Modules';

function ListCompany() {
    const snackbar = useSnackbar();
    const history = useHistory();
    const { Analytics } = useContext<IModulesContext>(ModulesContext);

    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);
    const [amount] = useState<number>(8);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [covenant, setCovenant] = useState<boolean>(false);
    const [categoriesList, setCategoriesList] = useState<ICategoriesSelectList[]>([]);
    const [categorySelected, setCategorySelected] = useState<ICategoriesSelectList | null>(null);
    const [companyList, setCompanyList] = useState<ICompanyDTO[]>([]);

    const CategoryGetAll = useDoRequest((api) => api.Category.Search);
    const CompanyNextPageSearch = useDoRequest((api) => api.Company.SearchCompany);

    function componentDidMount() {
        Analytics.logScreen('Listagem de estabelecimentos');
        getNextPage(1);
        getCategorys();
    }
    useEffect(componentDidMount, []);

    function getCategorys() {
        CategoryGetAll.doRequest("").then((response) => {
            if(response?.statusExecucao && response?.dadosRetorno?.length) {
                const categories = response.dadosRetorno;
                let letFilteredList: ICategoriesSelectList[] = [];

                categories.forEach((category: ICategoryDTO) => {
                    letFilteredList.push({label: category.nomeCategoria, value: category.idCategoria});
                });

                setCategoriesList(letFilteredList);
            } else {
                snackbar(response.mensagem || "Não foi possível buscar os dados das categorias.").error();
                history.redirectTo("/home");
            }
        }).catch((error) => {
            snackbar("Não foi possível buscar os dados das categorias.").error();
            history.redirectTo("/home");
        })
    }

    function getNextPage(initialPage?: number) {
        let pagedObj: ICompanySearch = {
            searchTerm: searchTerm,
            page: initialPage ? initialPage : page + 1,
            amount: amount,
            covenant: covenant,
        }

        if(categorySelected) {
            pagedObj.idCategoria = categorySelected.value;
        } 

        CompanyNextPageSearch.doRequest(pagedObj).then((response) => {
            if(response?.statusExecucao && response?.dadosRetorno) {
                const list = response?.dadosRetorno.listCompany;
                setPage(response?.dadosRetorno.page);
                if(initialPage) {
                    setCompanyList(list);
                } else {
                    setCompanyList([...companyList, ...list]);
                }
                
                setPageCount(response?.dadosRetorno.pageCount);
            } else {
                snackbar(response.mensagem || "Não foi possível buscar os dados das empresas.").error();
                history.redirectTo("/home");
            }
        }).catch((error) => {
            snackbar("Não foi possível buscar os dados das empresas.").error();
            history.redirectTo("/home");
        });
    }

    return (
        <ListCompanyView 
            searchTerm={searchTerm}
            pageCount={pageCount}
            page={page}
            covenant={covenant}
            companyList={companyList}
            loading={CategoryGetAll.loading}
            infinityLoading={CompanyNextPageSearch.loading}
            categoriesList={categoriesList}
            categorySelected={categorySelected}
            setCategorySelected={setCategorySelected}
            getNextPage={getNextPage}
            setSearchTerm={setSearchTerm}
            setCovenant={setCovenant}
        />
    )
}

export default ListCompany;