import { IHeaderProps } from './Header.interface';
import HeaderView from './Header.view';

function Header(props: IHeaderProps) {
    const { sections, anchorElNav, headerBackground, isAuthenticated, onHeaderButtonClick, handleCloseNavMenu, handleOpenNavMenu, redirect } = props;

    return (
        <HeaderView 
            sections={sections}
            anchorElNav={anchorElNav}
            isAuthenticated={isAuthenticated}
            headerBackground={headerBackground}
            onHeaderButtonClick={onHeaderButtonClick}
            handleOpenNavMenu={handleOpenNavMenu}
            redirect={redirect}
            handleCloseNavMenu={handleCloseNavMenu}
        />
    )
}

export default Header;