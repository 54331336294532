import { format } from 'date-fns';

import { useHistory } from '../../../../hooks';
import { IEventListCard } from './EventListCard.interface';
import EventListCardView from './EventListCard.view';

function EventListCard(props: IEventListCard) {
    const { event } = props;

    const history = useHistory();

    function goToEventPage() {
        history.redirectTo(`/detailEvent/${event.idCalendario}`);
    }

    return (
        <EventListCardView
            title={event?.descricaoPauta || ""}
            titleGrau={event?.idGrauNavigation?.tituloGrau || ""}
            date={format(new Date(event?.dataHoraEvento), "dd'/'MM'/'yyyy") || "--"}
            hour={format(new Date(event?.dataHoraEvento), "HH:mm") || "--"}
            goToEventPage={goToEventPage}
        />
    );
}

export default EventListCard;