import { useContext, useEffect, useState } from 'react';

import { ModulesContext } from '../../context/modules/Modules';
import { IModulesContext } from '../../context/modules/Modules.interface';
import { IBrotherDTO } from '../../model';
import { IBrothersPagedInput } from '../../modules/api/endpoints/brother/Brother.interface';
import { useArray, useDoRequest, useSnackbar } from '../../hooks';

import ListBrotherView from './ListBrother.view';

function ListBrother() {
    const GetBrothersPaged = useDoRequest((api) => api.Brother.SearchBrother);
    const snackbar = useSnackbar();
    const { Analytics } = useContext<IModulesContext>(ModulesContext);

    const listBrothers = useArray<IBrotherDTO>([]);

    const [firstLoading, setFirstLoading] = useState<boolean>(false);

    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);

    const [searchTerm, setSearchTerm] = useState<string>("");

    useEffect(() => {
        if (searchTerm === "") getBrothersPaged(page, "");
    }, [searchTerm]);

    useEffect(componentDidMount, []);

    function componentDidMount() {
        Analytics.logScreen('Listagem de obreiros');
        getBrothersPaged(page, searchTerm, true);
    }

    function getBrothersPaged(pageProp: number, searchTerm: string, initialLoading?: boolean) {
        if (pageProp > pageCount && pageCount != 0) return;
        if(initialLoading) {
            setFirstLoading(true);
        }

        const dto: IBrothersPagedInput = {
            searchTerm: searchTerm,
            page: pageProp,
            amount: 9
        }

        GetBrothersPaged.doRequest(dto).then((response) => {
            if (response.statusExecucao && response.dadosRetorno) {
                const data = response.dadosRetorno;

                setPageCount(data.pageCount);
                setSearchTerm(searchTerm);
                setPage(data.page);
                listBrothers.setValue(pageProp == 1 ? data.listBrother : [...listBrothers.value, ...data.listBrother]);
            } else {
                snackbar(response.mensagem || "Não foi possível listar os obreiros.").error();
            }
        }).catch((error) => {
            snackbar("Erro ao listar os obreiros.").error();
        }).finally(() => {
            setFirstLoading(false);
        })
    }

    function submitFilter() {
        Analytics.logScreenAction('Listagem de obreiros', 'Buscar obreiro');
        getBrothersPaged(1, searchTerm, true);
    }

    function nextPage() {
        getBrothersPaged(page + 1, searchTerm);
    }

    return (
        <ListBrotherView 
            brotherList={listBrothers.value}
            initialLoading={firstLoading}
            loading={GetBrothersPaged.loading}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            page={page}
            pageCount={pageCount}
            submitFilter={submitFilter}
            nextPage={nextPage}
        />
    )
}

export default ListBrother;