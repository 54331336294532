import {Box, Card, CardContent, Grid, Modal, styled} from '@mui/material';

export const ModalContainer = styled(Modal)(() => ({
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    marginBottom: "16px"
}));

export const ModalBox = styled(Box)(({ theme }) => ({
    maxWidth: theme.breakpoints.values.sm,
    maxHeight: theme.breakpoints.values.sm,
    width: "80%",
    borderRadius: 12
}));

export const ModalCard = styled(Card)(() => ({
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    padding: 12,
    borderRadius: 12
}));

export const CardContentBox = styled(CardContent)(() => ({
    display: "flex",
    flexDirection: "column",
    padding: "8px",
    textAlign: "center",
}));

export const ButtonContent = styled(Grid)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    marginVertical: "8px",
}));